import React from "react";

import Button from "../Button";
import ProfilePhoto from "../ProfilePhoto";
import styles from "./styles.module.scss";
import iconPeople from "../../assets/images/waitlist/people.svg";

interface Props {
  agent?: any;
  onClick: () => any;
}

const AgentAssignment: React.FC<Props> = ({ agent, onClick }) => {
  let firstName;
  if (agent) {
    firstName = agent.name.split(" ")[0]; // hacky
  }

  return (
    <div className={styles.agentAssignment}>
      {agent ? (
        <div className={styles.container}>
          <h1 className={styles.hero}>Goal Submitted</h1>
          <ProfilePhoto
            url={agent.photo}
            name={firstName}
            className={styles.profilePhoto}
          />
          <h1>{firstName} is your dedicated advocate for this Goal.</h1>
          <p>
            {firstName} will follow up within 72 hours. We're connecting you now
            via chat in case you have any immediate questions.
          </p>
        </div>
      ) : (
        <div className={styles.container}>
          <h1 className={styles.hero}>Goal Submitted</h1>
          <ProfilePhoto
            url={iconPeople}
            name="Your Team"
            className={styles.profilePhoto}
          />
          <h1>Your team is ready to handle this Goal.</h1>
          <p>
            Your team will follow up within 72 hours. We're connecting you now
            via chat in case you have any immediate questions.
          </p>
        </div>
      )}

      <Button onClick={onClick}>Got it!</Button>
    </div>
  );
};

export default AgentAssignment;

import classnames from "classnames";
import React from "react";
import classes from "./styles.module.scss";

interface Props {
  url?: string;
  name: string;
  className?: string;
}

const ProfilePhoto: React.FC<Props> = ({ url, name, className }) => (
  <span className={classnames(classes.container, className)}>
    {url ? (
      <img src={url} alt={name} />
    ) : (
      <span>
        {name
          .split(" ")
          .map(word => word[0])
          .join("")}
      </span>
    )}
  </span>
);

export default ProfilePhoto;

import React from "react";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router-dom";
import NewTicketSection from "../components/NewTicketSection";
import NewTicketWizard from "../components/NewTicketWizard";
import { getGoalCreationConfig } from "../store/selectors/goals";
import { GlobalStore } from "../types/store";

type StateProps = ReturnType<typeof mapStateToProps>;

type RouteProps = RouteComponentProps<{ [key: string]: string }>;

type Props = StateProps & RouteProps;

const NewTicket: React.FC<Props> = ({ config, match }) => {
  const path = Object.values(match.params);

  if (config && path.length > 0) {
    let currentSection = config.sections.find(
      section => section.slug === path[0]
    );

    for (let index = 1; index < path.length; index++) {
      const name = path[index];

      if (currentSection) {
        if (currentSection.wizardSteps) {
          const currentStepIndex = Number(name);

          if (currentSection.wizardSteps[currentStepIndex])
            return (
              <NewTicketWizard
                config={currentSection.wizardSteps}
                currentStepIndex={currentStepIndex}
                sectionPath={path.splice(0, path.length - 1)}
                parentSection={currentSection}
              />
            );
          else {
            return <Redirect to="/dashboard" />;
          }
        }

        if (currentSection.sections) {
          currentSection = currentSection.sections.find(
            section => section.slug === name
          );
        }
      }
    }

    if (currentSection) {
      return <NewTicketSection config={currentSection} />;
    }
  }
  return <Redirect to="/dashboard" />;
};

const mapStateToProps = (state: GlobalStore) => ({
  config: getGoalCreationConfig(state)
});

export default connect(mapStateToProps)(NewTicket);

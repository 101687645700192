import React from "react";
import ProfilePhoto from "../ProfilePhoto";
import classes from "./styles.module.scss";

interface Props {
  name: string;
  photoUrl?: string;
}

const Header: React.FC<Props> = ({ photoUrl, name }) => (
  <div className={classes.header}>
    <ProfilePhoto url={photoUrl} name={name} className={classes.headerPhoto} />
    <h1>{name}</h1>
  </div>
);

export default Header;

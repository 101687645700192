import React, { useEffect } from "react";
import classes from "./styles.module.scss";
import { Icon } from "../Icon/Icon";

interface Props {
  onClose?: () => void;
}

export const Sidebar: React.FC<Props> = ({ children, onClose }) => {
  useEffect(() => {
    document.body.classList.add(classes.withSidebar);
    return () => {
      document.body.classList.remove(classes.withSidebar);
    };
  }, []);

  return (
    <div className={classes.wrapper}>
      <div className={classes.overlay} onClick={onClose} />
      <div className={classes.container}>
        <div className={classes.closeButton}>
          <Icon name="close_24" onClick={onClose} />
        </div>
        {children}
      </div>
    </div>
  );
};

import React from "react";
import { connect } from "react-redux";
import { getInvoicesListIds } from "../../../store/selectors/invoices";
import { GlobalStore } from "../../../types/store";
import { InvoicesListMobileItemContainer } from "./Item";

type MapStateToProps = ReturnType<typeof mapStateToProps>;
type Props = MapStateToProps;

const InvoicesListMobile: React.FC<Props> = props => (
  <>
    {props.listIds.map(invoiceId => (
      <InvoicesListMobileItemContainer key={invoiceId} invoiceId={invoiceId} />
    ))}
  </>
);

const mapStateToProps = (state: GlobalStore) => ({
  listIds: getInvoicesListIds(state)
});

export const InvoicesListMobileContainer = connect(mapStateToProps)(
  InvoicesListMobile
);

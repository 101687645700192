import classNames from "classnames";
import React from "react";
import classes from "./styles.module.scss";

interface Props {
  complete?: boolean;
}

const ListItem: React.FC<Props> = ({ complete, children }) => (
  <div className={classNames(classes.listItem)}>
    <div className={classes.label}>{children}</div>

    {complete ? (
      <div className={classes.completeIcon}></div>
    ) : (
      <div className={classes.linkIcon}></div>
    )}
  </div>
);

export default ListItem;

import React from "react";
import iconPerson from "../../assets/images/category_icons/person.svg";
import iconQuestion from "../../assets/images/category_icons/question.svg";
import iconRedCross from "../../assets/images/category_icons/red_cross.svg";
import iconVault from "../../assets/images/category_icons/vault.svg";
import classes from "./styles.module.scss";

interface Props {
  icon?: string;
}

const ListItem: React.FC<Props> = ({ icon, children }) => {
  switch (icon) {
    case "vault.svg":
      icon = iconVault;
      break;
    case "person.svg":
      icon = iconPerson;
      break;
    case "red_cross.svg":
      icon = iconRedCross;
      break;
    case "question.svg":
      icon = iconQuestion;
      break;
    default:
  }

  return (
    <div className={classes.listItem}>
      {icon && (
        <div className={classes.icon}>
          <img src={icon} alt="Icon" />
        </div>
      )}
      {children}
    </div>
  );
};

export default ListItem;

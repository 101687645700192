import { Form, Formik } from "formik";
import React from "react";
import { RouteComponentProps } from "react-router";
import * as Yup from "yup";
import Button from "../components/Button";
import Card from "../components/Card";
import MainLayout from "../components/MainLayout";
import TextField from "../components/TextField";
import { createPasswordReset } from "../services/api";
import { generateFormStatus } from "../utils/form";

const RequestPasswordReset: React.FC<RouteComponentProps<null>> = () => {
  return (
    <MainLayout title="Request Password Reset">
      <MainLayout.PageHeader
        header="Request Password Reset"
        subHeader="Enter your email address to request a password reset."
        backgroundColor="#DBF0FF"
      />
      <MainLayout.Main>
        <Formik
          initialValues={{ email: "" }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Please enter a valid email address")
              .required("Email is required")
          })}
          onSubmit={async (values, { setSubmitting, setStatus }) => {
            try {
              const { message } = await createPasswordReset(values.email);
              setStatus({ type: "success", message });
            } catch (error) {
              setStatus(generateFormStatus(error));
            }
            setSubmitting(false);
          }}
        >
          {({
            handleSubmit,
            status,
            values,
            handleChange,
            touched,
            errors,
            isSubmitting
          }) => (
            <Form>
              <Card>
                <Card.Main>
                  {status && status.type === "error" && (
                    <p style={{ color: "red", marginBottom: 20 }}>
                      {status.message}
                    </p>
                  )}
                  {status && status.type === "success" && (
                    <p style={{ color: "green", marginBottom: 20 }}>
                      {status.message}
                    </p>
                  )}

                  <TextField
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    label="Email Address"
                    required
                    error={
                      touched.email && errors.email ? errors.email : undefined
                    }
                  />
                </Card.Main>
              </Card>
              <MainLayout.Actions>
                <Button
                  loading={isSubmitting}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Request Password Reset
                </Button>
              </MainLayout.Actions>
            </Form>
          )}
        </Formik>
      </MainLayout.Main>
    </MainLayout>
  );
};

export default RequestPasswordReset;

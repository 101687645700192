import classnames from "classnames";
import React from "react";
import classes from "./styles.module.scss";

interface Props {
  active: number;
  total: number;
}

const StepsProgress: React.FC<Props> = ({ active, total }) => (
  <div>
    <div className={classes.stepsProgressBuffer} />
    <div className={classes.stepsProgress}>
      {Array(total)
        .fill(0)
        .map((_, index) => (
          <div
            key={index}
            className={classnames(classes.stepsProgressStep, {
              [classes.stepsProgressStepActive]: index + 1 === active
            })}
          />
        ))}
    </div>
  </div>
);

export default StepsProgress;

import * as apiService from "../../services/api";
import { fetchMembershipActions } from "./membershipActions";
import { AppThunk } from "../../types/store";

export const fetchMembership = (): AppThunk<any> => async dispatch => {
  dispatch(fetchMembershipActions.request());

  try {
    const subscription = await apiService.getSubscription();
    const paymentMethod = await apiService.getSubscriptionPaymentMethod();
    const paymentHistory = await apiService.getSubscriptionPaymentHistory();

    dispatch(
      fetchMembershipActions.success({
        info: subscription,
        paymentMethod,
        paymentHistory
      })
    );
  } catch (error) {
    dispatch(fetchMembershipActions.failure(error));
  }
};

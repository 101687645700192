import { Form, Formik } from "formik";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import * as Yup from "yup";
import { fetchMembership } from "../../store/actions/fetchMembership";
import * as apiService from "../../services/api";
import * as stripeService from "../../services/stripe";
import {
  getMembershipPaymentMethod,
  getMembershipPlanId,
  getMembershipPlans
} from "../../store/selectors/membership";
import { GlobalStore } from "../../types/store";
import { generateFormStatus } from "../../utils/form";
import Button from "../Button";
import PaymentFields from "../PaymentFields";
import SelectSubscriptionPlanField from "../SelectSubscriptionPlanField";
import classes from "./styles.module.scss";

type OwnProps = {
  onCancel: () => void;
};

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StateProps;

const SubscriptionEditForm: React.FC<Props> = ({
  planId,
  plans,
  paymentMethod,
  onCancel
}) => {
  const dispatch = useDispatch();

  const [cancelLoading, setCancelLoading] = useState(false);

  const currentPaymentMethod =
    paymentMethod && `**** **** **** ${paymentMethod.last4}`;

  return (
    <Formik
      initialValues={{
        plan: planId || plans[0].planId,
        name: "",
        card: "",
        expirationDate: "",
        cvv: "",
        zip: ""
      }}
      validationSchema={Yup.object().shape({
        plan: Yup.string().required("Plan is required"),
        name: Yup.string().required("Name on Card is required"),
        card: Yup.string().required("Card is required"),
        expirationDate: Yup.string().required("Expiration Date is required"),
        cvv: Yup.string().required("CVV Code is required"),
        zip: Yup.string().required("Billing Zip is required")
      })}
      onSubmit={async (values, { setSubmitting, setStatus, resetForm }) => {
        try {
          setStatus(null);

          const [expirationMonth, expirationYear] = values.expirationDate.split(
            "/"
          );

          const token = await stripeService.createPaymentMethod({
            name: values.name,
            number: Number(values.card.split(" ").join("")),
            cvc: Number(values.cvv),
            expMonth: Number(expirationMonth),
            expYear: Number(expirationYear),
            zip: Number(values.zip)
          });

          await apiService.updateSubscriptionPaymentMethod(token);

          await dispatch(fetchMembership());

          resetForm({
            plan: values.plan,
            name: "",
            card: "",
            expirationDate: "",
            cvv: "",
            zip: ""
          });
        } catch (error) {
          setStatus(generateFormStatus(error));
        }
        setSubmitting(false);
      }}
    >
      {({
        handleSubmit,
        status,
        values,
        handleChange,
        touched,
        errors,
        isSubmitting,
        isValid
      }) => (
        <Form>
          {status && status.type === "error" && (
            <p style={{ color: "red", marginBottom: 20 }}>{status.message}</p>
          )}

          <SelectSubscriptionPlanField
            showTitle={true}
            name="plan"
            value={values.plan}
            onChange={handleChange}
            error={touched.plan && errors.plan ? errors.plan : undefined}
            disabled
          />

          <p>
            Please contact{" "}
            <a href="mailto:team@wingspan.app">team@wingspan.app</a> to change
            your plan type.
          </p>

          {currentPaymentMethod && (
            <>
              <h4>Current Payment Method</h4>
              <p>{currentPaymentMethod}</p>
            </>
          )}

          <h4>Add New Payment Method</h4>

          <PaymentFields
            values={values}
            touched={touched}
            errors={errors}
            onChange={handleChange}
          />

          <p style={{ textAlign: "center" }}>
            Adding a new payment method will delete your current payment method.
          </p>

          <p className="modest" style={{ textAlign: "center" }}>
            We partner with{" "}
            <a
              href="https://stripe.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Stripe
            </a>{" "}
            for best-in-class payments security.
          </p>

          <div style={{ display: "flex", flexDirection: "row-reverse" }}>
            <Button
              disabled={!isValid}
              loading={isSubmitting}
              onClick={() => {
                handleSubmit();
              }}
            >
              Save
            </Button>
          </div>

          <div className={classes.cancel}>
            <Button
              type="link"
              onClick={async () => {
                setCancelLoading(true);

                try {
                  await apiService.deleteSubscription();
                  await dispatch(fetchMembership());
                  onCancel();
                } catch (error) {
                  console.error("Failed to cancel subscription");
                  console.error(error);
                }

                setCancelLoading(false);
              }}
              loading={cancelLoading}
            >
              Cancel Membership
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: GlobalStore) => ({
  plans: getMembershipPlans(state),
  planId: getMembershipPlanId(state),
  paymentMethod: getMembershipPaymentMethod(state)
});

export default connect(mapStateToProps)(SubscriptionEditForm);

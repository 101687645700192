import { Reducer } from "redux";
import { InvoicesStore } from "../../types/store";
import { InferValueTypes } from "../../types/utils";
import { closeInvoicePreview } from "../actions/closeInvoicePreview";
import { fetchInvoiceListActions } from "../actions/invoicesActions";
import { openInvoicePreview } from "../actions/openInvoicePreview";
import {
  CLOSE_INVOICE_PREVIEW,
  FETCH_INVOICE_LIST_SUCCESS,
  OPEN_INVOICE_PREVIEW
} from "../constants";

export type InvoicesActions =
  | ReturnType<InferValueTypes<typeof fetchInvoiceListActions>>
  | ReturnType<typeof openInvoicePreview>
  | ReturnType<typeof closeInvoicePreview>;

export const getDefaultInvoices: () => InvoicesStore = () => ({
  byId: {},
  listIds: [],
  stats: {
    paid: 0,
    outstanding: 0
  },
  preview: {
    visible: false
  }
});

export const invoices: Reducer<InvoicesStore, InvoicesActions> = (
  state = getDefaultInvoices(),
  action
) => {
  switch (action.type) {
    case FETCH_INVOICE_LIST_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          ...action.payload.byId
        },
        listIds: action.payload.listIds,
        stats: action.payload.stats
      };

    case OPEN_INVOICE_PREVIEW:
      return {
        ...state,
        preview: {
          visible: true,
          data: action.payload.data
        }
      };

    case CLOSE_INVOICE_PREVIEW:
      return {
        ...state,
        preview: {
          visible: false
        }
      };

    default: {
      return state;
    }
  }
};

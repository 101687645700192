import React from "react";
import styles from "./styles.module.scss";
import classnames from "classnames";

interface Props {
  noMargin?: boolean; // Used for tabs content
}

const Main: React.FC<Props> = ({ noMargin, children }) => (
  <main
    className={classnames(styles.main, { [styles.mainNoMargin]: noMargin })}
  >
    {children}
  </main>
);

export default Main;

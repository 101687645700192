export default [
  { value: "al", label: "AL" },
  { value: "ak", label: "AK" },
  { value: "as", label: "AS" },
  { value: "az", label: "AZ" },
  { value: "ar", label: "AR" },
  { value: "ca", label: "CA" },
  { value: "co", label: "CO" },
  { value: "ct", label: "CT" },
  { value: "de", label: "DE" },
  { value: "dc", label: "DC" },
  { value: "fm", label: "FM" },
  { value: "fl", label: "FL" },
  { value: "ga", label: "GA" },
  { value: "gu", label: "GU" },
  { value: "hi", label: "HI" },
  { value: "id", label: "ID" },
  { value: "il", label: "IL" },
  { value: "in", label: "IN" },
  { value: "ia", label: "IA" },
  { value: "ks", label: "KS" },
  { value: "ky", label: "KY" },
  { value: "la", label: "LA" },
  { value: "me", label: "ME" },
  { value: "mh", label: "MH" },
  { value: "md", label: "MD" },
  { value: "ma", label: "MA" },
  { value: "mi", label: "MI" },
  { value: "mn", label: "MN" },
  { value: "ms", label: "MS" },
  { value: "mo", label: "MO" },
  { value: "mt", label: "MT" },
  { value: "ne", label: "NE" },
  { value: "nv", label: "NV" },
  { value: "nh", label: "NH" },
  { value: "nj", label: "NJ" },
  { value: "nm", label: "NM" },
  { value: "ny", label: "NY" },
  { value: "nc", label: "NC" },
  { value: "nd", label: "ND" },
  { value: "mp", label: "MP" },
  { value: "oh", label: "OH" },
  { value: "ok", label: "OK" },
  { value: "or", label: "OR" },
  { value: "pw", label: "PW" },
  { value: "pa", label: "PA" },
  { value: "pr", label: "PR" },
  { value: "ri", label: "RI" },
  { value: "sc", label: "SC" },
  { value: "sd", label: "SD" },
  { value: "tn", label: "TN" },
  { value: "tx", label: "TX" },
  { value: "ut", label: "UT" },
  { value: "vt", label: "VT" },
  { value: "vi", label: "VI" },
  { value: "va", label: "VA" },
  { value: "wa", label: "WA" },
  { value: "wv", label: "WV" },
  { value: "wi", label: "WI" },
  { value: "wy", label: "WY" }
];

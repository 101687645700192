import { Form, Formik } from "formik";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import * as Yup from "yup";
import { fetchMembership } from "../store/actions/fetchMembership";
import heroFinal from "../assets/images/final-hero.png";
import Button from "../components/Button";
import Card from "../components/Card";
import Final from "../components/Final";
import MainLayout from "../components/MainLayout";
import PaymentFields from "../components/PaymentFields";
import SelectSubscriptionPlanField from "../components/SelectSubscriptionPlanField";
import * as apiService from "../services/api";
import * as stripeService from "../services/stripe";
import { getMembershipPlans } from "../store/selectors/membership";
import { GlobalStore } from "../types/store";
import { makeEnrichIdentifyFromState, track } from "../utils/analytics";
import { generateFormStatus } from "../utils/form";

type StateProps = ReturnType<typeof mapStateToProps>;

type RouterPops = RouteComponentProps;

type Props = StateProps & RouterPops;

const Subscribe: React.FC<Props> = ({ history, plans, enrichIdentify }) => {
  const dispatch = useDispatch();
  const initialPlan = plans.find(plan => plan.interval === "year");

  const [plan, setPlan] = useState(initialPlan ? initialPlan.planId : "");
  const [currentStep, setCurrentStep] = useState("plan");
  const [error, setError] = useState<string>();

  switch (currentStep) {
    case "plan":
      return (
        <MainLayout title="Membership" currentPage="register" simpleHeader>
          <MainLayout.PageHeader
            header="Membership"
            subHeader="Which payment plan would you like?"
            backgroundColor="#E6D7EB"
          />
          <MainLayout.Main>
            <Card>
              <Card.Main>
                {error && (
                  <p style={{ color: "red", marginBottom: 20 }}>{error}</p>
                )}
                <SelectSubscriptionPlanField
                  name="plan"
                  value={plan}
                  onChange={({ target: { value } }) => {
                    setPlan(value);
                  }}
                />
              </Card.Main>
            </Card>
            <p style={{ textAlign: "center" }}>
              Most customers opt for the <b>Annual Subscription</b> to save
              themselves time and money.
            </p>
            <p className="modest" style={{ textAlign: "center" }}>
              We partner with{" "}
              <a
                href="https://stripe.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Stripe
              </a>{" "}
              for best-in-class payments security.
            </p>
            <MainLayout.Actions>
              <Button
                onClick={() => {
                  if (plan) {
                    track("Membership Plan Selected", {
                      location: "Onboarding"
                    });
                    setCurrentStep("paymentInfo");
                  } else setError("Please select a payment plan.");
                }}
              >
                Next
              </Button>
              <Button
                type="link"
                padding="none"
                onClick={() => {
                  history.push("/dashboard");
                }}
              >
                No thanks, I'll subscribe later
              </Button>
            </MainLayout.Actions>
          </MainLayout.Main>
        </MainLayout>
      );

    case "paymentInfo":
      return (
        <MainLayout title="Membership" currentPage="register" simpleHeader>
          <MainLayout.PageHeader
            header="Membership"
            subHeader="Enter your payment details"
            backgroundColor="#FFDCBE"
          />
          <MainLayout.Main>
            <Formik
              initialValues={{
                name: "",
                card: "",
                expirationDate: "",
                cvv: "",
                zip: ""
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string().required("Name on Card is required"),
                card: Yup.string().required("Card is required"),
                expirationDate: Yup.string().required(
                  "Expiration Date is required"
                ),
                cvv: Yup.string().required("CVV Code is required"),
                zip: Yup.string().required("Billing Zip is required")
              })}
              onSubmit={async (
                values,
                { setSubmitting, setStatus, resetForm }
              ) => {
                try {
                  setStatus(null);

                  track("Membership Purchased", {
                    location: "Onboarding"
                  });

                  const [
                    expirationMonth,
                    expirationYear
                  ] = values.expirationDate.split("/");

                  const token = await stripeService.createPaymentMethod({
                    name: values.name,
                    number: Number(values.card.split(" ").join("")),
                    cvc: Number(values.cvv),
                    expMonth: Number(expirationMonth),
                    expYear: Number(expirationYear),
                    zip: Number(values.zip)
                  });

                  await apiService.updateSubscriptionPaymentMethod(token);

                  const subscription = await apiService.updateSubscription(
                    plan
                  );
                  await dispatch(fetchMembership());

                  enrichIdentify(
                    subscription.planNickname,
                    new Date(),
                    subscription.trialEnd
                  );

                  setCurrentStep("success");
                } catch (error) {
                  setStatus(generateFormStatus(error));
                }
                setSubmitting(false);
              }}
            >
              {({
                handleSubmit,
                status,
                values,
                handleChange,
                touched,
                errors,
                isSubmitting
              }) => (
                <Form>
                  <Card>
                    <Card.Main>
                      {status && status.type === "error" && (
                        <p style={{ color: "red", marginBottom: 20 }}>
                          {status.message}
                        </p>
                      )}

                      <PaymentFields
                        values={values}
                        touched={touched}
                        errors={errors}
                        onChange={handleChange}
                      />
                    </Card.Main>
                  </Card>
                  <p className="modest" style={{ textAlign: "center" }}>
                    We partner with{" "}
                    <a
                      href="https://stripe.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Stripe
                    </a>{" "}
                    for best-in-class payments security.
                  </p>
                  <MainLayout.Actions>
                    <Button
                      loading={isSubmitting}
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Submit
                    </Button>
                    <Button
                      type="link"
                      padding="none"
                      onClick={() => {
                        setCurrentStep("plan");
                      }}
                    >
                      Back
                    </Button>
                  </MainLayout.Actions>
                </Form>
              )}
            </Formik>
          </MainLayout.Main>
        </MainLayout>
      );

    case "success":
      return (
        <MainLayout title="Membership" simpleHeader>
          <MainLayout.PageHeader image={heroFinal} backgroundColor="#FFDCD2" />
          <MainLayout.Main>
            <Final>
              <h1>Awesome! Your membership is now active</h1>
              <p>
                Get started by clicking the button below to continue to your
                home page.
              </p>

              <Button
                onClick={() => {
                  history.push("/dashboard");
                }}
              >
                Go to Home Page
              </Button>
            </Final>
          </MainLayout.Main>
        </MainLayout>
      );

    default:
      return null;
  }
};

const mapStateToProps = (state: GlobalStore) => ({
  plans: getMembershipPlans(state),
  enrichIdentify: makeEnrichIdentifyFromState(state)
});

export default connect(mapStateToProps)(Subscribe);

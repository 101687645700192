import classNames from "classnames";
import React, { useState } from "react";
import classes from "./styles.module.scss";
import FieldContainer, { FieldContainerProps } from "../FieldContainer";

interface Props extends FieldContainerProps {
  options: {
    label?: string;
    value: string;
  }[];
  onChange: (value: string) => void;
  value: string;
  placeholder?: string;
}

const DropDownField: React.FC<Props> = ({
  value,
  options,
  onChange,
  placeholder,
  label,
  required,
  error
}) => {
  const [visible, setVisible] = useState(false);

  const selectedOption = options.find(option => option.value === value);

  return (
    <FieldContainer label={label} required={required} error={error}>
      <div
        className={classNames(classes.control, { [classes.focused]: visible })}
      >
        <div
          className={classes.input}
          onClick={() => {
            setVisible(!visible);
          }}
        >
          {selectedOption ? (
            <div className={classes.value}>
              {selectedOption.label || selectedOption.value}
            </div>
          ) : (
            placeholder && (
              <div className={classes.placeholder}>{placeholder}</div>
            )
          )}
          <div className={classes.icon}></div>
          {error && <div className={classes.error}>{error}</div>}
        </div>
        {visible && (
          <div className={classes.dropdown}>
            {options.map(option => (
              <div
                key={option.value}
                className={classNames(classes.option, {
                  [classes.selected]: option.value === value
                })}
                onClick={() => {
                  onChange(option.value);
                  setVisible(false);
                }}
              >
                {option.label || option.value}
              </div>
            ))}
          </div>
        )}
      </div>
    </FieldContainer>
  );
};

export default DropDownField;

import { Form, Formik } from "formik";
import moment from "moment";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import * as Yup from "yup";
import { fetchUserUpdate } from "../store/actions/fetchUserUpdate";
import addIcon from "../assets/icons/add_24.svg";
import Button from "../components/Button";
import EmptyInstructions from "../components/EmptyInstructions";
import FieldValue from "../components/FieldValue";
import MainLayout from "../components/MainLayout";
import Modal from "../components/Modal";
import { EditButton, Header } from "../components/Profile";
import SubscriptionEditForm from "../components/SubscriptionEditForm";
import SubscriptionInvoicePreview from "../components/SubscriptionInvoicePreview";
import TextField from "../components/TextField";
import { resetPassword } from "../services/api";
import { setSubscribeModalVisible } from "../store/actions/membershipActions";
import {
  getMembershipInfo,
  getMembershipPaymentHistory,
  getMembershipPaymentMethod,
  getMembershipPlans
} from "../store/selectors/membership";
import { getUserFields } from "../store/selectors/user";
import { GlobalStore } from "../types/store";
import { track } from "../utils/analytics";
import { generateFormStatus } from "../utils/form";

type StateProps = ReturnType<typeof mapStateToProps>;

type RouteProps = RouteComponentProps;

type Props = RouteProps & StateProps;

const Profile: React.FC<Props> = ({
  userFields,
  subscription,
  paymentMethod,
  paymentHistory,
  plans
}) => {
  const dispatch = useDispatch();

  const [profileEditVisible, setProfileEditVisibility] = useState(false);
  const [membershipEditVisible, setMembershipEditVisible] = useState(false);
  const [paymentHistoryVisible, setPaymentHistoryVisible] = useState(false);
  const [settingsEditVisible, setSettingsEditVisible] = useState(false);

  let activeSubscriptionPlan;

  if (subscription) {
    const activeSubscriptionPlanId = subscription.planId;
    activeSubscriptionPlan = plans.find(
      plan => plan.planId === activeSubscriptionPlanId
    );
  }

  return (
    <MainLayout title="Your Profile" currentPage="profile">
      <MainLayout.PageHeader backgroundColor="#F3F5F9">
        <Header name={`${userFields.firstName} ${userFields.lastName}`} />
      </MainLayout.PageHeader>
      <MainLayout.Main noMargin>
        <MainLayout.Tabs>
          <MainLayout.Tabs.Tab name="Profile">
            <EditButton
              onClick={() => {
                setProfileEditVisibility(true);
              }}
            />

            <FieldValue label="Company Name">
              {userFields.companyName ? (
                userFields.companyName
              ) : (
                <Button
                  size="small"
                  color="tertiary"
                  onClick={() => {
                    setProfileEditVisibility(true);
                  }}
                  icon={<img src={addIcon} alt="Edit Icon" />}
                >
                  Add a Company Name
                </Button>
              )}
            </FieldValue>

            <FieldValue label="Description">
              {userFields.description ? (
                userFields.description
              ) : (
                <Button
                  size="small"
                  color="tertiary"
                  onClick={() => {
                    setProfileEditVisibility(true);
                  }}
                  icon={<img src={addIcon} alt="Edit Icon" />}
                >
                  Add a Description
                </Button>
              )}
            </FieldValue>

            <FieldValue label="Zip Code">
              {userFields.zipCode ? (
                userFields.zipCode
              ) : (
                <Button
                  size="small"
                  color="tertiary"
                  onClick={() => {
                    setProfileEditVisibility(true);
                  }}
                  icon={<img src={addIcon} alt="Edit Icon" />}
                >
                  Add a Zip Code
                </Button>
              )}
            </FieldValue>

            <Modal
              header="Edit Profile"
              visible={profileEditVisible}
              onClose={() => {
                setProfileEditVisibility(false);
              }}
            >
              <Formik
                initialValues={{
                  firstName: userFields.firstName,
                  lastName: userFields.lastName,
                  companyName: userFields.companyName || "",
                  description: userFields.description || "",
                  zipCode: userFields.zipCode || ""
                }}
                validationSchema={Yup.object().shape({
                  firstName: Yup.string().required("First name is required"),
                  lastName: Yup.string().required("Last name is required"),
                  companyName: Yup.string(),
                  description: Yup.string(),
                  zipCode: Yup.string()
                })}
                onSubmit={async (values, { setSubmitting, setStatus }) => {
                  try {
                    await dispatch(fetchUserUpdate(values));
                    setProfileEditVisibility(false);
                  } catch (error) {
                    setStatus(generateFormStatus(error));
                  }
                  setSubmitting(false);
                }}
              >
                {({
                  handleSubmit,
                  status,
                  values,
                  handleChange,
                  touched,
                  errors,
                  isSubmitting
                }) => (
                  <Form>
                    {status && status.type === "error" && (
                      <p style={{ color: "red", marginBottom: 20 }}>
                        {status.message}
                      </p>
                    )}

                    <TextField
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      label="First Name"
                      required
                      error={
                        touched.firstName && errors.firstName
                          ? errors.firstName
                          : undefined
                      }
                    />

                    <TextField
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      label="Last Name"
                      required
                      error={
                        touched.lastName && errors.lastName
                          ? errors.lastName
                          : undefined
                      }
                    />

                    <TextField
                      name="companyName"
                      value={values.companyName}
                      onChange={handleChange}
                      label="Company Name"
                      required
                      error={
                        touched.companyName && errors.companyName
                          ? errors.companyName
                          : undefined
                      }
                    />

                    <TextField
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      label="Description"
                      required
                      error={
                        touched.description && errors.description
                          ? errors.description
                          : undefined
                      }
                    />

                    <TextField
                      name="zipCode"
                      value={values.zipCode}
                      onChange={handleChange}
                      label="Zip Code"
                      required
                      error={
                        touched.zipCode && errors.zipCode
                          ? errors.zipCode
                          : undefined
                      }
                    />

                    <div
                      style={{ display: "flex", flexDirection: "row-reverse" }}
                    >
                      <Button
                        loading={isSubmitting}
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Modal>
          </MainLayout.Tabs.Tab>

          <MainLayout.Tabs.Tab name="Membership">
            {subscription ? (
              <>
                {!subscription.cancelAtPeriodEnd && (
                  <EditButton
                    onClick={() => {
                      setMembershipEditVisible(true);
                    }}
                  />
                )}

                <FieldValue label="Subscription">
                  {activeSubscriptionPlan &&
                    `${activeSubscriptionPlan.title}. $${activeSubscriptionPlan.amount} per ${activeSubscriptionPlan.interval}.`}
                  {subscription.cancelAtPeriodEnd && (
                    <>
                      <p className="modest">
                        Will be canceled on{" "}
                        {moment(subscription.currentPeriodEnd).format(
                          "MM/DD/YY"
                        )}
                      </p>
                    </>
                  )}
                </FieldValue>
                <FieldValue label="Payment Method">
                  {paymentMethod && `**** **** **** ${paymentMethod.last4}`}
                </FieldValue>
                <FieldValue label="Payment History">
                  {subscription.trialEnd ? (
                    `Free trial ends ${moment(subscription.trialEnd).format(
                      "MM/DD/YY"
                    )}`
                  ) : (
                    <button
                      className="asLink"
                      onClick={() => {
                        setPaymentHistoryVisible(true);
                      }}
                    >
                      View Payment History
                    </button>
                  )}
                </FieldValue>
              </>
            ) : (
              <EmptyInstructions
                header="Inactive Membership"
                subHeader="Activate your membership to continue using Wingspan"
              >
                <Button
                  onClick={() => {
                    track("Click Choose Membership Plan", {
                      location: "Profile"
                    });
                    dispatch(setSubscribeModalVisible(true));
                  }}
                >
                  Choose Plan
                </Button>
              </EmptyInstructions>
            )}

            {paymentHistory && (
              <Modal
                header="Payment History"
                visible={paymentHistoryVisible}
                onClose={() => {
                  setPaymentHistoryVisible(false);
                }}
              >
                {paymentHistory
                  .filter(invoice => invoice.charge && invoice.amountPaid > 0)
                  .map(invoice => (
                    <SubscriptionInvoicePreview
                      key={invoice.invoiceId}
                      paidAt={
                        invoice.invoiceStatus === "paid"
                          ? invoice.paidAt
                          : undefined
                      }
                      last4={invoice.charge ? invoice.charge.cardLast4 : ""}
                      amount={invoice.amountPaid}
                    />
                  ))}
              </Modal>
            )}

            <Modal
              header="Edit Membership"
              visible={membershipEditVisible}
              onClose={() => {
                setMembershipEditVisible(false);
              }}
            >
              <SubscriptionEditForm
                onCancel={() => {
                  setMembershipEditVisible(false);
                }}
              />
            </Modal>
          </MainLayout.Tabs.Tab>

          <MainLayout.Tabs.Tab name="Settings">
            <EditButton
              onClick={() => {
                setSettingsEditVisible(true);
              }}
            />

            <FieldValue label="Email">{userFields.email}</FieldValue>
            <FieldValue label="Password">*****************</FieldValue>

            <Modal
              header="Edit Settings"
              visible={settingsEditVisible}
              onClose={() => {
                setSettingsEditVisible(false);
              }}
            >
              <Formik
                initialValues={{
                  email: userFields.email,
                  oldPassword: "",
                  newPassword: ""
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email("Please enter a valid email address")
                    .required("Email is required"),
                  oldPassword: Yup.string().required(
                    "Old Password is required"
                  ),
                  newPassword: Yup.string().required("New Password is required")
                })}
                onSubmit={async (values, { setSubmitting, setStatus }) => {
                  try {
                    await resetPassword(values.oldPassword, values.newPassword);
                    setSettingsEditVisible(false);
                  } catch (error) {
                    setStatus(generateFormStatus(error));
                  }
                  setSubmitting(false);
                }}
              >
                {({
                  handleSubmit,
                  status,
                  values,
                  handleChange,
                  touched,
                  errors,
                  isSubmitting
                }) => (
                  <Form>
                    {status && status.type === "error" && (
                      <p style={{ color: "red", marginBottom: 20 }}>
                        {status.message}
                      </p>
                    )}

                    <TextField
                      type="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      label="Email Address"
                      required
                      error={
                        touched.email && errors.email ? errors.email : undefined
                      }
                      disabled
                    />

                    <h4>Change Password</h4>

                    <TextField
                      type="password"
                      name="oldPassword"
                      value={values.oldPassword}
                      onChange={handleChange}
                      label="Old Password"
                      required
                      error={
                        touched.oldPassword && errors.oldPassword
                          ? errors.oldPassword
                          : undefined
                      }
                    />

                    <TextField
                      type="password"
                      name="newPassword"
                      value={values.newPassword}
                      onChange={handleChange}
                      label="New Password"
                      required
                      error={
                        touched.newPassword && errors.newPassword
                          ? errors.newPassword
                          : undefined
                      }
                    />

                    <div
                      style={{ display: "flex", flexDirection: "row-reverse" }}
                    >
                      <Button
                        loading={isSubmitting}
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Modal>
          </MainLayout.Tabs.Tab>
        </MainLayout.Tabs>
      </MainLayout.Main>
    </MainLayout>
  );
};

const mapStateToProps = (state: GlobalStore) => ({
  userFields: getUserFields(state),
  subscription: getMembershipInfo(state),
  paymentMethod: getMembershipPaymentMethod(state),
  paymentHistory: getMembershipPaymentHistory(state),
  plans: getMembershipPlans(state)
});

export default connect(mapStateToProps)(Profile);

import React, { useEffect, useState } from "react";
import Drawer from "./Drawer";
import Header from "./Header";
import classes from "./styles.module.scss";

interface Props {
  title: string;
  pageHeader?: React.ReactNode;
}

const Layout: React.FC<Props> = ({ title, pageHeader, children }) => {
  useEffect(() => {
    document.title = title + " - Wingspan Collective";
    window.analytics.page();
  }, [title]);

  const [drawerVisible, setDrawerVisible] = useState(false);

  return (
    <div className={classes.container}>
      <Header setDrawerVisible={setDrawerVisible} />

      <Drawer
        visible={drawerVisible}
        onClose={() => {
          setDrawerVisible(false);
        }}
      />

      {pageHeader && <div className={classes.pageHeader}>{pageHeader}</div>}

      <main className={classes.main}>{children}</main>
    </div>
  );
};

export default Layout;

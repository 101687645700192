import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import * as Yup from "yup";
import Button from "../components/Button";
import Card from "../components/Card";
import MainLayout from "../components/MainLayout";
import TextField from "../components/TextField";
import { resetPasswordWithCode, validatePasswordReset } from "../services/api";
import { generateFormStatus } from "../utils/form";

const PasswordReset: React.FC<RouteComponentProps<{ code: string }>> = ({
  match: {
    params: { code }
  }
}) => {
  const [error, setError] = useState({
    type: "error",
    message: ""
  });

  useEffect(() => {
    async function checkValidationCode() {
      try {
        await validatePasswordReset(code);
      } catch (error) {
        setError(generateFormStatus(error));
      }
    }

    checkValidationCode();
  }, [code]);

  return (
    <MainLayout title="Reset Password">
      <MainLayout.PageHeader
        header="Reset Password"
        subHeader="Choose a new password for your account."
        backgroundColor="#FFDCBE"
      />
      <MainLayout.Main>
        <Formik
          initialValues={{ code, password: "" }}
          validationSchema={Yup.object().shape({
            code: Yup.string().required("Email is required"),
            password: Yup.string().required("Password is required")
          })}
          onSubmit={async (values, { setSubmitting, setStatus }) => {
            try {
              const { status } = await resetPasswordWithCode(
                code,
                values.password
              );
              setStatus({ type: "success", message: status });
            } catch (error) {
              setStatus(generateFormStatus(error));
            }
            setSubmitting(false);
          }}
        >
          {({
            handleSubmit,
            status,
            values,
            handleChange,
            touched,
            errors,
            isSubmitting
          }) => (
            <Form>
              <Card>
                <Card.Main>
                  {((error && error.message) ||
                    (status && status.type === "error")) && (
                    <p style={{ color: "red", marginBottom: 20 }}>
                      {error.message || status.message}
                    </p>
                  )}
                  {status && status.type === "success" && (
                    <p style={{ color: "green", marginBottom: 20 }}>
                      {status.message}
                    </p>
                  )}

                  <TextField
                    name="code"
                    value={values.code}
                    onChange={handleChange}
                    label="Verification Code"
                    required
                    error={
                      touched.code && errors.code ? errors.code : undefined
                    }
                  />

                  <TextField
                    type="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    label="New Password"
                    required
                    error={
                      touched.password && errors.password
                        ? errors.password
                        : undefined
                    }
                  />
                </Card.Main>
              </Card>
              <MainLayout.Actions>
                <Button
                  loading={isSubmitting}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Reset Password
                </Button>
              </MainLayout.Actions>
            </Form>
          )}
        </Formik>
      </MainLayout.Main>
    </MainLayout>
  );
};

export default PasswordReset;

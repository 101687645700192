import classnames from "classnames";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import AgentListItem from "../components/AgentListItem";
import Button from "../components/Button";
import styles from "../components/Button/styles.module.scss";
import Card from "../components/Card";
import List from "../components/List";
import MainLayout from "../components/MainLayout";
import AGENTS from "../types/team";

const Team: React.FC<RouteComponentProps<null>> = ({ history }) => {
  // Update agents to an API call
  return (
    <MainLayout title="Your Team" currentPage="team">
      <MainLayout.PageHeader
        header="Your Team"
        subHeader="We're standing by to help with anything."
        backgroundColor="#FFECD2"
      />
      <MainLayout.Main>
        <Card>
          <Card.Main noPadding>
            <List>
              {AGENTS.map((agent: any) => (
                <List.Item key={agent.categoryId}>
                  <AgentListItem
                    name={agent.name}
                    categoryId={agent.categoryId}
                    specialties={agent.specialties}
                    avatarUrl={agent.avatarUrl}
                  />
                </List.Item>
              ))}
            </List>
          </Card.Main>
        </Card>

        <div style={{ textAlign: "center" }}>
          <Button
            onClick={() => history.push("/create-goal")}
            className={classnames(styles.button, styles.primary)}
          >
            Create New Goal
          </Button>
        </div>
      </MainLayout.Main>
    </MainLayout>
  );
};

export default Team;

import { Form, Formik } from "formik";
import React from "react";
import { connect, useDispatch } from "react-redux";
import * as Yup from "yup";
import { fetchMembership } from "../../store/actions/fetchMembership";
import * as apiService from "../../services/api";
import * as stripeService from "../../services/stripe";
import { setSubscribeModalVisible } from "../../store/actions/membershipActions";
import {
  getMembershipPlans,
  getSubscriptionModalVisibility
} from "../../store/selectors/membership";
import { GlobalStore } from "../../types/store";
import { makeEnrichIdentifyFromState, track } from "../../utils/analytics";
import { generateFormStatus } from "../../utils/form";
import Button from "../Button";
import Modal from "../Modal";
import PaymentFields from "../PaymentFields";
import SelectSubscriptionPlanField from "../SelectSubscriptionPlanField";

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = StateProps;

const SubscribeModal: React.FC<Props> = ({
  visible,
  plans,
  enrichIdentify
}) => {
  const dispatch = useDispatch();
  const initialPlan = plans.find(plan => plan.interval === "year");

  return (
    <Modal
      visible={visible}
      onClose={() => {
        dispatch(setSubscribeModalVisible(false));
      }}
      header="Membership Details"
    >
      <Formik
        initialValues={{
          plan: initialPlan ? initialPlan.planId : "",
          name: "",
          card: "",
          expirationDate: "",
          cvv: "",
          zip: ""
        }}
        validationSchema={Yup.object().shape({
          plan: Yup.string().required("Plan is required"),
          name: Yup.string().required("Name on Card is required"),
          card: Yup.string().required("Card is required"),
          expirationDate: Yup.string().required("Expiration Date is required"),
          cvv: Yup.string().required("CVV Code is required"),
          zip: Yup.string().required("Billing Zip is required")
        })}
        onSubmit={async (values, { setSubmitting, setStatus, resetForm }) => {
          try {
            setStatus(null);

            track("Membership Purchased", { location: "Profile" });

            const [
              expirationMonth,
              expirationYear
            ] = values.expirationDate.split("/");

            const token = await stripeService.createPaymentMethod({
              name: values.name,
              number: Number(values.card.split(" ").join("")),
              cvc: Number(values.cvv),
              expMonth: Number(expirationMonth),
              expYear: Number(expirationYear),
              zip: Number(values.zip)
            });

            await apiService.updateSubscriptionPaymentMethod(token);

            const subscription = await apiService.updateSubscription(
              values.plan
            );
            await dispatch(fetchMembership());

            enrichIdentify(
              subscription.planNickname,
              new Date(),
              subscription.trialEnd
            );

            dispatch(setSubscribeModalVisible(false));
          } catch (error) {
            setStatus(generateFormStatus(error));
          }
          setSubmitting(false);
        }}
      >
        {({
          handleSubmit,
          status,
          values,
          handleChange,
          touched,
          errors,
          isSubmitting,
          isValid
        }) => (
          <Form>
            {status && status.type === "error" && (
              <p style={{ color: "red", marginBottom: 20 }}>{status.message}</p>
            )}

            <SelectSubscriptionPlanField
              name="plan"
              value={values.plan}
              onChange={handleChange}
              error={touched.plan && errors.plan ? errors.plan : undefined}
              showTitle={true}
            />

            <h4>Payment Method</h4>

            <PaymentFields
              values={values}
              touched={touched}
              errors={errors}
              onChange={handleChange}
            />

            <p className="modest" style={{ textAlign: "center" }}>
              We partner with{" "}
              <a
                href="https://stripe.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Stripe
              </a>{" "}
              for best-in-class payments security.
            </p>

            <div style={{ display: "flex", flexDirection: "row-reverse" }}>
              <Button
                disabled={!isValid}
                loading={isSubmitting}
                onClick={() => {
                  handleSubmit();
                }}
              >
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = (state: GlobalStore) => ({
  visible: getSubscriptionModalVisibility(state),
  plans: getMembershipPlans(state),
  enrichIdentify: makeEnrichIdentifyFromState(state)
});

export default connect(mapStateToProps)(SubscribeModal);

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from "react";
export default (({
  styles = {},
  ...props
}) => React.createElement("svg", _extends({
  "data-name": "Layer 1",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 30 30"
}, props), React.createElement("path", {
  fill: "none",
  d: "M0 0h30v30H0z"
}), React.createElement("circle", {
  cx: "15",
  cy: "10",
  r: "4.25",
  fill: "#ffdcbe"
}), React.createElement("path", {
  d: "M15 6.5a3.5 3.5 0 11-3.5 3.5A3.5 3.5 0 0115 6.5M15 5a5 5 0 105 5 5 5 0 00-5-5z",
  fill: "#fff"
}), React.createElement("path", {
  d: "M5.78 24.25a9.25 9.25 0 0118.44 0z",
  fill: "#ffdcbe"
}), React.createElement("path", {
  d: "M15 16.5a8.52 8.52 0 018.37 7H6.63a8.52 8.52 0 018.37-7m0-1.5A10 10 0 005 25h20a10 10 0 00-10-10z",
  fill: "#fff"
}), React.createElement("path", {
  fill: "#ffdcbe",
  d: "M11 16l2 9M19 16l-2 9M17 10a2 2 0 01-4 0"
}), React.createElement("path", {
  d: "M17.61 14.84a5.5 5.5 0 10-5.22 0A10.51 10.51 0 004.5 25a.5.5 0 00.5.5h20a.5.5 0 00.5-.5 10.51 10.51 0 00-7.89-10.16zM10.5 10a4.5 4.5 0 114.5 4.5 4.51 4.51 0 01-4.5-4.5zm8 6.16l-1.9 8.34h-3.2l-1.85-8.34a9.35 9.35 0 016.9 0zm-7.83.41l1.76 7.93H5.51a9.53 9.53 0 015.11-7.93zm7 7.93l1.76-7.93a9.53 9.53 0 015.11 7.93z",
  fill: "#282d37"
}), React.createElement("path", {
  d: "M17 9.5a.5.5 0 00-.5.5 1.5 1.5 0 01-3 0 .5.5 0 00-1 0 2.5 2.5 0 005 0 .5.5 0 00-.5-.5z",
  fill: "#282d37"
})));
import axios from "axios";
import queryString from "query-string";

const request = axios.create({
  baseURL: "https://api.stripe.com/v1",
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    Accept: "application/json",
    authority: "api.stripe.com",
    Authorization: `Bearer ${process.env.REACT_APP_STRIPE_KEY}`
  }
});

export interface CreatePaymentMethodOptions {
  name: string;
  number: number;
  cvc: number;
  expMonth: number;
  expYear: number;
  zip: number;
}

export const createPaymentMethod = async ({
  name,
  number,
  cvc,
  expMonth,
  expYear,
  zip
}: CreatePaymentMethodOptions) => {
  const { data } = await request.post(
    "/payment_methods",
    queryString.stringify({
      type: "card",
      "card[number]": number,
      "card[cvc]": cvc,
      "card[exp_month]": expMonth,
      "card[exp_year]": expYear,
      "billing_details[name]": name,
      "billing_details[address][postal_code]": zip
    })
  );

  return data.id;
};

import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { getUserAuthorized, getUserRoleId } from "../../store/selectors/user";
import { GlobalStore } from "../../types/store";

type OwnProps = {
  allowedRoleIds?: number[];
};

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & RouteProps & StateProps;

const PrivateRoute: React.SFC<Props> = ({
  component: Component,
  userAuthorized,
  userRoleId,
  allowedRoleIds = [0, 1],
  ...props
}) =>
  Component ? (
    <Route
      {...props}
      render={props =>
        userAuthorized && userRoleId !== undefined ? (
          allowedRoleIds.includes(userRoleId) ? (
            <Component {...props}></Component>
          ) : userRoleId === 2 ? (
            <Redirect to="/waitlist" />
          ) : (
            <Redirect to="/dashboard" />
          )
        ) : (
          <Redirect to={`/sign-in#${window.location.pathname}`} />
        )
      }
    ></Route>
  ) : null;

const mapStateToProps = (state: GlobalStore) => ({
  userAuthorized: getUserAuthorized(state),
  userRoleId: getUserRoleId(state)
});

export default connect(mapStateToProps)(PrivateRoute);

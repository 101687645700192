import React from "react";
import classes from "./styles.module.scss";

interface Props {
  width?: number | string;
}

const Container: React.FC<Props> = ({ children, width }) => (
  <div className={classes.container} style={{ maxWidth: width }}>
    {children}
  </div>
);

export default Container;

import { GlobalStore } from "../../types/store";
import { IInvoiceStatus } from "../../types/paymentsService";

export const getInvoicesListIds = (state: GlobalStore) =>
  state.payments.invoices.listIds;

export const getInvoicesListStats = (state: GlobalStore) =>
  state.payments.invoices.stats;

export const getInvoiceDataById = (state: GlobalStore, invoiceId: string) =>
  state.payments.invoices.byId[invoiceId];

export const getInvoicePreview = (state: GlobalStore) =>
  state.payments.invoices.preview;

export const getInvoicesIsSent = (state: GlobalStore) =>
  getInvoicesListIds(state).some(
    id => getInvoiceDataById(state, id).status !== IInvoiceStatus.Draft
  );

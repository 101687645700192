import React from "react";
import classes from "./styles.module.scss";
import classnames from "classnames";

interface Props {
  type?: "button" | "link";
  color?: "primary" | "secondary" | "tertiary";
  disabled?: boolean;
  padding?: "none" | "wide"; // useful for type="link"
  className?: string;
  loading?: boolean;
  onClick?: () => any;
  icon?: any;
  size?: "normal" | "small";
}

const Button: React.FC<Props> = ({
  type,
  color,
  disabled,
  padding,
  loading,
  className,
  onClick,
  children,
  icon,
  size
}) => {
  if (!type) type = "button";
  if (!color) color = "primary";
  if (!size) size = "normal";

  return (
    <button
      disabled={disabled || loading}
      onClick={onClick}
      className={classnames(className, {
        [classes.button]: type === "button",
        [classes.link]: type === "link",
        [classes.primary]: color === "primary",
        [classes.secondary]: color === "secondary",
        [classes.disabled]: disabled,
        [classes.loading]: loading,
        [classes.tertiary]: color === "tertiary",
        [classes.noPadding]: padding === "none",
        [classes.widePadding]: padding === "wide",
        [classes.small]: size === "small"
      })}
    >
      {loading ? (
        <div className={classes.loader} />
      ) : (
        <>
          {icon && <div className={classes.icon}>{icon}</div>}
          {children}
        </>
      )}
    </button>
  );
};

export default Button;

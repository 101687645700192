import { AppThunk } from "../../types/store";
import { createAction } from "../meta/types";
import {
  FETCH_GOAL_CREATION_CONFIG,
  FETCH_MEMBERSHIP_PLANS,
  FETCH_USER,
  INIT_APP,
  INIT_APP_FAILURE,
  INIT_APP_REQUEST,
  INIT_APP_SUCCESS
} from "../constants";
import { fetchUser } from "./fetchUser";
import { fetchMembershipPlans } from "./fetchMembershipPlans";
import { fetchGoalCreationConfig } from "./fetchGoalCreationConfig";
import { getMetaError } from "../meta/selectors";

export const initAppActions = createAction(
  INIT_APP,
  INIT_APP_REQUEST,
  INIT_APP_SUCCESS,
  INIT_APP_FAILURE
)();

export const initApp = (): AppThunk<any> => async (dispatch, getState) => {
  dispatch(initAppActions.request());

  await Promise.all([
    dispatch(fetchUser()),
    dispatch(fetchMembershipPlans()),
    dispatch(fetchGoalCreationConfig())
  ]);

  const state = getState();

  if (
    getMetaError(state, FETCH_USER) ||
    getMetaError(state, FETCH_MEMBERSHIP_PLANS) ||
    getMetaError(state, FETCH_GOAL_CREATION_CONFIG)
  ) {
    dispatch(initAppActions.failure());
  } else {
    dispatch(initAppActions.success());
  }
};

import * as apiService from "../../services/api";
import { signUpActions } from "./userActions";
import { AppThunk } from "../../types/store";
import { track } from "../../utils/analytics";
import { setAuthToken } from "../../utils/auth";
import { fetchUser } from "./fetchUser";

export const signUp = (
  data: apiService.SignUpData
): AppThunk<any> => async dispatch => {
  dispatch(signUpActions.request());

  try {
    const { token } = await apiService.signUp(data);
    setAuthToken(token);
    await dispatch(fetchUser());
    track("Registration Complete");

    dispatch(signUpActions.success());
  } catch (error) {
    dispatch(signUpActions.failure(error));
  }
};

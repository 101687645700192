import React from "react";
import { connect } from "react-redux";
import { getMembershipPlans } from "../../../store/selectors/membership";
import { GlobalStore } from "../../../types/store";
import Row from "../../Row";
import PlanOption from "./PlanOption";
import classes from "./styles.module.scss";

type OwnProps = {
  value: string;
  onChange: (planId: string) => void;
};

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StateProps;

const SelectSubscriptionPlanField: React.FC<Props> = ({
  plans,
  value,
  onChange
}) => (
  <div className={classes.container}>
    <Row>
      {plans.map(plan => (
        <Row.Col key={plan.planId}>
          <PlanOption
            selected={plan.planId === value}
            mostPopular={plan.interval === "year"}
            monthlyPrice={
              plan.interval === "year" ? plan.amount / 12 : plan.amount
            }
            interval={plan.interval}
            annualSavings={plan.interval === "year" ? 120 : undefined}
            onClick={() => {
              onChange(plan.planId);
            }}
          />
        </Row.Col>
      ))}
    </Row>
  </div>
);

const mapStateToProps = (state: GlobalStore) => ({
  plans: getMembershipPlans(state)
});

export default connect(mapStateToProps)(SelectSubscriptionPlanField);

import paymentsService from "../../services/payments";
import { AppThunk } from "../../types/store";
import { trackInvoiceAction } from "../../utils/analytics";
import {
  SEND_INVOICE_REMINDER,
  SEND_INVOICE_REMINDER_FAILURE,
  SEND_INVOICE_REMINDER_REQUEST,
  SEND_INVOICE_REMINDER_SUCCESS
} from "../constants";
import { createAction } from "../meta/types";
import { getInvoiceDataById } from "../selectors/invoices";
import { addNotification } from "../../components/Notification/Notification";
import { getMemberClientDataByInvoiceId } from "../selectors/getMemberClientDataByInvoiceId";

export const sendInvoiceReminderActions = createAction(
  SEND_INVOICE_REMINDER,
  SEND_INVOICE_REMINDER_REQUEST,
  SEND_INVOICE_REMINDER_SUCCESS,
  SEND_INVOICE_REMINDER_FAILURE
)<{ invoiceId: string }, { invoiceId: string }, Error>();

export const sendInvoiceReminder = (invoiceId: string): AppThunk<any> => async (
  dispatch,
  getState
) => {
  dispatch(sendInvoiceReminderActions.request({ invoiceId }, invoiceId));

  try {
    const invoice = getInvoiceDataById(getState(), invoiceId);

    await paymentsService.updateInvoice(invoiceId, {});
    trackInvoiceAction("Send Reminder", invoiceId, invoice.status);
    const member = getMemberClientDataByInvoiceId(getState(), invoiceId);

    if (member) {
      if (member.emailCC?.length) {
        addNotification({
          text: `Your invoice was sent to ${
            member.name
          } and ${member.emailCC.join(", ")}`
        });
      } else {
        addNotification({ text: `Your invoice was sent to ${member.name}.` });
      }
    }
    
    dispatch(sendInvoiceReminderActions.success({ invoiceId }, invoiceId));
  } catch (error) {
    dispatch(sendInvoiceReminderActions.failure(error, invoiceId));
  }
};

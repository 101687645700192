import { AppThunk } from "../../types/store";
import { trackInvoiceAction } from "../../utils/analytics";
import {
  COPY_INVOICE_LINK,
  COPY_INVOICE_LINK_FAILURE,
  COPY_INVOICE_LINK_REQUEST,
  COPY_INVOICE_LINK_SUCCESS
} from "../constants";
import { createAction } from "../meta/types";
import { getInvoiceDataById } from "../selectors/invoices";

export const copyInvoiceLinkActions = createAction(
  COPY_INVOICE_LINK,
  COPY_INVOICE_LINK_REQUEST,
  COPY_INVOICE_LINK_SUCCESS,
  COPY_INVOICE_LINK_FAILURE
)<{ invoiceId: string }, { invoiceId: string }, Error>();

export const copyInvoiceLink = (invoiceId: string): AppThunk<any> => async (
  dispatch,
  getState
) => {
  dispatch(copyInvoiceLinkActions.request({ invoiceId }, invoiceId));

  try {
    const invoice = getInvoiceDataById(getState(), invoiceId);
    if (invoice?.attachments.invoiceLink) {
      navigator.clipboard.writeText(invoice.attachments.invoiceLink);
    }

    trackInvoiceAction("Copy Invoice Link", invoiceId, invoice.status);

    dispatch(copyInvoiceLinkActions.success({ invoiceId }, invoiceId));
  } catch (error) {
    dispatch(copyInvoiceLinkActions.failure(error, invoiceId));
  }
};

import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { getRecentActivity } from "../../services/api";
import Card from "../Card";
import EmptyInstructions from "../EmptyInstructions";
import Fetch from "../Fetch";
import Goal from "../Goal";

interface Props extends RouteComponentProps {}

const RecentActivityWidget: React.FC<Props> = ({ history }) => (
  <Card>
    <Card.Header>Recent Activity</Card.Header>
    <Card.Main>
      <Fetch request={getRecentActivity}>
        {({ data }) => (
          <>
            {data.length > 0 ? (
              data.map((ticket: any, index: number) => (
                <Goal
                  category={ticket.category}
                  subject={ticket.subject}
                  lastMessageName={ticket.agentName}
                  lastMessageText={ticket.description}
                  lastMessagePhoto={ticket.agentPhoto}
                  key={index}
                  onClick={() => {
                    history.push(`/goals/f/${ticket.freshdeskTicketId}`);
                  }}
                  noHover
                />
              ))
            ) : (
              <EmptyInstructions
                header="Nothing Yet"
                subHeader="We'll show you recent activity &amp; to-dos here once you create some Goals."
              />
            )}
          </>
        )}
      </Fetch>
    </Card.Main>

    <Card.Footer>
      <Link to="/goals">View All Your Goals</Link>
    </Card.Footer>
  </Card>
);

export default withRouter(RecentActivityWidget);

import { FieldArray, Form, Formik } from "formik";
import React, { useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import * as Yup from "yup";
import {
  getMemberClientsById,
  getMemberClientsListIds
} from "../../../store/selectors/memberClients";
import { GlobalStore } from "../../../types/store";
import { lateFeeAfterOptions } from "../../../utils/invoiceHelper";
import Container from "../../Container";
import { Mobile, TabletDesktop } from "../../Responsive";
import FormikAutocompleteField from "../AutocompleteField/Formik";
import Button from "../Button";
import FormikDateField from "../DateField/Formik";
import FormikDropDownField from "../DropDownField/Formik";
import FormExpandField from "../FormExpandField";
import FormSection from "../FormSection";
import Row from "../FormSection/Row";
import FormikSwitchCheckboxField from "../SwitchCheckboxField/Formik";
import FormikTextAreaField from "../TextAreaField/Formik";
import FormikTextField from "../TextField/Formik";
import classes from "./styles.module.scss";

export interface InvoiceFormValues {
  memberClientId: string;
  company: string;
  name: string;
  email: string;
  additionalEmails: string[];
  lineItems: { title: string; amount?: string }[];
  clientPaysProcessingFee: boolean;
  dueDateType: "on-receipt" | "days-after-receipt" | "date" | "hidden";
  dueDateAfter: number;
  dueDate: Date;
  notes: string;
  projectName: string;
  lateFee: "none" | "percent" | "fixed";
  lateFeeAmount: string;
  lateFeeAfter: "7" | "14" | "30" | "every7" | "every14" | "every30";
}

const RemoveAdditional: React.FC<{ onClick(): void }> = ({
  children,
  onClick
}) => (
  <div className={classes.removeAdditional} onClick={onClick}>
    <span>{children}</span>
  </div>
);

type OwnProps = {
  initialValues: InvoiceFormValues;
  title: string;
  onSave: (values: InvoiceFormValues) => void;
  onSend: (values: InvoiceFormValues) => void;
  onPreview: (values: InvoiceFormValues) => void;
};
type MapStateToProps = ReturnType<typeof mapStateToProps>;
type RouterProps = RouteComponentProps;
type Props = OwnProps & MapStateToProps & RouterProps;

const InvoiceForm: React.FC<Props> = ({
  initialValues,
  title,
  onSave,
  onSend,
  onPreview,
  memberClientListIds,
  memberClientById,
  history
}) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [submitType, setSubmitType] = useState<"send" | "save" | "preview">(
    "save"
  );

  const [showNotes, setShowNotes] = useState(!!initialValues.notes);
  const [showProjectName, setShowProjectName] = useState(
    !!initialValues.projectName
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        memberClientId: Yup.string(),
        company: Yup.string().required("Company is required"),
        name: Yup.string().required("Full name is required"),
        email: Yup.string()
          .email("Please enter a valid email address")
          .required("Email is required"),
        additionalEmails: Yup.array(
          Yup.string().email("Please enter a valid email address")
        ),
        lineItems: Yup.array(
          Yup.object().shape({
            title: Yup.string().required("Required"),
            amount: Yup.number()
              .typeError("Required")
              .min(1, "Required")
              .required("Required")
          })
        ),
        clientPaysProcessingFee: Yup.boolean(),
        notes: Yup.string(),
        projectName: Yup.string()
      })}
      onSubmit={async values => {
        switch (submitType) {
          case "save": {
            setSaveLoading(true);
            await onSave(values);
            setSaveLoading(false);
            break;
          }

          case "send": {
            setSendLoading(true);
            await onSend(values);
            setSendLoading(false);
            break;
          }

          case "preview": {
            setPreviewLoading(true);
            await onPreview(values);
            setPreviewLoading(false);
            break;
          }
          default:
            break;
        }
      }}
    >
      {({ values, setFieldValue, handleSubmit }) => {
        const handleSave = async () => {
          setSubmitType("save");
          handleSubmit();
        };

        const handleSend = async () => {
          setSubmitType("send");
          handleSubmit();
        };

        const handlePreview = async () => {
          setSubmitType("preview");
          handleSubmit();
        };

        return (
          <div className={classes.container}>
            <header className={classes.header}>
              <Container width={1180}>
                <div className={classes.inner}>
                  <div className={classes.title}>
                    <div
                      className={classes.backButton}
                      onClick={() => {
                        history.goBack();
                      }}
                    />
                    {title}
                  </div>
                  <div className={classes.actions}>
                    <TabletDesktop>
                      <Button
                        secondary
                        loading={previewLoading}
                        onClick={handlePreview}
                      >
                        Preview
                      </Button>
                      <Button
                        secondary
                        loading={saveLoading}
                        onClick={handleSave}
                      >
                        Save & exit
                      </Button>
                      <Button loading={sendLoading} onClick={handleSend}>
                        Send invoice
                      </Button>
                    </TabletDesktop>
                  </div>
                </div>
              </Container>
            </header>
            <main className={classes.main}>
              <Container width={980}>
                <Form>
                  <FormSection title="Client details">
                    <input
                      type="hidden"
                      name="memberClientId"
                      value={values.memberClientId}
                    />
                    <FormikAutocompleteField
                      name="company"
                      label="Company"
                      options={memberClientListIds.map(memberClientId => {
                        const memberClient =
                          memberClientById[memberClientId]?.data;

                        return {
                          value: memberClient?.memberClientId || "",
                          label: memberClient?.company || ""
                        };
                      })}
                      onAutoComplete={value => {
                        const memberClient = memberClientById[value]?.data;

                        if (memberClient) {
                          setFieldValue("memberClientId", value);
                          setFieldValue("company", memberClient.company);
                          setFieldValue("name", memberClient.name);
                          setFieldValue("email", memberClient.emailTo);
                          setFieldValue(
                            "additionalEmails",
                            memberClient.emailCC
                          );
                        }
                      }}
                    />
                    <FormikTextField name="name" label="Full name" />
                    <FormikTextField name="email" type="email" label="Email" />

                    <FieldArray
                      name="additionalEmails"
                      render={arrayHelpers => (
                        <>
                          {values.additionalEmails.map((_, index) => (
                            <Row key={index}>
                              <FormikTextField
                                name={`additionalEmails[${index}]`}
                                type="email"
                                label={`Additional email ${index +
                                  1} (optional)`}
                              />

                              <RemoveAdditional
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                Remove email
                              </RemoveAdditional>
                            </Row>
                          ))}
                          <FormExpandField
                            label="Add email recipients"
                            onClick={() => arrayHelpers.push("")}
                          />
                        </>
                      )}
                    />
                  </FormSection>
                  <FormSection title="Payment details">
                    <FieldArray
                      name="lineItems"
                      render={arrayHelpers => (
                        <>
                          {values.lineItems.map((_, index) => (
                            <Row key={index}>
                              <FormikTextField
                                name={`lineItems[${index}].title`}
                                label={`Item ${index + 1} title`}
                              />
                              <FormikTextField
                                name={`lineItems[${index}].amount`}
                                label="Amount"
                                formatOptions={{
                                  prefix: "$",
                                  numeral: true,
                                  numeralThousandsGroupStyle: "thousand",
                                  rawValueTrimPrefix: true
                                }}
                              />
                              {values.lineItems.length !== 1 ? (
                                <RemoveAdditional
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  Remove item {index + 1}
                                </RemoveAdditional>
                              ) : null}
                            </Row>
                          ))}

                          <FormExpandField
                            label="Add line item"
                            onClick={() =>
                              arrayHelpers.push({ title: "", amount: "" })
                            }
                            total={values.lineItems.reduce(
                              (total, item) => total + Number(item.amount || 0),
                              0
                            )}
                          />
                        </>
                      )}
                    />

                    <FormikSwitchCheckboxField
                      name="clientPaysProcessingFee"
                      label="Client pays processing fees"
                    />

                    {values.dueDateType === "hidden" ? (
                      <FormikDateField name="dueDate" label="Due date" />
                    ) : (
                      <Row>
                        <FormikDropDownField
                          name="dueDateType"
                          label="Due date"
                          options={[
                            {
                              value: "on-receipt",
                              label: "On receipt"
                            },
                            {
                              value: "days-after-receipt",
                              label: "# of days after receipt"
                            },
                            {
                              value: "date",
                              label: "Specific date"
                            }
                          ]}
                        />

                        {values.dueDateType === "days-after-receipt" && (
                          <FormikTextField
                            type="number"
                            name="dueDateAfter"
                            label="After"
                            measurement="days"
                          />
                        )}

                        {values.dueDateType === "date" && (
                          <FormikDateField name="dueDate" label="Date" />
                        )}
                      </Row>
                    )}
                  </FormSection>
                  <FormSection title="Invoice details">
                    <Row>
                      <FormikDropDownField
                        name="lateFee"
                        label="Late fee"
                        options={[
                          {
                            value: "percent",
                            label: "% of invoice"
                          },
                          {
                            value: "fixed",
                            label: "Fixed fee"
                          },
                          {
                            value: "none",
                            label: "None"
                          }
                        ]}
                      />

                      {values.lateFee !== "none" && (
                        <>
                          {values.lateFee === "percent" ? (
                            <FormikTextField
                              name="lateFeeAmount"
                              label="Amount"
                              measurement="% of invoice"
                            />
                          ) : (
                            <FormikTextField
                              name="lateFeeAmount"
                              label="Amount"
                              formatOptions={{
                                prefix: "$",
                                numeral: true,
                                numeralThousandsGroupStyle: "thousand",
                                rawValueTrimPrefix: true
                              }}
                            />
                          )}

                          <FormikDropDownField
                            name="lateFeeAfter"
                            label="After"
                            options={lateFeeAfterOptions}
                          />
                        </>
                      )}
                    </Row>

                    {showNotes ? (
                      <Row>
                        <FormikTextAreaField
                          name="notes"
                          label="Note (optional)"
                        />
                        <RemoveAdditional
                          onClick={() => {
                            setFieldValue("notes", "");
                            setShowNotes(false);
                          }}
                        >
                          Remove notes
                        </RemoveAdditional>
                      </Row>
                    ) : (
                      <FormExpandField
                        label="Add note"
                        onClick={() => setShowNotes(true)}
                      />
                    )}

                    {showProjectName ? (
                      <Row>
                        <FormikTextAreaField
                          name="projectName"
                          label="Project name (optional)"
                        />
                        <RemoveAdditional
                          onClick={() => {
                            setFieldValue("projectName", "");
                            setShowProjectName(false);
                          }}
                        >
                          Remove Project name
                        </RemoveAdditional>
                      </Row>
                    ) : (
                      <FormExpandField
                        label="Add project name"
                        onClick={() => setShowProjectName(true)}
                      />
                    )}
                  </FormSection>
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                </Form>
              </Container>
            </main>
            <Mobile>
              <footer className={classes.footer}>
                <Button
                  secondary
                  loading={previewLoading}
                  onClick={handlePreview}
                  small
                  noBorder
                >
                  Preview
                </Button>
                <Button
                  secondary
                  loading={saveLoading}
                  onClick={handleSave}
                  small
                  noBorder
                >
                  Save & exit
                </Button>
                <Button loading={sendLoading} onClick={handleSend} small>
                  Send invoice
                </Button>
              </footer>
            </Mobile>
          </div>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: GlobalStore) => ({
  memberClientListIds: getMemberClientsListIds(state),
  memberClientById: getMemberClientsById(state)
});

export const InvoiceFormContainer = withRouter(
  connect(mapStateToProps)(InvoiceForm)
);

import React from "react";

import classes from "./AgentHeader.module.scss";
import { TabletDesktop } from "../Responsive";
import ProfilePhoto from "../ProfilePhoto";
import { useMediaQuery } from "react-responsive";

interface Props {
  assignedAgent: any;
  onClick: () => void;
}

const AgentHeader: React.FC<Props> = ({ assignedAgent, onClick }) => {
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)"
  });

  return (
    <div
      className={classes.container}
      onClick={() => {
        if (isMobile) {
          onClick();
        }
      }}
    >
      <div className={classes.agentInfo}>
        <ProfilePhoto
          name={assignedAgent.agentName}
          url={assignedAgent.avatarUrl}
          className={classes.avatar}
        />
        <div className={classes.innerRight}>
          <div className={classes.supportName}>{assignedAgent.agentName}</div>
          {assignedAgent.specialties && (
            <div className={classes.supportSub}>
              {assignedAgent.specialties}
            </div>
          )}
        </div>
      </div>
      <TabletDesktop>
        <div className={classes.detailsTrigger} onClick={onClick}>
          Show Details
        </div>
      </TabletDesktop>
    </div>
  );
};

export default AgentHeader;

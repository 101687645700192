import React from "react";
import arrow from "../../assets/icons/arrow_dropdown_24.svg";
import ProfilePhoto from "../ProfilePhoto";
import Item from "./Item";
import classes from "./styles.module.scss";

interface Props {
  name: string;
}

interface Component extends React.FC<Props> {
  Item: typeof Item;
}

const ProfileDropdown: Component = ({ name, children }) => (
  <div className={classes.container}>
    <ProfilePhoto className={classes.avatar} name={name} />
    <span className={classes.userName}>{name}</span>
    <img src={arrow} alt="dropdown" />

    <div className={classes.dropdown}>{children}</div>
  </div>
);

ProfileDropdown.Item = Item;

export default ProfileDropdown;

function federalTaxes(taxableIncome: number) {
  // Returns 2020 total federal tax burden for given taxable income.
  const brackets: { min: number; max: number | null; rate: number }[] = [
    { min: 0, max: 9875, rate: 0.1 },
    { min: 9876, max: 40125, rate: 0.12 },
    { min: 40126, max: 85525, rate: 0.22 },
    { min: 85526, max: 163300, rate: 0.24 },
    { min: 163301, max: 207530, rate: 0.32 },
    { min: 207531, max: 518400, rate: 0.35 },
    { min: 518401, max: null, rate: 0.37 }
  ];

  let taxBurden = 0;
  let bracket: { min: number; max: number | null; rate: number };
  for (let i = 0; i < brackets.length; i++) {
    bracket = brackets[i];
    if (bracket["max"] && taxableIncome > bracket["max"]) {
      taxBurden += (bracket["max"] - bracket["min"]) * bracket["rate"];
    } else {
      taxBurden += (taxableIncome - bracket["min"]) * bracket["rate"];
      break;
    }
  }

  return taxBurden;
}

const STANDARD_DEDUCTION = 12000;

// Both functions return income after tax
function taxSoleProprietor(revenue: number, expenses: number) {
  const taxRateSE = 0.153,
    incomeFactorSE = 0.9235,
    deductibleFactorSE = 0.5;
  const incomeSE = (revenue - expenses) * incomeFactorSE;
  const taxSE = incomeSE * taxRateSE;
  const deductibleSE = taxSE * deductibleFactorSE;
  const taxableIncome = revenue - expenses - deductibleSE - STANDARD_DEDUCTION;

  return federalTaxes(taxableIncome) + taxSE;
}

function taxSCorp(revenue: number, expenses: number) {
  const fica = 0.0765,
    futa = 0.006;

  let salary = 40000;
  if (revenue >= 150000) {
    salary = 50000;
  }

  const payrollTaxesCorp = salary * (fica + futa);
  const payrollTaxesIndividual = salary * fica;
  const netIncomeCorp = revenue - expenses - salary - payrollTaxesCorp;
  const taxableIncome = salary + netIncomeCorp - STANDARD_DEDUCTION;

  return (
    federalTaxes(taxableIncome) + payrollTaxesCorp + payrollTaxesIndividual
  );
}

export function calculatePotentialSavings(
  revenue: number,
  expenses: number
): { from: number; to: number } {
  let taxDelta =
    taxSoleProprietor(revenue, expenses) - taxSCorp(revenue, expenses);
  const minDelta = 1000;
  if (taxDelta <= minDelta) {
    taxDelta = minDelta;
  }
  const confidence = 0.05;

  return {
    from: taxDelta * (1 - confidence),
    to: taxDelta * (1 + confidence)
  };
}

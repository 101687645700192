import React from "react";
import Benefits from "../../components/onboarding/Benefits";
import Button from "../../components/onboarding/Button";
import Layout from "../../components/onboarding/Layout";
import { RouteComponentProps } from "react-router-dom";

const Intro: React.FC<RouteComponentProps> = ({ history }) => (
  <Layout title="Welcome to Wingspan!">
    <h1>Welcome to Wingspan!</h1>
    <p>
      Your Wingspan membership includes everything critical to running a
      successful freelance business:
    </p>

    <Benefits />

    <p>We’ll start with some simple questions to get you going.</p>

    <Button
      onClick={() => {
        history.push("/onboarding/questionnaire/1");
      }}
    >
      Let's Go!
    </Button>
  </Layout>
);

export default Intro;

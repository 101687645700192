import React from "react";
import { Slider } from "@material-ui/core";

interface Props {
  options: {
    label?: string;
    value: string | number;
  }[];
  value: number;
  onChange: (value: number) => void;
  error?: string;
}

const SelectSlider: React.FC<Props> = ({ options, value, onChange, error }) => {
  const selectedOption = options.find(option => Number(option.value) === value);
  let selectedOptionIndex = 0;
  options.forEach((option, index) => {
    if (Number(option.value) === value) selectedOptionIndex = index;
  });
  return (
    <div>
      {selectedOption && (
        <p>
          <b>
            {selectedOptionIndex + 1} out of {options.length}
          </b>
          <br />
          {selectedOption.label || selectedOption.value}
        </p>
      )}
      <Slider
        value={value}
        defaultValue={1}
        min={1}
        max={options.length}
        onChange={(_, value) => {
          onChange(value as number);
        }}
      />
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

export default SelectSlider;

import React from "react";
import { connect } from "react-redux";
import CreateNewGoalWidget from "../components/CreateNewGoalWidget";
import MainLayout from "../components/MainLayout";
import { GlobalStore } from "../types/store";
import { getGoalCreationConfig } from "../store/selectors/goals";

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = StateProps;

const Dashboard: React.FC<Props> = ({ config }) => (
  <MainLayout title={config && config.header}>
    <MainLayout.PageHeader backgroundColor="#FFDCBE">
      <h1 className="hero">Create Goal</h1>
      <h3>{config.header}</h3>
    </MainLayout.PageHeader>
    <MainLayout.Main>
      <CreateNewGoalWidget />
    </MainLayout.Main>
  </MainLayout>
);

const mapStateToProps = (state: GlobalStore) => ({
  config: getGoalCreationConfig(state)
});

export default connect(mapStateToProps)(Dashboard);

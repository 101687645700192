import React from "react";
import classnames from "classnames";
import Avatar from "@material-ui/core/Avatar";
import AssetLink from "./AssetLink";
import classes from "./ChatReply.module.scss";

interface Props {
  text: string;
  fileName: string;
  fileUrl: string;
  showAvatar: boolean;
  isCurrentUser: boolean;
  agent: any;
}

const ChatReply: React.FC<Props> = ({
  text,
  showAvatar,
  isCurrentUser,
  fileName,
  fileUrl,
  agent
}) => {
  if (!isCurrentUser && !agent) {
    return <></>;
  }
  return (
    <div
      className={classnames({
        [classes.container]: true,
        [classes.isCurrentUser]: !!isCurrentUser,
        [classes.avatarSpace]: !isCurrentUser && !showAvatar
      })}
    >
      {!isCurrentUser && showAvatar && agent.avatarUrl && (
        <Avatar
          alt={agent.agentName}
          src={agent.avatarUrl}
          className={classes.avatar}
        />
      )}
      {!isCurrentUser && showAvatar && !agent.avatarUrl && (
        <Avatar alt={agent.agentName} className={classes.avatar}>
          {agent.agentName && agent.agentName.length > 0
            ? agent.agentName[0]
            : "A"}
        </Avatar>
      )}
      <div className={classes.text}>
        {fileName && fileUrl && <AssetLink fileName={fileName} url={fileUrl} />}
        {text}
      </div>
    </div>
  );
};

export default ChatReply;

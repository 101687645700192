import { formatMoney } from "accounting";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import Button from "../../components/onboarding/Button";
import Layout from "../../components/onboarding/Layout";
import { getUserQuestionnaire } from "../../store/selectors/user";
import { GlobalStore } from "../../types/store";
import { calculatePotentialSavings } from "../../utils/potentialSavings";
import classes from "./Success.module.scss";

type StateProps = ReturnType<typeof mapStateToProps>;

type RouteProps = RouteComponentProps;

type Props = RouteProps & StateProps;

const Success: React.FC<Props> = ({ history, userQuestionnaire }) => {
  const questionnaireObj: any = {};
  userQuestionnaire.forEach(field => {
    questionnaireObj[field.name] = field.response;
  });

  const potentialSavings = calculatePotentialSavings(
    questionnaireObj.revenue,
    questionnaireObj.expenses
  );

  return (
    <Layout title="Welcome to Wingspan!">
      <Layout.MainBox className={classes.innerContainer}>
        <div className={classes.label}>Potential Savings in 2020:</div>
        <div className={classes.sum}>
          {formatMoney(potentialSavings.from, {
            precision: 0
          })}{" "}
          –{" "}
          {formatMoney(potentialSavings.to, {
            precision: 0
          })}
        </div>
        <div className={classes.description}>
          Woah, that’s a lot of chedda! Based on your inputs you could save a
          lot using Wingspan.
        </div>
        <Button
          onClick={() => {
            history.push("/onboarding/subscribe");
          }}
        >
          Let’s get started!
        </Button>
      </Layout.MainBox>
      <div className={classes.info}>
        Potential savings are based on savings from S-corp election and
        maximizing business deductions for single filing status. These values
        are for illustrative purposes only and are not intended to be a
        substitute for consultation with your tax advisor.
      </div>
    </Layout>
  );
};

const mapStateToProps = (state: GlobalStore) => ({
  userQuestionnaire: getUserQuestionnaire(state)
});

export default connect(mapStateToProps)(Success);

import React from "react";
import Avatar from "@material-ui/core/Avatar";
import classes from "./ChatDetail.module.scss";
import classnames from "classnames";

interface Props {
  attributes: [any];
  subject: string;
  description: string;
  assignedAgent: any;
  className?: string;
}

const ChatDetail: React.FC<Props> = ({
  attributes,
  subject,
  description,
  assignedAgent,
  className
}) => {
  return (
    <div className={classnames(classes.container, className)}>
      <div className={classes.subject}>{subject}</div>
      <div className={classes.description}>{description}</div>
      {assignedAgent && assignedAgent.agentName && (
        <>
          <div className={classes.primarySupportLabel}>Primary Support</div>
          <div className={classes.primarySupportContainer}>
            {assignedAgent.avatarUrl && (
              <Avatar
                alt={assignedAgent.agentName}
                src={assignedAgent.avatarUrl}
                className={classes.avatar}
              />
            )}
            {!assignedAgent.avatarUrl && (
              <Avatar alt={assignedAgent.agentName} className={classes.avatar}>
                {assignedAgent.agentName.length > 0
                  ? assignedAgent.agentName[0]
                  : "A"}
              </Avatar>
            )}
            <div className={classes.primarySupportInnerRight}>
              <div className={classes.supportName}>
                {assignedAgent.agentName}
              </div>
              {assignedAgent.specialties && (
                <div className={classes.supportSub}>
                  {assignedAgent.specialties}
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {attributes.map(attr => {
        if (attr.type === "fieldValue" && attr.display) {
          return (
            <div
              className={classes.attributeContainer}
              key={`detail-attr-${attr.title}`}
            >
              <div className={classes.attributeTitle}>{attr.title}</div>
              <div className={classes.attributeValue}>{attr.value}</div>
              {attr.description && attr.description.length > 0 && (
                <div className={classes.attributeDescription}>
                  {attr.description}
                </div>
              )}
            </div>
          );
        }
        return undefined;
      })}
    </div>
  );
};

export default ChatDetail;

import React, { useCallback, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { fetchPaymentsStatus } from "../../store/actions/fetchPaymentsStatus";
import facePhoto from "../../assets/images/jessica-lavender.png";
import Container from "../../components/Container";
import ErrorMessage from "../../components/ErrorMessage";
import EnablePaymentsForm from "../../components/payments/EnablePaymentsForm";
import Header from "../../components/payments/Header";
import Layout from "../../components/payments/Layout";
import paymentsService from "../../services/payments";
import { getPaymentsStatus } from "../../store/selectors/payments";
import { getUserFields, getUser_id } from "../../store/selectors/user";
import { GlobalStore } from "../../types/store";

type StateProps = ReturnType<typeof mapStateToProps>;

type RouteProps = RouteComponentProps<{ step: string }>;

type Props = RouteProps & StateProps;

const InvoicesSetUp: React.FC<Props> = ({
  paymentsEnabled,
  userFields,
  userId,
  history
}) => {
  const dispatch = useDispatch();
  const [error, setError] = useState("");

  const handleError = useCallback(
    (msg: string) => {
      setError(msg);
      window.scrollTo(0, 0);
    },
    [setError]
  );

  if (paymentsEnabled) return <Redirect to="/invoices" />;
  if (!userFields.filingType) return <Redirect to="/invoices/set-up" />;

  async function handleSubmit() {
    try {
      if (userId) {
        const response: any = await paymentsService.enable(userId);
        if (!response.enabled && response.missingProperties) {
          console.error("Failed to enable payments service");
          console.error(response);
          handleError(`${response.missingProperties[0]} is missing`);
        } else {
          await dispatch(fetchPaymentsStatus());
          history.push("/invoices");
        }
      }
    } catch (error) {
      console.error("Failed to enable payments service");
      console.error(error);
      if (error && error.response && error.response.data.error) {
        handleError(error.response.data.error);
      } else {
        handleError("Sorry, something went wrong");
      }
    }
  }

  return (
    <Layout title="Set up Payments">
      <Container width={980}>
        <Header
          title={
            userFields.filingType === "personal"
              ? "Great! We’ll guide you through individual setup."
              : "Great! We’ll guide you through company setup."
          }
          description="You’ll be sending invoices in no time."
          face={facePhoto}
          onBack={() => {
            history.push("/invoices/set-up");
          }}
        />

        {error && <ErrorMessage>{error}</ErrorMessage>}

        <EnablePaymentsForm onSubmit={handleSubmit} />
      </Container>
    </Layout>
  );
};

const mapStateToProps = (state: GlobalStore) => ({
  userId: getUser_id(state),
  userFields: getUserFields(state),
  paymentsEnabled: getPaymentsStatus(state)
});

export default connect(mapStateToProps)(InvoicesSetUp);

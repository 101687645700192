import { AppThunk } from "../../types/store";
import { createAction } from "../meta/types";
import {
  DELETE_INVOICE,
  DELETE_INVOICE_FAILURE,
  DELETE_INVOICE_REQUEST,
  DELETE_INVOICE_SUCCESS
} from "../constants";
import paymentsService from "../../services/payments";

export const deleteInvoiceActions = createAction(
  DELETE_INVOICE,
  DELETE_INVOICE_REQUEST,
  DELETE_INVOICE_SUCCESS,
  DELETE_INVOICE_FAILURE
)<{ invoiceId: string }, { invoiceId: string }, Error>();

export const deleteInvoice = (
  invoiceId: string
): AppThunk<any> => async dispatch => {
  dispatch(deleteInvoiceActions.request({ invoiceId }, invoiceId));

  try {
    await paymentsService.deleteInvoice(invoiceId);

    dispatch(deleteInvoiceActions.success({ invoiceId }, invoiceId));
  } catch (error) {
    dispatch(deleteInvoiceActions.failure(error, invoiceId));
  }
};

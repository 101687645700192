import React from "react";
import classes from "./styles.module.scss";
import Item from "./Item";

interface Component extends React.FC {
  Item: typeof Item;
}

const List: Component = ({ children }) => {
  return <div className={classes.container}>{children}</div>;
};

List.Item = Item;

export default List;

import { Asset } from "../services/api";

export interface WizardStepConfig {
  header: string;
  subHeader?: string;
  description?: string;
  blocks: WizardStepBlock[];
  ticketSubject?: string;
  ticketInfo?: string;
  ticketSubheader?: string;
  ticketCategoryId?: number;
  buttonText?: string;
}

export enum WizardStepBlockType {
  BOX = "box",
  FIELD = "field",
  SUBHEADER = "subHeader",
  TEXT = "text",
  FIELD_VALUE = "fieldValue",
  FILES_LIST = "filesList"
}

export type WizardStepBlock =
  | WizardStepBox
  | WizardStepField
  | WizardStepSubheader
  | WizardStepText
  | WizardStepFieldValue
  | WizardStepFilesList;

export interface WizardStepBox {
  type: WizardStepBlockType.BOX;
  blocks: WizardStepBlock[];
}

export interface WizardStepOption {
  label?: string;
  value: string | number;
}

export interface WizardStepField {
  type: WizardStepBlockType.FIELD;
  name: string;
  fieldType?:
    | "text"
    | "number"
    | "currency"
    | "email"
    | "phone"
    | "date"
    | "select"
    | "selectSlider"
    | "files"
    | "radio"
    | "checkbox";
  label?: string;
  mandatory?: boolean;
  options?: WizardStepOption[];
  values?: any;
}

export interface WizardStepSubheader {
  type: WizardStepBlockType.SUBHEADER;
  value: string;
}

export interface WizardStepText {
  type: WizardStepBlockType.TEXT;
  value: string;
}

export interface WizardStepFieldValue {
  type: WizardStepBlockType.FIELD_VALUE;
  title: string;
  value: string;
}

export interface WizardStepFilesList {
  type: WizardStepBlockType.FILES_LIST;
  title: string;
  value: string;
}

export interface WizardValues {
  [key: string]: any;
}

export interface WizardErrors {
  [key: string]: string;
}

export interface WizardOnChange {
  (name: string, value: any, formattedValue?: any): void;
}

export interface WizardFile extends Asset {}

import { Form, Formik } from "formik";
import React from "react";
import { connect, useDispatch } from "react-redux";
import * as Yup from "yup";
import { fetchUserUpdate } from "../../../store/actions/fetchUserUpdate";
import USstates from "../../../constants/USstates";
import { getUserFields } from "../../../store/selectors/user";
import { GlobalStore } from "../../../types/store";
import URL from "../../../types/url";
import { FormikScrollToError } from "../../FormikScrollToError";
import Modest from "../../onboarding/Modest";
import Button from "../Button";
import FormikDateField from "../DateField/Formik";
import FormikDropDownField from "../DropDownField/Formik";
import FormSection from "../FormSection";
import FormikTextAreaField from "../TextAreaField/Formik";
import FormikTextField from "../TextField/Formik";
import { getMCCs } from "../../../store/selectors/payments";

type OwnProps = {
  onSubmit?: () => void;
};

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StateProps;

const EnablePaymentsForm: React.FC<Props> = ({
  userFields,
  onSubmit,
  mccs
}) => {
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        fullName: `${userFields.firstName} ${userFields.lastName}`,
        dob: userFields.dob,
        email: userFields.email,
        ssnLast4: userFields.ssnLast4 || "",

        businessName: userFields.businessName || "",
        businessProfileMCC: userFields.businessProfileMCC || "",
        businessProfileURL: userFields.businessProfileURL || "",
        description: userFields.description || "",
        companyTaxId: userFields.companyTaxId || "",

        address: userFields.address || "",
        addressLine2: userFields.addressLine2 || "",
        city: userFields.city || "",
        state: userFields.state || "",
        zipCode: userFields.zipCode || "",
        phone: userFields.phone || "",

        accountRoutingNumber: userFields.accountRoutingNumber || "",
        accountNumber: userFields.accountNumber || "",
        accountHolderName: userFields.accountHolderName || ""
      }}
      validationSchema={Yup.object().shape({
        fullName: Yup.string().required("Full name is required"),
        dob: Yup.date().required("Birthday is required"),
        email: Yup.string()
          .email("Please enter a valid email address")
          .required("Work email is required"),
        ssnLast4: Yup.string()
          .length(4, "Should be 4 digits")
          .required("Last 4 digits of SSN is required"),

        businessName:
          userFields.filingType === "business"
            ? Yup.string().required("Business name is required")
            : Yup.string(),
        businessProfileMCC: Yup.string().required("Industry is required"),
        businessProfileURL: Yup.string(),
        description: Yup.string(),
        companyTaxId:
          userFields.filingType === "business"
            ? Yup.string()
                .length(9, "Should be 9 characters")
                .required("Tax ID or EIN is required")
            : Yup.string(),

        address: Yup.string().required("Address line 1 is required"),
        addressLine2: Yup.string(),
        city: Yup.string().required("City is required"),
        state: Yup.string().required("Required"),
        zipCode: Yup.string()
          .length(5, "Should be 5 characters")
          .required("Required"),
        phone: Yup.string()
          .length(10, "Phone number should be 10 characters")
          .required("Phone number is required"),

        accountRoutingNumber: Yup.string().required(
          "Routing number is required"
        ),
        accountNumber: Yup.string().required("Account number is required"),
        accountHolderName: Yup.string().required("Account holder is required")
      })}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const [firstName, lastName] = values.fullName.split(" ");

          await dispatch(
            fetchUserUpdate({
              firstName,
              lastName,
              email: values.email,
              dob: values.dob,
              ssnLast4: values.ssnLast4,

              businessName:
                userFields.filingType === "business"
                  ? values.businessName
                  : undefined,
              businessProfileMCC: values.businessProfileMCC,
              businessProfileURL: values.businessProfileURL,
              description: values.description,
              companyTaxId: values.companyTaxId,

              city: values.city,
              address: values.address,
              addressLine2: values.addressLine2,
              zipCode: values.zipCode,
              state: values.state,
              phone: values.phone,

              ...(userFields.filingType === "business"
                ? {
                    businessCity: values.city,
                    businessAddress: values.address,
                    businessAddressLine2: values.addressLine2,
                    businessZipCode: values.zipCode,
                    businessState: values.state,
                    businessPhone: values.phone
                  }
                : {}),

              accountHolderName: values.accountHolderName,
              accountNumber: values.accountNumber,
              accountRoutingNumber: values.accountRoutingNumber
            })
          );

          if (onSubmit) await onSubmit();
        } catch (error) {
          console.error("Failed updating user profile");
          console.error(error);
        }

        setSubmitting(false);
      }}
    >
      {props => {
        const { handleSubmit, isSubmitting } = props;
        return (
          <Form>
            <FormikScrollToError context={props} />
            <FormSection title="Personal details">
              <FormikTextField name="fullName" label="Full name" />

              <FormikDateField name="dob" label="Birthday" width={280} />

              <FormikTextField name="email" type="email" label="Work email" />

              <FormikTextField
                name="ssnLast4"
                label="Last 4 digits of SSN"
                maxLength={11}
                formatOptions={{
                  prefix: "XXX-XX-",
                  rawValueTrimPrefix: true
                }}
                inputWidth={180}
              />
            </FormSection>

            <FormSection title="Business details">
              {userFields.filingType === "business" && (
                <FormikTextField name="businessName" label="Business name" />
              )}

              <FormikDropDownField
                name="businessProfileMCC"
                label="Industry"
                options={mccs}
                searchable
                searchPlaceholder="Search industry"
              />

              <FormikTextField
                name="businessProfileURL"
                label="Website (optional)"
              />

              <FormikTextAreaField
                name="description"
                label="Description (optional)"
              />

              {userFields.filingType === "business" && (
                <FormikTextField
                  name="companyTaxId"
                  label="Tax ID or EIN"
                  formatOptions={{
                    numericOnly: true,
                    blocks: [2, 7],
                    delimiters: ["-"],
                    delimiterLazyShow: true
                  }}
                  width={136}
                />
              )}
            </FormSection>

            <FormSection title="Contact information">
              <FormikTextField
                name="address"
                label="Street address"
                placeholder="Address line 1"
                smallIndent
              />

              <FormikTextField
                name="addressLine2"
                placeholder="Address line 2 (optional)"
                smallIndent
              />

              <FormSection.Row>
                <FormikTextField name="city" placeholder="City" smallIndent />

                <FormikDropDownField
                  name="state"
                  options={USstates}
                  width={80}
                  smallIndent
                  placeholder="State"
                  searchable
                />

                <FormikTextField name="zipCode" width={80} placeholder="Zip" />
              </FormSection.Row>

              <br />

              <FormikTextField
                name="phone"
                label="Phone number"
                inputWidth={180}
                formatOptions={{
                  numericOnly: true,
                  blocks: [4, 3, 4],
                  delimiters: [") ", "-"],
                  delimiterLazyShow: true,
                  prefix: "(",
                  noImmediatePrefix: true,
                  rawValueTrimPrefix: true
                }}
              />
            </FormSection>

            <FormSection
              title="Bank details"
              description="We only use this information to ensure you get paid."
            >
              <FormikTextField
                name="accountRoutingNumber"
                label="Routing number"
                width={280}
              />

              <FormikTextField
                name="accountNumber"
                label="Account number"
                width={280}
              />

              <FormikTextField
                name="accountHolderName"
                label="Name of account holder"
              />
              <Button
                loading={isSubmitting}
                onClick={() => {
                  handleSubmit();
                }}
              >
                Submit & start invoicing
              </Button>
            </FormSection>

            <Modest align="left">
              Wingspan works with Stripe to collect payments. By registering to
              submit invoices, you agree to{" "}
              <a
                href={URL.termsOfService}
                rel="noopener noreferrer"
                target="_blank"
              >
                our terms of service
              </a>{" "}
              and the{" "}
              <a
                href={URL.stripeConnectedTerms}
                rel="noopener noreferrer"
                target="_blank"
              >
                Stripe Connected Account Agreement
              </a>
              .
            </Modest>

            {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: GlobalStore) => ({
  userFields: getUserFields(state),
  mccs: getMCCs(state)
});

export default connect(mapStateToProps)(EnablePaymentsForm);

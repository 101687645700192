import { AppThunk } from "../../types/store";
import { getInvoiceDataById } from "../selectors/invoices";
import { createAction } from "../meta/types";
import {
  DOWNLOAD_INVOICE_PDF,
  DOWNLOAD_INVOICE_PDF_FAILURE,
  DOWNLOAD_INVOICE_PDF_REQUEST,
  DOWNLOAD_INVOICE_PDF_SUCCESS
} from "../constants";
import { trackInvoiceAction } from "../../utils/analytics";

export const downloadInvoicePDFActions = createAction(
  DOWNLOAD_INVOICE_PDF,
  DOWNLOAD_INVOICE_PDF_REQUEST,
  DOWNLOAD_INVOICE_PDF_SUCCESS,
  DOWNLOAD_INVOICE_PDF_FAILURE
)<{ invoiceId: string }, { invoiceId: string }, Error>();

export const downloadInvoicePDF = (invoiceId: string): AppThunk<any> => async (
  dispatch,
  getState
) => {
  dispatch(downloadInvoicePDFActions.request({ invoiceId }, invoiceId));

  try {
    const invoice = getInvoiceDataById(getState(), invoiceId);
    if (invoice?.attachments.invoicePdf) {
      const link = document.createElement("a");
      link.style.opacity = "0";
      link.download = "invoice.pdf";
      link.href = invoice.attachments.invoicePdf;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    trackInvoiceAction("Download Invoice", invoiceId, invoice.status);

    dispatch(downloadInvoicePDFActions.success({ invoiceId }, invoiceId));
  } catch (error) {
    dispatch(downloadInvoicePDFActions.failure(error, invoiceId));
  }
};

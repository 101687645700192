function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from "react";
export default (({
  styles = {},
  ...props
}) => React.createElement("svg", _extends({
  "data-name": "Layer 1",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 30 30"
}, props), React.createElement("circle", {
  cx: "15",
  cy: "15",
  r: "10.25",
  fill: "#c3e1f5"
}), React.createElement("path", {
  d: "M15 5.5A9.5 9.5 0 115.5 15 9.51 9.51 0 0115 5.5M15 4a11 11 0 1011 11A11 11 0 0015 4z",
  fill: "#fff"
}), React.createElement("path", {
  d: "M15 26.5A11.5 11.5 0 1126.5 15 11.51 11.51 0 0115 26.5zm0-22A10.5 10.5 0 1025.5 15 10.51 10.51 0 0015 4.5z",
  fill: "#282d37"
}), React.createElement("path", {
  d: "M17 21.5h-4a.5.5 0 01-.5-.5v-3.5H9a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5h3.5V9a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v3.5H21a.5.5 0 01.5.5v4a.5.5 0 01-.5.5h-3.5V21a.5.5 0 01-.5.5zm-3.5-1h3V17a.5.5 0 01.5-.5h3.5v-3H17a.5.5 0 01-.5-.5V9.5h-3V13a.5.5 0 01-.5.5H9.5v3H13a.5.5 0 01.5.5z",
  fill: "#282d37"
}), React.createElement("path", {
  fill: "none",
  d: "M0 0h30v30H0z"
})));
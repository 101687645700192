import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import {
  applyMiddleware,
  combineReducers,
  createStore,
  Middleware,
  Store
} from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import { GlobalStore } from "../types/store";
import { goals } from "./reducers/goals";
import { membership } from "./reducers/membership";
import { meta } from "./meta/reducer";
import { payments } from "./reducers/payments";
import { user } from "./reducers/user";

const middleware: Middleware[] = [thunk];

export const history = createBrowserHistory();

export const rootReducer = combineReducers({
  user,
  meta,
  membership,
  goals,
  payments,
  router: connectRouter(history)
});

const fixTypeByAction = (action: any) => {
  let type = action.type;

  if (action.type.startsWith("@@router")) {
    const path = action.payload?.location?.pathname
      ? `:"${action.payload.location.pathname}"`
      : "";
    type = `${action.type}${path}`;
  }

  return type;
};

if (process.env.NODE_ENV !== "production") {
  const logger = createLogger({
    collapsed: true,
    diff: true,
    titleFormatter(formattedAction: any, formattedTime: string, took: number) {
      // const time = `${formattedTime}`;
      // const duration = `${took.toFixed(2)} ms`;
      // const other = `\n[${time} | ${duration}]`;
      // return `${fixTypeByAction(formattedAction)} ${other}`;

      return fixTypeByAction(formattedAction);
    }
  });

  middleware.push(logger);
}

const composeEnhancers = composeWithDevTools({
  actionSanitizer(action: any) {
    return {
      ...action,
      type: fixTypeByAction(action)
    };
  }
});

export const store: Store<GlobalStore> = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middleware, routerMiddleware(history)))
);

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from "react";
export default (({
  styles = {},
  ...props
}) => React.createElement("svg", _extends({
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  xmlns: "http://www.w3.org/2000/svg",
  xmlnsXlink: "http://www.w3.org/1999/xlink"
}, props), React.createElement("defs", null, React.createElement("path", {
  d: "M18 0c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4l-4 4V2C0 .9.9 0 2 0zm0 2H2v14l2-2h14V2zm-5.115 2.705a1 1 0 011.41 1.42l-4.59 4.59a1 1 0 01-1.412 0L6.709 9.136a.995.995 0 01.001-1.411 1.002 1.002 0 011.415-.005L9 8.59l3.882-3.882z",
  id: "a"
})), React.createElement("g", {
  fill: "none",
  fillRule: "evenodd"
}, React.createElement("path", {
  d: "M0 0h24v24H0z"
}), React.createElement("g", {
  transform: "translate(2 2)"
}, React.createElement("mask", {
  id: "b",
  fill: "#fff"
}, React.createElement("use", {
  xlinkHref: "#a"
})), React.createElement("use", {
  fill: "#6B727F",
  fillRule: "nonzero",
  xlinkHref: "#a"
}), React.createElement("g", {
  mask: "url(#b)"
}, React.createElement("path", {
  fill: "#FFF",
  d: "M-2-2h24v24H-2z"
}), React.createElement("path", {
  fill: "#6B727F",
  d: "M-2-2h24v24H-2z"
}))))));
import React from "react";
import { useMediaQuery } from "react-responsive";
import { Redirect, RouteComponentProps } from "react-router-dom";
import Button from "../components/Button";
import EmptyInstructions from "../components/EmptyInstructions";
import Fetch from "../components/Fetch";
import Goal from "../components/Goal";
import MainLayout from "../components/MainLayout";
import { getTickets } from "../services/api";
import styles from "./Goals.module.scss";

const Goals: React.FC<RouteComponentProps<null>> = ({ history }) => {
  const isTabletOrDesktop = useMediaQuery({
    query: "(min-width: 768px)"
  });

  const createNewGoal = (
    <Button
      onClick={() => {
        history.push(`/create-goal`);
      }}
    >
      Create New Goal
    </Button>
  );

  const activeEmpty = (
    <EmptyInstructions
      header="Let's Get Started"
      subHeader="You Have No Active Goals"
      text="Submit a Goal to your team. We'll work tirelessly on any of your benefits, payments, legal, or administrative needs."
    >
      {createNewGoal}
    </EmptyInstructions>
  );

  const completeEmpty = (
    <EmptyInstructions
      header="Nothing yet"
      subHeader="You Have No Completed Goals"
      text="It's easy to find past interactions with your team here. Create a Goal to get started!"
    >
      {createNewGoal}
    </EmptyInstructions>
  );

  return (
    <MainLayout title="Goals" currentPage="goals">
      <MainLayout.PageHeader backgroundColor="#F3F5F9">
        <h1 className="hero">Your Goals</h1>
      </MainLayout.PageHeader>
      <MainLayout.Main noMargin>
        <Fetch request={getTickets}>
          {({ data }) =>
            isTabletOrDesktop ? (
              data.active.length > 0 || data.completed.length > 0 ? (
                <Redirect
                  to={`/goals/f/${
                    data.active.length > 0
                      ? data.active[0].freshdeskTicketId
                      : data.completed[0].freshdeskTicketId
                  }`}
                />
              ) : (
                <MainLayout.Tabs>
                  <MainLayout.Tabs.Tab name="Active (0)">
                    {activeEmpty}
                  </MainLayout.Tabs.Tab>
                  <MainLayout.Tabs.Tab name="Completed (0)">
                    {completeEmpty}
                  </MainLayout.Tabs.Tab>
                </MainLayout.Tabs>
              )
            ) : (
              <MainLayout.Tabs>
                <MainLayout.Tabs.Tab name={`Active (${data.active.length})`}>
                  {data.active.length ? (
                    <div>
                      {data.active.map((ticket: any, index: number) => (
                        <Goal
                          category={ticket.category}
                          subject={ticket.subject}
                          unreadCount={ticket.unreadCount || 0}
                          lastMessageName={ticket.agentName}
                          lastMessageText={ticket.description}
                          lastMessagePhoto={ticket.agentPhoto}
                          key={index}
                          onClick={() => {
                            history.push(
                              `/goals/f/${ticket.freshdeskTicketId}`
                            );
                          }}
                        />
                      ))}
                      <div className={styles.fixedFooter}>{createNewGoal}</div>
                    </div>
                  ) : (
                    <div>{activeEmpty}</div>
                  )}
                </MainLayout.Tabs.Tab>
                <MainLayout.Tabs.Tab
                  name={`Completed (${data.completed.length})`}
                >
                  {data.completed.length ? (
                    <div>
                      {data.completed.map((ticket: any, index: number) => (
                        <Goal
                          category={ticket.category}
                          subject={ticket.subject}
                          unreadCount={ticket.unreadCount || 0}
                          lastMessageName={ticket.agentName}
                          lastMessageText={ticket.description}
                          lastMessagePhoto={ticket.agentPhoto}
                          completed
                          completedDate={
                            ticket.completed && new Date(ticket.completed)
                          }
                          key={index}
                          onClick={() => {
                            history.push(
                              `/goals/f/${ticket.freshdeskTicketId}`
                            );
                          }}
                        />
                      ))}
                      <div className={styles.fixedFooter}>{createNewGoal}</div>
                    </div>
                  ) : (
                    <div>{completeEmpty}</div>
                  )}
                </MainLayout.Tabs.Tab>
              </MainLayout.Tabs>
            )
          }
        </Fetch>
      </MainLayout.Main>
    </MainLayout>
  );
};

export default Goals;

import React from "react";
import { WizardValues } from "../../types/wizardStep";
import styles from "./styles.module.scss";

interface Props {
  value: string;
  formattedValues: WizardValues;
}

const Text: React.FC<Props> = ({ value, formattedValues }) => {
  const regexp = /\$\$[a-zA-Z]*/;

  while (value.search(regexp) >= 0) {
    const match = value.match(regexp);
    if (match) {
      const searchValue = match[0];
      const name = searchValue.slice(2);
      value = value.replace(
        searchValue,
        formattedValues[name] ? `<b>${formattedValues[name]}</b>` : ""
      );
    }
  }

  return (
    <p
      className={styles.wizardText}
      dangerouslySetInnerHTML={{ __html: value }}
    />
  );
};

export default Text;

import { Reducer } from "redux";
import { MetaAction, MetaState, MetaStore } from "./types";
import {
  META_FAILURE_STATUS,
  META_REQUEST_STATUS,
  META_SUCCESS_STATUS
} from "./constants";
import { fixKey } from "./utils";

export const meta: Reducer<MetaStore, MetaAction> = (state = {}, action) => {
  const { actionName, payload, status } = action;
  const key = fixKey(action.key);

  let metaState: MetaState = state[actionName]?.[key] || {
    status: null,
    try: 0
  };

  if (status === META_REQUEST_STATUS) {
    metaState = {
      status: META_REQUEST_STATUS,
      try: metaState.try + 1,
      error: undefined
    };
  } else if (status === META_SUCCESS_STATUS) {
    metaState = {
      ...metaState,
      status: META_SUCCESS_STATUS,
      error: undefined
    };
  } else if (status === META_FAILURE_STATUS) {
    metaState = {
      ...metaState,
      status: META_FAILURE_STATUS,
      error: payload
    };
  } else {
    return state;
  }

  return {
    ...state,
    [actionName]: {
      ...(state[actionName] ? state[actionName] : {}),
      [key]: metaState
    }
  };
};

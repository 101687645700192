import React from "react";
import { getAuthToken } from "../../utils/auth";
import iconFile from "../../assets/icons/file_24.svg";
import classes from "./AssetLink.module.scss";

interface Props {
  fileName: string;
  url: string;
}

const AssetLink: React.FC<Props> = ({ fileName, url }) => {
  return (
    <div
      className={classes.container}
      onClick={e => {
        window.open(`${url}?auth=${getAuthToken()}`, "_blank");
      }}
      title="Click to download"
    >
      <img src={iconFile} alt="file" className={classes.iconFile} />
      <div className={classes.name}>{fileName}</div>
    </div>
  );
};

export default AssetLink;

import classNames from "classnames";
import React from "react";
import FieldContainer, { FieldContainerProps } from "../FieldContainer";
import classes from "./styles.module.scss";

export interface SwitchCheckboxFieldProps extends FieldContainerProps {
  value: boolean;
  label: string;
  onChange: (value: boolean) => void;
}

const SwitchCheckboxField: React.FC<SwitchCheckboxFieldProps> = ({
  label,
  error,
  smallIndent,
  required,
  width,
  value,
  onChange
}) => (
  <FieldContainer
    required={required}
    error={error}
    smallIndent={smallIndent}
    width={width}
  >
    <div
      className={classNames(classes.control, { [classes.checked]: value })}
      onClick={() => {
        onChange(!value);
      }}
    >
      <div className={classes.box} />
      <div className={classes.label}>{label}</div>
    </div>
  </FieldContainer>
);

export default SwitchCheckboxField;

import React from "react";
import Container from "../../components/Container";
import { InvoicesListDesktopContainer } from "../../components/payments/InvoicesListDesktop";
import { InvoicesListHeaderContainer } from "../../components/payments/InvoicesListHeader";
import { InvoicesListMobileContainer } from "../../components/payments/InvoicesListMobile";
import Layout from "../../components/payments/Layout";
import Section from "../../components/payments/Section";
import PrivateRoute from "../../components/PrivateRoute";
import { Mobile, TabletDesktop } from "../../components/Responsive";
import { InvoiceDetailsContainer } from "./Details";

export const InvoicesList: React.FC = () => (
  <>
    <PrivateRoute
      path="/invoices/:invoiceId"
      component={InvoiceDetailsContainer}
      exact
    />
    <Layout title="Invoices" pageHeader={<InvoicesListHeaderContainer />}>
      <Container width={1300}>
        <Section title="Activity">
          <Mobile>
            <InvoicesListMobileContainer />
          </Mobile>
          <TabletDesktop>
            <InvoicesListDesktopContainer />
          </TabletDesktop>
        </Section>
      </Container>
    </Layout>
  </>
);

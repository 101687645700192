import React from "react";
import classes from "./styles.module.scss";
import classNames from "classnames";

interface Props {
  secondary?: boolean;
  onClick?: () => any;
  disabled?: boolean;
  loading?: boolean;
  small?: boolean;
  noBorder?: boolean;
  fullWidthOnMobile?: boolean;
}

const Button: React.FC<Props> = ({
  secondary,
  onClick,
  children,
  disabled,
  loading,
  small,
  noBorder,
  fullWidthOnMobile
}) => (
  <button
    disabled={disabled || loading}
    className={classNames(secondary ? classes.secondary : classes.primary, {
      [classes.disabled]: disabled,
      [classes.withLoader]: loading,
      [classes.small]: small,
      [classes.noBorder]: noBorder,
      [classes.fullWidthOnMobile]: fullWidthOnMobile
    })}
    onClick={() => {
      if (onClick) onClick();
    }}
  >
    {children}
  </button>
);

export default Button;

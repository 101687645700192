import { Reducer } from "redux";
import { InferValueTypes } from "../../types/utils";
import {
  fetchUserActions,
  fetchUserUpdateActions,
  signInActions,
  signOutActions,
  signUpActions
} from "../actions/userActions";
import { getAuthToken } from "../../utils/auth";
import { UserStore } from "../../types/store";
import {
  FETCH_USER_FAILURE,
  FETCH_USER_SUCCESS,
  FETCH_USER_UPDATE_SUCCESS,
  SIGN_OUT_SUCCESS
} from "../constants";

export type InvoicesActions =
  | ReturnType<InferValueTypes<typeof fetchUserActions>>
  | ReturnType<InferValueTypes<typeof fetchUserUpdateActions>>
  | ReturnType<InferValueTypes<typeof signUpActions>>
  | ReturnType<InferValueTypes<typeof signInActions>>
  | ReturnType<InferValueTypes<typeof signOutActions>>;

export const getDefaultUserStore: () => UserStore = () => ({
  authorized: !!getAuthToken(),
  data: {} as any
});

export const user: Reducer<UserStore, InvoicesActions> = (
  state = getDefaultUserStore(),
  action
) => {
  switch (action.type) {
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        authorized: true,
        data: {
          id: action.payload.id,
          _id: action.payload._id,
          roleId: action.payload.roleId,
          goalsSubmitted: action.payload.goalsSubmitted,
          fields: action.payload.fields
        }
      };

    case FETCH_USER_FAILURE:
    case SIGN_OUT_SUCCESS:
      return getDefaultUserStore();

    case FETCH_USER_UPDATE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          fields: action.payload.data
        }
      };

    default: {
      return state;
    }
  }
};

import { Form, Formik } from "formik";
import queryString from "query-string";
import React from "react";
import { connect, useDispatch } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";
import * as Yup from "yup";
import { signUp } from "../store/actions/signUp";
import Button from "../components/Button";
import Card from "../components/Card";
import ErrorMessage from "../components/ErrorMessage";
import MainLayout from "../components/MainLayout";
import TextField from "../components/TextField";
import { getMetaIsLoading } from "../store/meta/selectors";
import { GlobalStore } from "../types/store";
import URL from "../types/url";
import { SIGN_UP } from "../store/constants";

type StateProps = ReturnType<typeof mapStateToProps>;

type RouteProps = RouteComponentProps;

type Props = RouteProps & StateProps;

const Register: React.FC<Props> = ({ loading }) => {
  const dispatch = useDispatch();

  const queries = queryString.parse(window.location.search);
  const title = queries.type === "whitelist" ? "Get Started" : "Sign Up";

  return (
    <MainLayout title={title} currentPage="register">
      <MainLayout.PageHeader
        header={title}
        subHeader="Enter your account details and let's get to work."
        backgroundColor="#FFDCBE"
      />
      <MainLayout.Main>
        <Formik
          initialValues={{
            email: queries.email ? queries.email.toString() : "",
            password: "",
            firstName: "",
            lastName: ""
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Please enter a valid email address")
              .required("Email is required"),
            password: Yup.string().required("Password is required"),
            firstName: Yup.string().required("First name is required"),
            lastName: Yup.string().required("Last name is required")
          })}
          onSubmit={async (values, { setSubmitting }) => {
            dispatch(signUp(values));
            setSubmitting(false);
          }}
        >
          {({ handleSubmit, values, handleChange, touched, errors }) => (
            <Form>
              <Card>
                <Card.Main>
                  <ErrorMessage actionTypePrefix={SIGN_UP} />

                  <TextField
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    disabled={queries.email ? true : false}
                    label="Email Address"
                    required
                    error={
                      touched.email && errors.email ? errors.email : undefined
                    }
                  />

                  <TextField
                    type="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    label="Password"
                    required
                    error={
                      touched.password && errors.password
                        ? errors.password
                        : undefined
                    }
                  />

                  <TextField
                    name="firstName"
                    value={values.firstName}
                    onChange={handleChange}
                    label="First Name"
                    required
                    error={
                      touched.firstName && errors.firstName
                        ? errors.firstName
                        : undefined
                    }
                  />

                  <TextField
                    name="lastName"
                    value={values.lastName}
                    onChange={handleChange}
                    label="Last Name"
                    required
                    error={
                      touched.lastName && errors.lastName
                        ? errors.lastName
                        : undefined
                    }
                  />
                </Card.Main>
              </Card>
              <p className="modest" style={{ textAlign: "center" }}>
                By proceeding you agree to Wingspan's{" "}
                <a
                  href={URL.termsOfService}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Use
                </a>{" "}
                &amp;{" "}
                <a
                  href={URL.privacyPolicy}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
                .
              </p>
              <MainLayout.Actions>
                <Button
                  loading={loading}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Create Account
                </Button>
                <p className="secondaryAction">
                  Already Have an Account? <Link to="/sign-in">Sign In</Link>
                </p>
              </MainLayout.Actions>
            </Form>
          )}
        </Formik>
      </MainLayout.Main>
    </MainLayout>
  );
};

const mapStateToProps = (state: GlobalStore) => ({
  loading: getMetaIsLoading(state, SIGN_UP)
});

export default connect(mapStateToProps)(Register);

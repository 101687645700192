import { Form, Formik } from "formik";
import React from "react";
import { connect, useDispatch } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router-dom";
import * as Yup from "yup";
import { fetchUserUpdate } from "../../store/actions/fetchUserUpdate";
import facePhoto from "../../assets/images/jessica-lavender.png";
import Container from "../../components/Container";
import Button from "../../components/payments/Button";
import Header from "../../components/payments/Header";
import Layout from "../../components/payments/Layout";
import FormikRadioFeild from "../../components/payments/RadioField/Formik";
import { getPaymentsStatus } from "../../store/selectors/payments";
import { getUserFields } from "../../store/selectors/user";
import { GlobalStore } from "../../types/store";

type StateProps = ReturnType<typeof mapStateToProps>;

type RouteProps = RouteComponentProps;

type Props = RouteProps & StateProps;

const InvoicesSetUp: React.FC<Props> = ({
  paymentsEnabled,
  userFields,
  history
}) => {
  const dispatch = useDispatch();

  if (paymentsEnabled) return <Redirect to="/invoices" />;

  return (
    <Layout title="Set up Payments">
      <Container width={780}>
        <Header
          title="You’re so close to sending your first invoice!"
          description="Before we can help you get paid, we need a bit more information."
          face={facePhoto}
        />

        <Formik
          initialValues={{
            fillingType: userFields.filingType || "personal"
          }}
          validationSchema={Yup.object().shape({
            fillingType: Yup.string().oneOf(["personal", "business"])
          })}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              await dispatch(
                fetchUserUpdate({ filingType: values.fillingType })
              );
              history.push("/invoices/set-up/2");
            } catch (error) {
              console.error("Failed updating filling type");
              console.error(error);
            }
            setSubmitting(false);
          }}
        >
          {props => {
            const { handleSubmit, isSubmitting } = props;
            return (
              <Form>
                <p>What type of account are you setting up?</p>
                <FormikRadioFeild
                  name="fillingType"
                  options={[
                    {
                      value: "personal",
                      label: "Individual or independent LLC"
                    },
                    {
                      value: "business",
                      label: "Company"
                    }
                  ]}
                />

                <Button loading={isSubmitting} onClick={handleSubmit}>
                  Start account setup
                </Button>

                {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
              </Form>
            );
          }}
        </Formik>
      </Container>
    </Layout>
  );
};

const mapStateToProps = (state: GlobalStore) => ({
  userFields: getUserFields(state),
  paymentsEnabled: getPaymentsStatus(state)
});

export default connect(mapStateToProps)(InvoicesSetUp);

import moment from "moment";
import React, { useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { EmptyLayout } from "../../components/EmptyLayout";
import {
  InvoiceFormContainer,
  InvoiceFormValues
} from "../../components/payments/InvoiceForm";
import { InvoicePreviewContainer } from "../../components/payments/InvoicePreview";
import { createAndSendInvoice } from "../../store/actions/createAndSendInvoice";
import { createInvoice } from "../../store/actions/createInvoice";
import { fetchInvoiceList } from "../../store/actions/fetchInvoiceList";
import { fetchMemberClientList } from "../../store/actions/fetchMemberClientList";
import { openInvoicePreview } from "../../store/actions/openInvoicePreview";
import { getPaymentsStatus } from "../../store/selectors/payments";
import { Currency, IInvoiceStatus } from "../../types/paymentsService";
import { GlobalStore } from "../../types/store";
import { generateLateFeeConfig } from "../../utils/invoiceHelper";
import { createOrUpdateMemberClient } from "../../utils/memberClient";
import { trackInvoiceAction } from "../../utils/analytics";

type StateProps = ReturnType<typeof mapStateToProps>;

type RouteProps = RouteComponentProps;

type Props = StateProps & RouteProps;

const Create: React.FC<Props> = ({ paymentsEnabled, history }) => {
  const dispatch = useDispatch();

  const handleSave = useCallback(
    async (values: InvoiceFormValues) => {
      try {
        const memberClientId = await createOrUpdateMemberClient(
          values.memberClientId || "",
          values.company,
          values.name,
          values.email,
          values.additionalEmails
        );

        await dispatch(
          createInvoice({
            memberClientId,
            lineItems: values.lineItems.map(lineItem => ({
              description: lineItem.title,
              totalCost: lineItem.amount ? Number(lineItem.amount) : 0
            })),
            currency: Currency.USD,
            status: IInvoiceStatus.Draft,
            dueDate:
              values.dueDateType === "on-receipt"
                ? new Date()
                : values.dueDateType === "days-after-receipt"
                ? moment()
                    .add(values.dueDateAfter, "days")
                    .toDate()
                : values.dueDate,
            invoiceNotes: values.notes,
            labels: {
              projectName: values.projectName
            },
            creditFeeHandling: {
              memberPays: values.clientPaysProcessingFee ? 0 : 100,
              clientPays: values.clientPaysProcessingFee ? 100 : 0
            },
            lateFeeHandling: generateLateFeeConfig({
              lateFee: values.lateFee,
              lateFeeAmount: values.lateFeeAmount,
              lateFeeAfter: values.lateFeeAfter
            })
          })
        );
        await Promise.all([
          dispatch(fetchInvoiceList()),
          dispatch(fetchMemberClientList())
        ]);

        history.push("/invoices");
      } catch (error) {
        console.error("Failed to create invoice");
        console.error(error);
      }
    },
    [dispatch, history]
  );

  const handleSend = useCallback(
    async (values: InvoiceFormValues) => {
      try {
        const memberClientId = await createOrUpdateMemberClient(
          values.memberClientId || "",
          values.company,
          values.name,
          values.email,
          values.additionalEmails
        );

        await dispatch(
          createAndSendInvoice({
            memberClientId,
            lineItems: values.lineItems.map(lineItem => ({
              description: lineItem.title,
              totalCost: lineItem.amount ? Number(lineItem.amount) : 0
            })),
            currency: Currency.USD,
            status: IInvoiceStatus.Draft,
            dueDate:
              values.dueDateType === "on-receipt"
                ? new Date()
                : values.dueDateType === "days-after-receipt"
                ? moment()
                    .add(values.dueDateAfter, "days")
                    .toDate()
                : values.dueDate,
            invoiceNotes: values.notes,
            labels: {
              projectName: values.projectName
            },
            creditFeeHandling: {
              memberPays: values.clientPaysProcessingFee ? 0 : 100,
              clientPays: values.clientPaysProcessingFee ? 100 : 0
            },
            lateFeeHandling: generateLateFeeConfig({
              lateFee: values.lateFee,
              lateFeeAmount: values.lateFeeAmount,
              lateFeeAfter: values.lateFeeAfter
            })
          })
        );

        await Promise.all([
          dispatch(fetchInvoiceList()),
          dispatch(fetchMemberClientList())
        ]);

        history.push("/invoices");
      } catch (error) {
        console.error("Failed to create invoice");
        console.error(error);
      }
    },
    [dispatch, history]
  );

  if (!paymentsEnabled) {
    return <Redirect to="/invoices/set-up" />;
  }

  return (
    <EmptyLayout title="Create invoice">
      <InvoiceFormContainer
        initialValues={{
          memberClientId: "",
          company: "",
          name: "",
          email: "",
          additionalEmails: [],
          lineItems: [{ title: "", amount: "" }],
          clientPaysProcessingFee: true,
          dueDateType: "on-receipt",
          dueDateAfter: 30,
          dueDate: new Date(),
          notes: "",
          projectName: "",
          lateFee: "percent",
          lateFeeAmount: "0",
          lateFeeAfter: "7"
        }}
        title="Create invoice"
        onSave={handleSave}
        onSend={handleSend}
        onPreview={values => {
          dispatch(openInvoicePreview(values));
          trackInvoiceAction("Preview", null, null, {
            location: "Create"
          });
        }}
      />
      <InvoicePreviewContainer />
    </EmptyLayout>
  );
};

const mapStateToProps = (state: GlobalStore) => ({
  paymentsEnabled: getPaymentsStatus(state)
});

export default connect(mapStateToProps)(Create);

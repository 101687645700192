import { formatMoney } from "accounting";
import moment from "moment";
import React from "react";
import classes from "./styles.module.scss";

interface Props {
  paidAt?: Date;
  last4: string;
  amount: number;
}

const SubscriptionInvoicePreview: React.FC<Props> = ({
  paidAt,
  last4,
  amount
}) => (
  <div className={classes.container}>
    <div className={classes.label}>
      {paidAt ? moment(paidAt).format("MM/DD/YY") : "Pending"}
    </div>
    <div className={classes.main}>
      <div className={classes.cardNumber}>**** **** **** {last4}</div>
      <div className={classes.amount}>{formatMoney(amount)}</div>
    </div>
  </div>
);

export default SubscriptionInvoicePreview;

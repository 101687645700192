import { Reducer } from "redux";
import { InferValueTypes } from "../../types/utils";
import { fetchGoalCreationConfigActions } from "../actions/fetchGoalCreationConfigActions";
import { FETCH_GOAL_CREATION_CONFIG_SUCCESS } from "../constants";
import { GoalsStore } from "../../types/store";

export type InvoicesActions = ReturnType<
  InferValueTypes<typeof fetchGoalCreationConfigActions>
>;

export const getDetaulsGoalsStore: () => GoalsStore = () => ({
  config: {
    header: "Welcome",
    sections: []
  }
});

export const goals: Reducer<GoalsStore, InvoicesActions> = (
  state = getDetaulsGoalsStore(),
  action
) => {
  switch (action.type) {
    case FETCH_GOAL_CREATION_CONFIG_SUCCESS:
      return {
        ...state,
        config: action.payload.config
      };

    default: {
      return state;
    }
  }
};

import React from "react";
import classnames from "classnames";
import classes from "./styles.module.scss";

interface Props {
  label: string;
  valueBold?: boolean;
}

const FieldValue: React.FC<Props> = ({ label, valueBold, children }) => (
  <div className={classes.container}>
    <div className={classes.label}>{label}</div>
    <div
      className={classnames(classes.value, {
        [classes.valueBold]: valueBold,
        [classes.valueMissing]: !children
      })}
    >
      {children ? children : `Not specified`}
    </div>
  </div>
);

export default FieldValue;

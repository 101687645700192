import * as apiService from "../../services/api";
import { AppThunk } from "../../types/store";
import { fetchGoalCreationConfigActions } from "./fetchGoalCreationConfigActions";

export const fetchGoalCreationConfig = (): AppThunk<any> => async dispatch => {
  dispatch(fetchGoalCreationConfigActions.request());

  try {
    const config = await apiService.getGoalCreationConfig();

    dispatch(
      fetchGoalCreationConfigActions.success({
        config
      })
    );
  } catch (error) {
    dispatch(fetchGoalCreationConfigActions.failure(error));
  }
};

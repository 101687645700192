import React from "react";
import classes from "./styles.module.scss";
import classNames from "classnames";

interface Props {
  title: string;
  description?: string;
  face?: string;
  centered?: boolean;
  onBack?: () => void;
}

const Header: React.FC<Props> = ({
  title,
  description,
  face,
  centered,
  onBack
}) => (
  <div
    className={classNames(classes.container, { [classes.centered]: centered })}
  >
    {onBack && (
      <button
        className={classes.backButton}
        onClick={() => {
          onBack();
        }}
      >
        Back
      </button>
    )}
    {face && (
      <div className={classes.face}>
        <img src={face} alt="" />
      </div>
    )}
    <h1>{title}</h1>
    {description && <p>{description}</p>}
  </div>
);

export default Header;

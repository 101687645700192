import * as apiService from "../../services/api";
import { signOutActions } from "./userActions";
import { AppThunk } from "../../types/store";
import { track } from "../../utils/analytics";
import { unsetAuthToken } from "../../utils/auth";

export const signOut = (): AppThunk<any> => async dispatch => {
  dispatch(signOutActions.request());

  try {
    await apiService.signOut();
    unsetAuthToken();
    track("Auth", { action: "Log Out" });

    dispatch(signOutActions.success());
  } catch (error) {
    dispatch(signOutActions.failure(error));
  }
};

import { updateProfile, UpdateProfileData } from "../../services/api";
import { fetchUserUpdateActions } from "./userActions";
import { AppThunk } from "../../types/store";

export const fetchUserUpdate = (
  data: UpdateProfileData
): AppThunk<any> => async dispatch => {
  dispatch(fetchUserUpdateActions.request());

  try {
    const { roleId, ...fields } = await updateProfile(data);

    dispatch(
      fetchUserUpdateActions.success({
        data: fields
      })
    );
  } catch (error) {
    dispatch(fetchUserUpdateActions.failure(error));
  }
};

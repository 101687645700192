import React from "react";
import styles from "./styles.module.scss";
import ProfilePhoto from "../ProfilePhoto";

interface Props {
  name: string;
  categoryId: number;
  specialties: string;
  avatarUrl?: string;
}

const AgentListItem: React.FC<Props> = ({
  name,
  categoryId,
  specialties,
  avatarUrl
}) => (
  <li className={styles.container}>
    <ProfilePhoto
      url={avatarUrl}
      name={name}
      className={styles.profilePhotoSmall}
    />
    <div>
      <h3>{name}</h3>
      <span>{specialties}</span>
    </div>
  </li>
);

export default AgentListItem;

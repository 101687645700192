import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import classes from "./styles.module.scss";

interface Props extends RouteComponentProps {
  to?: string;
  onClick?: () => void;
}

const Item: React.FC<Props> = ({ to, onClick, history, children }) => (
  <div
    className={classes.item}
    onClick={() => {
      if (to) {
        history.push(to);
      } else if (onClick) {
        onClick();
      }
    }}
  >
    {children}
  </div>
);

export default withRouter(Item);

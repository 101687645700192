import { formatMoney } from "accounting";
import moment from "moment";
import React, { useCallback } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getInvoiceDataById } from "../../../store/selectors/invoices";
import { getMemberClientDataById } from "../../../store/selectors/memberClients";
import { GlobalStore } from "../../../types/store";
import { getInvoiceTotalAmount } from "../../../utils/invoiceHelper";
import { InvoiceActionsDropdownContainer } from "../InvoiceActionsDropdown";
import InvoiceStatus from "../InvoiceStatus";

interface OwnProps extends RouteComponentProps {
  invoiceId: string;
}
type MapStateToProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & MapStateToProps;

const InvoicesListDesktopItem: React.FC<Props> = props => {
  const onView = useCallback(() => {
    props.history.push(`/invoices/${props.invoiceId}`);
  }, [props.history, props.invoiceId]);

  return props.invoiceData && props.memberClientData ? (
    <tr>
      <td onClick={onView}>
        <InvoiceStatus status={props.invoiceData.status} />
      </td>
      <td onClick={onView}>
        {moment.utc(props.invoiceData.dueDate).format("MMM D, YYYY")}
      </td>
      <td onClick={onView}>{props.memberClientData.company}</td>
      <td onClick={onView}>{props.memberClientData.name}</td>
      <td onClick={onView}>One-time</td>
      <td onClick={onView} align="right">
        {formatMoney(getInvoiceTotalAmount(props.invoiceData))}
      </td>
      <td style={{ width: "58px" }}>
        <InvoiceActionsDropdownContainer invoiceId={props.invoiceId} />
      </td>
    </tr>
  ) : null;
};

const mapStateToProps = (state: GlobalStore, props: OwnProps) => {
  const invoiceData = getInvoiceDataById(state, props.invoiceId);
  let memberClientData;
  if (invoiceData?.memberClientId)
    memberClientData = getMemberClientDataById(
      state,
      invoiceData?.memberClientId
    );

  return {
    invoiceData,
    memberClientData
  };
};

export const InvoicesListDesktopItemContainer = withRouter(
  connect(mapStateToProps)(InvoicesListDesktopItem)
);

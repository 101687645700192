import { formatMoney } from "accounting";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getInvoicesListStats } from "../../../store/selectors/invoices";
import { GlobalStore } from "../../../types/store";
import Container from "../../Container";
import { Mobile } from "../../Responsive";
import Button from "../Button";
import classes from "./styles.module.scss";
import { track } from "../../../utils/analytics";

type MapStateToProps = ReturnType<typeof mapStateToProps>;
type Props = RouteComponentProps & MapStateToProps;

const InvoicesListHeader: React.FC<Props> = props => (
  <Container width={1300}>
    <div className={classes.container}>
      <Mobile>
        <h1>Invoices</h1>
      </Mobile>

      <div className={classes.stats}>
        <div className={classes.item}>
          <div className={classes.label}>
            <span>Paid</span> (Last 30 days)
          </div>
          <div className={classes.value}>{formatMoney(props.stats.paid)}</div>
        </div>
        <div className={classes.item}>
          <div className={classes.label}>
            <span>Outstanding</span> (Last 30 days)
          </div>
          <div className={classes.value}>
            {formatMoney(props.stats.outstanding)}
          </div>
        </div>
      </div>
      <Button
        onClick={() => {
          track("Invoice", {
            action: "Click Create Invoice"
          });
          props.history.push("/invoices/create");
        }}
      >
        Create Invoice
      </Button>
    </div>
  </Container>
);

const mapStateToProps = (state: GlobalStore) => ({
  stats: getInvoicesListStats(state)
});

export const InvoicesListHeaderContainer = withRouter(
  connect(mapStateToProps)(InvoicesListHeader)
);

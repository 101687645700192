import { formatMoney } from "accounting";
import moment from "moment";
import React from "react";
import { connect, useDispatch } from "react-redux";
import wingspanLogo from "../../../assets/images/logo-icon-top.svg";
import { closeInvoicePreview } from "../../../store/actions/closeInvoicePreview";
import { getInvoicePreview } from "../../../store/selectors/invoices";
import {
  getUserBusinessAddress,
  getUserCompanyName,
  getUserEmail
} from "../../../store/selectors/user";
import { GlobalStore } from "../../../types/store";
import { lateFeeAfterOptions } from "../../../utils/invoiceHelper";
import { Sidebar } from "../../Sidebar";
import Button from "../Button";
import { InvoiceDetailsInfoSection } from "../InvoiceDetailsInfoSection";
import classes from "./styles.module.scss";

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = StateProps;

const InvoicePreview: React.FC<Props> = props => {
  const dispatch = useDispatch();

  if (props.preview.visible) {
    const { data } = props.preview;

    let lateFee;

    if (data.lateFee !== "none") {
      lateFee = `Additional ${
        data.lateFee === "fixed"
          ? formatMoney(data.lateFeeAmount)
          : `${data.lateFeeAmount}% of invoice total`
      } after ${lateFeeAfterOptions
        .find(option => option.value === data.lateFeeAfter)
        ?.label.toLocaleLowerCase()}`;
    }

    return (
      <Sidebar
        onClose={() => {
          dispatch(closeInvoicePreview());
        }}
      >
        <div className={classes.container}>
          <div className={classes.title}>Preview invoice</div>

          <div className={classes.content}>
            <header className={classes.header}>
              <div className={classes.total}>
                {formatMoney(
                  data.lineItems.reduce(
                    (total, item) => total + (Number(item.amount) || 0),
                    0
                  )
                )}
              </div>
              <div className={classes.dueDate}>
                Due {moment.utc(data.dueDate).format("MMM D, YYYY")}
              </div>
              <Button>Pay invoice</Button>
            </header>

            <section className={classes.section}>
              <InvoiceDetailsInfoSection
                userCompany={props.userCompany}
                clientCompany={data.company}
                invoiceName="Invoice #___INVOICE_ID___"
                createdDate={new Date()}
                dueDateTypeOnReceipt={data.dueDateType === "on-receipt"}
                dueDate={data.dueDate}
                clientName={data.name}
                clientEmail={data.email}
                additionalEmails={data.additionalEmails}
                lineItems={data.lineItems.map(lineItem => ({
                  description: lineItem.title,
                  totalCost: Number(lineItem.amount || 0)
                }))}
                lateFees={lateFee ? [lateFee] : undefined}
                projectName={data.projectName}
                notes={data.notes}
              />
            </section>

            <footer className={classes.footer}>
              {props.userCompany && (
                <div className={classes.company}>{props.userCompany}</div>
              )}

              <div className={classes.email}>
                <a href={`mailto:${props.userEmail}`}>{props.userEmail}</a>
              </div>

              <div className={classes.address}>
                {props.userBusinessAddress.address}
                {props.userBusinessAddress.addressLine2 &&
                  `, ${props.userBusinessAddress.addressLine2}`}
                <br />
                {/* {props.userBusinessAddress.city},{" "}
              {props.userBusinessAddress.state}{" "} */}
                {props.userBusinessAddress.zipCode}
              </div>

              <div className={classes.poweredBy}>Powered by</div>
              <div className={classes.wingspanLogo}>
                <img src={wingspanLogo} alt="Wingspan Logo" />
              </div>
            </footer>
          </div>
        </div>
      </Sidebar>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state: GlobalStore) => ({
  preview: getInvoicePreview(state),
  userCompany: getUserCompanyName(state),
  userEmail: getUserEmail(state),
  userBusinessAddress: getUserBusinessAddress(state)
});

export const InvoicePreviewContainer = connect(mapStateToProps)(InvoicePreview);

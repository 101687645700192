import React from "react";
import classes from "./styles.module.scss";
import classnames from "classnames";

const Column: React.FC = ({ children }) => (
  <div className={classes.column}>{children}</div>
);

interface Props {
  forceMobile?: boolean;
}

interface ColumnsComponent extends React.FC<Props> {
  Column: React.FC;
}

const Columns: ColumnsComponent = ({ children, forceMobile }) => (
  <div
    className={classnames(classes.container, {
      [classes.forceMobile]: forceMobile
    })}
  >
    {children}
  </div>
);

Columns.Column = Column;

export default Columns;

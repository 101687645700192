import { Field, FieldProps } from "formik";
import React from "react";
import TextAreaField, { TextAreaFieldProps } from ".";

interface Props
  extends Omit<TextAreaFieldProps, "value" | "error" | "onChange"> {
  name: string;
}

const FormikTextAreaField: React.FC<Props> = ({ name, ...props }) => (
  <Field name={name}>
    {({ field, form }: FieldProps) => (
      <TextAreaField
        name={field.name}
        value={field.value}
        onChange={field.onChange}
        onBlur={field.onBlur}
        error={
          form.touched[name] && form.errors[name]
            ? (form.errors[name] as string)
            : undefined
        }
        {...props}
      />
    )}
  </Field>
);

export default FormikTextAreaField;

import {
  WizardErrors,
  WizardOnChange,
  WizardStepBlock,
  WizardStepBlockType,
  WizardStepConfig,
  WizardStepField,
  WizardValues
} from "../types/wizardStep";

export function replaceVariables(
  text: string,
  formattedValues: { [key: string]: any }
) {
  const regexp = /\$\$[a-zA-Z]*/;

  while (text.search(regexp) >= 0) {
    const match = text.match(regexp);
    if (match) {
      const searchValue = match[0];
      const name = searchValue.slice(2);
      text = text.replace(
        searchValue,
        formattedValues[name] ? formattedValues[name] : ""
      );
    }
  }

  return text;
}

export function validate(
  values: WizardValues,
  blocks: WizardStepBlock[]
): WizardErrors {
  let errors: WizardErrors = {};

  blocks.forEach(block => {
    if (block.type === WizardStepBlockType.BOX) {
      const boxErrors = validate(values, block.blocks);
      errors = {
        ...errors,
        ...boxErrors
      };
    } else if (
      block.type === WizardStepBlockType.FIELD &&
      block.mandatory &&
      (!values[block.name] ||
        (block.fieldType === "checkbox" && values[block.name].length === 0))
    ) {
      errors[block.name] = block.label
        ? `${block.label} is required`
        : "Required";
    }
  });

  return errors;
}

export function fillDefaults(
  values: WizardValues,
  blocks: WizardStepBlock[],
  onChange: WizardOnChange
) {
  blocks.forEach(block => {
    if (block.type === WizardStepBlockType.BOX) {
      fillDefaults(values, block.blocks, onChange);
    } else if (
      block.type === WizardStepBlockType.FIELD &&
      !values[block.name]
    ) {
      if (
        block.fieldType === "selectSlider" &&
        block.options &&
        block.options.length > 0
      ) {
        onChange(block.name, block.options[0].value, block.options[0].label);
      }
    }
  });
}

export function getFieldsFromBlocks(
  blocks: WizardStepBlock[]
): WizardStepField[] {
  let fields: WizardStepField[] = [];

  blocks.forEach(block => {
    if (block.type === WizardStepBlockType.BOX) {
      const blockFields = getFieldsFromBlocks(block.blocks);
      fields = [...fields, ...blockFields];
    } else if (block.type === WizardStepBlockType.FIELD) {
      fields.push(block);
    }
  });

  return fields;
}

export function getFields(steps: WizardStepConfig[]): WizardStepField[] {
  let fields: WizardStepField[] = [];

  steps.forEach(step => {
    const blockFields = getFieldsFromBlocks(step.blocks);
    fields = [...fields, ...blockFields];
  });

  return fields;
}

export function getFieldsKeys(steps: WizardStepConfig[]): string[] {
  const fields = getFields(steps);
  return fields.map(field => field.name);
}

export function getFieldLabel(
  steps: WizardStepConfig[],
  key: string
): string | undefined {
  const fields = getFields(steps);
  const field = fields.find(field => field.name === key);
  return field ? field.label : undefined;
}

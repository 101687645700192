export interface PaymentsStatus {
  enabled: boolean;
  missingProperties?: string[];
  identityVerified?: boolean;
}

export interface PaymentsIndustry {
  title: string;
  code: string;
}

export interface MemberClientCreateData {
  company: string;
  name: string;
  emailTo: string;
  emailCC?: Array<string>;
}

export interface MemberClientUpdateData
  extends Partial<MemberClientCreateData> {}

export interface MemberClientResponse {
  memberClientId: string;
  company: string;
  name: string;
  emailTo: string;
  emailCC?: Array<string>;
}

export enum Unit {
  Hours = "Hours",
  Units = "Units"
}

export enum PaymentMethod {
  Credit = "Credit",
  ACH = "ACH",
  Manual = "Manual"
}

export enum IInvoiceStatus {
  Draft = "Draft",
  Open = "Open",
  Overdue = "Overdue",
  Cancelled = "Cancelled",
  Paid = "Paid"
}

export enum InvoiceStatusPhase {
  Draft,
  Open,
  Overdue,
  Cancelled,
  Paid
}

export enum Currency {
  USD = "USD"
}

export interface InvoiceLineItemRequest {
  description?: string;
  quantity?: number;
  unit?: Unit;
  costPerUnit?: number;
  totalCost: number;
}

export interface InvoiceLineItemResponse {
  invoiceLineItemId: string;
  description?: string;
  quantity: number;
  unit: Unit;
  costPerUnit: number;
  totalCost: number;
}

export interface FeeHandlingConfig {
  memberPays: number;
  clientPays: number;
}

export enum FrequencyInterval {
  Weekly,
  Monthly
}

export interface FrequencyConfig {
  interval: FrequencyInterval;
  every: number;
  dayInInterval: number;
  startDate: Date;
  endDate?: Date;
}

export interface LateFeeConfig {
  lateFeePercentage?: number;
  lateFeeAmount?: number;
  frequency: FrequencyConfig;
}

export interface InvoiceAttachments {
  invoicePdf?: string;
  receiptPdf?: string;
  invoiceLink?: string;
}

export interface InvoiceCreateData {
  memberClientId: string;
  lineItems: Array<InvoiceLineItemRequest>;
  feeLineItems?: Array<InvoiceLineItemRequest>;
  creditFeeHandling?: FeeHandlingConfig;
  lateFeeHandling?: LateFeeConfig;
  currency: Currency;
  paymentMethods?: Array<PaymentMethod>;
  requireAutoCollection?: boolean;
  invoiceNotes?: string;
  status: IInvoiceStatus;
  dueDate: Date;
  labels?: {
    projectName?: string;
  };
}

export interface InvoiceUpdateData extends Partial<InvoiceCreateData> {}

export interface InvoiceResponse {
  invoiceId: string;
  memberClientId: string;
  lineItems: Array<InvoiceLineItemResponse>;
  feeLineItems?: Array<InvoiceLineItemResponse>;
  creditFeeHandling?: FeeHandlingConfig;
  lateFeeHandling?: LateFeeConfig;
  currency: Currency;
  paymentMethods?: Array<PaymentMethod>;
  requireAutoCollection?: boolean;
  invoiceNotes?: string;
  status: IInvoiceStatus;
  dueDate: Date;
  updatedAt: Date;
  attachments: InvoiceAttachments;
}

export interface InvoicesFilter {
  memberId?: string;
  memberClientId?: string;
  status?:
    | IInvoiceStatus
    | {
        "!="?: IInvoiceStatus;
        in?: IInvoiceStatus[];
      };
  dueDate?: {
    [key in "=" | ">" | ">=" | "<" | "<="]?: Date;
  };
  createdAt?: {
    [key in "=" | ">" | ">=" | "<" | "<="]?: Date;
  };
  updatedAt?: {
    [key in "=" | ">" | ">=" | "<" | "<="]?: Date;
  };
}

import React, { useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import classNames from "classnames";

import styles from "./Notification.module.scss";
import { Icon } from "../Icon/Icon";

export enum NotificationStatus {
  success = "success",
  error = "error"
}

type Notification = {
  text: string | React.ReactNode;
  status?: NotificationStatus;
  key?: any;
  timeout?: number;
};

const TIMEOUT = 3000;

const shared: {
  notifications: Notification[];
} = { notifications: [] };

let onUpdateNotifications: (notifications: Notification[]) => void =
  console.log;

export const addNotification = (notification: Notification) => {
  notification.key = notification.key
    ? notification.key
    : `${Date.now()}_${Math.random().toFixed(4)}`;
  notification.timeout = notification.timeout ? notification.timeout : TIMEOUT;
  notification.status = notification.status
    ? notification.status
    : NotificationStatus.success;

  window.setTimeout(
    (key: any) => {
      shared.notifications = shared.notifications.filter(n => n.key !== key);
      onUpdateNotifications(shared.notifications);
    },
    notification.timeout,
    notification.key
  );

  shared.notifications = [notification, ...shared.notifications];
  onUpdateNotifications(shared.notifications);
};

// @ts-ignore
window.addNotification = addNotification;

export const removeNotification = (key: string) => {
  shared.notifications = shared.notifications.filter(n => n.key !== key);
  onUpdateNotifications(shared.notifications);
};

export const Notifications: React.FC<{}> = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    onUpdateNotifications = setNotifications;

    return () => {
      onUpdateNotifications = console.log;
    };
  }, []);

  return (
    <TransitionGroup
      className={classNames(styles.notifications, {
        [styles.ignore]: notifications.length === 0
      })}
    >
      {notifications.map(({ text, status, key }, index) => (
        <CSSTransition
          key={key}
          timeout={500}
          classNames={{
            appear: styles.appear,
            appearActive: styles.appearActive,
            appearDone: styles.appearDone,
            enter: styles.enter,
            enterActive: styles.enterActive,
            enterDone: styles.enterDone,
            exit: styles.exit,
            exitActive: styles.exitActive,
            exitDone: styles.exitDone
          }}
        >
          <div
            className={classNames(styles.notification, {
              [styles.success]: status === NotificationStatus.success,
              [styles.error]: status === NotificationStatus.error
            })}
          >
            <Icon
              className={styles.close}
              name="cross_blue_13"
              onClick={() => {
                removeNotification(key);
              }}
            />
            {status === NotificationStatus.success && (
              <Icon
                className={styles.statusIcon}
                name="circle_check_green_16"
              />
            )}
            {status === NotificationStatus.error && (
              <Icon className={styles.statusIcon} name="warning_red_16" />
            )}
            <span className={styles.text}>{text}</span>
          </div>
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
};

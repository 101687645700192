function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from "react";
export default (({
  styles = {},
  ...props
}) => React.createElement("svg", _extends({
  "data-name": "Layer 1",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 30 30"
}, props), React.createElement("path", {
  fill: "#ffdcd2",
  d: "M5.75 5.75h18.5v13.5H5.75z"
}), React.createElement("path", {
  d: "M23.5 6.5v12h-17v-12h17M25 5H5v15h20V5z",
  fill: "#fff"
}), React.createElement("path", {
  d: "M25 20.5H5a.5.5 0 01-.5-.5V5a.5.5 0 01.5-.5h20a.5.5 0 01.5.5v15a.5.5 0 01-.5.5zm-19.5-1h19v-14h-19z",
  fill: "#282d37"
}), React.createElement("path", {
  d: "M22 17.5H8a.5.5 0 01-.5-.5V8a.5.5 0 01.5-.5h14a.5.5 0 01.5.5v9a.5.5 0 01-.5.5zm-13.5-1h13v-8h-13zM15 25.5a.5.5 0 01-.5-.5v-5a.5.5 0 011 0v5a.5.5 0 01-.5.5z",
  fill: "#282d37"
}), React.createElement("path", {
  d: "M19 25.5h-8a.5.5 0 010-1h8a.5.5 0 010 1zM19 11.5h-8a.5.5 0 010-1h8a.5.5 0 010 1zM19 14.5h-8a.5.5 0 010-1h8a.5.5 0 010 1z",
  fill: "#282d37"
}), React.createElement("path", {
  fill: "none",
  d: "M0 0h30v30H0z"
})));
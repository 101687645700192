import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import logo from "../../assets/images/logo.svg";
import { getMetaIsReady } from "../../store/meta/selectors";
import { GlobalStore } from "../../types/store";
import { initApp } from "../../store/actions/initApp";
import { INIT_APP } from "../../store/constants";

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = StateProps;

const InitApp: React.FC<Props> = ({ isReady, children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initApp());
  }, [dispatch]);

  return isReady ? (
    <>{children}</>
  ) : (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh"
      }}
    >
      <img src={logo} style={{ maxWidth: "70%" }} alt="Wingspan logo" />
    </div>
  );
};

const mapStateToProps = (state: GlobalStore) => ({
  isReady: getMetaIsReady(state, INIT_APP)
});

export const InitAppContainer = connect(mapStateToProps)(InitApp);

import paymentsService from "../../services/payments";
import { InvoiceCreateData } from "../../types/paymentsService";
import { AppThunk } from "../../types/store";
import { trackInvoiceAction } from "../../utils/analytics";
import {
  CREATE_INVOICE,
  CREATE_INVOICE_FAILURE,
  CREATE_INVOICE_REQUEST,
  CREATE_INVOICE_SUCCESS
} from "../constants";
import { createAction } from "../meta/types";

export const createInvoiceActions = createAction(
  CREATE_INVOICE,
  CREATE_INVOICE_REQUEST,
  CREATE_INVOICE_SUCCESS,
  CREATE_INVOICE_FAILURE
)<{ data: InvoiceCreateData }, { invoiceId: string }, Error>();

export const createInvoice = (
  data: InvoiceCreateData
): AppThunk<any> => async dispatch => {
  dispatch(createInvoiceActions.request({ data }));
  try {
    const { invoiceId } = await paymentsService.createInvoice(data);

    trackInvoiceAction("Save & Exit", invoiceId, data.status, {
      location: "Create"
    });

    dispatch(createInvoiceActions.success({ invoiceId }));

    return invoiceId;
  } catch (error) {
    dispatch(createInvoiceActions.failure(error));
  }
};

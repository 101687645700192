import React from "react";
import Row from "./Row";
import classes from "./styles.module.scss";

interface Props {
  title: string;
  description?: string;
}

interface FromSectionComponent extends React.FC<Props> {
  Row: typeof Row;
}

const FormSection: FromSectionComponent = ({
  title,
  description,
  children
}) => (
  <div className={classes.container}>
    <aside className={classes.aside}>
      <h2>{title}</h2>
      {description && <p>{description}</p>}
    </aside>
    <div className={classes.fields}>{children}</div>
  </div>
);

FormSection.Row = Row;

export default FormSection;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  makeStyles,
  Tab as MaterialTab,
  Tabs as MaterialTabs
} from "@material-ui/core";

import Goal from "../Goal";
import classes from "./GoalsList.module.scss";
import iconPlus from "../../assets/icons/add_24.svg";

const useContainerStyles = makeStyles({
  root: {
    marginTop: 0
  },
  indicator: {
    backgroundColor: "#F5554B"
  }
});

const useTabStyles = makeStyles({
  root: {
    padding: 13,
    backgroundColor: "#FFFFFF",
    color: "#ABAEB5",
    fontFamily: "Centra No2",
    fontSize: 14,
    lineHeight: 1.5,
    textTransform: "capitalize",
    opacity: 1
  },
  selected: {
    color: "#282D37" // This is not working
  }
});

interface Props {
  active: any[];
  completed: any[];
  onChange: (goalId: string) => void;
  value: string;
  className?: string;
}

const GoalsList: React.FC<Props> = ({
  active,
  completed,
  onChange,
  className,
  value
}) => {
  const [currentTab, setCurrentTab] = useState("active");
  const containerClasses = useContainerStyles();
  const tabClasses = useTabStyles();

  const handleChange = (_: any, newCurrentTab: string) => {
    setCurrentTab(newCurrentTab);
  };

  return (
    <div className={className}>
      <div className={classes.header}>
        <h2>Your Goals</h2>
        <div className={classes.createIcon}>
          <Link to="/create-goal">
            <img src={iconPlus} alt="Create Goal" />
          </Link>
        </div>
      </div>
      <MaterialTabs
        classes={{
          root: containerClasses.root,
          indicator: containerClasses.indicator
        }}
        value={currentTab}
        onChange={handleChange}
        variant="fullWidth"
      >
        <MaterialTab
          classes={{ root: tabClasses.root, selected: tabClasses.selected }}
          label={`Active (${active.length})`}
          id="simple-tab-active"
          aria-controls="simple-tabpanel-active"
          value="active"
        />
        <MaterialTab
          classes={{ root: tabClasses.root, selected: tabClasses.selected }}
          label={`Completed (${completed.length})`}
          id="simple-tab-completed"
          aria-controls="simple-tabpanel-completed"
          value="completed"
        />
      </MaterialTabs>
      {currentTab === "active"
        ? active.map((goal: any) => (
            <Goal
              active={value === String(goal.freshdeskTicketId)}
              innerIndent
              key={goal.freshdeskTicketId}
              category={goal.category}
              subject={goal.subject}
              lastMessageName={goal.agentName}
              lastMessageText={goal.description}
              lastMessagePhoto={goal.agentPhoto}
              unreadCount={goal.unreadCount || 0}
              onClick={() => {
                onChange(goal.freshdeskTicketId);
              }}
            />
          ))
        : completed.map((goal: any) => (
            <Goal
              active={value === String(goal.freshdeskTicketId)}
              innerIndent
              key={goal.freshdeskTicketId}
              category={goal.category}
              subject={goal.subject}
              lastMessageName={goal.agentName}
              lastMessageText={goal.description}
              lastMessagePhoto={goal.agentPhoto}
              unreadCount={goal.unreadCount || 0}
              onClick={() => {
                onChange(goal.freshdeskTicketId);
              }}
              completed
              completedDate={goal.completed && new Date(goal.completed)}
            />
          ))}
    </div>
  );
};

export default GoalsList;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchUserUpdate } from "../../store/actions/fetchUserUpdate";
import { PREFILL_FIELDS } from "../../constants/prefillFields";
import {
  WizardErrors,
  WizardFile,
  WizardOnChange,
  WizardStepBlock,
  WizardStepBlockType,
  WizardStepConfig,
  WizardValues
} from "../../types/wizardStep";
import { fillDefaults, validate } from "../../utils/wizardStep";
import Button from "../Button";
import MainLayout from "../MainLayout";
import Box from "./Box";
import Field from "./Field";
import FieldValue from "./FieldValue";
import FilesList from "./FilesList";
import Subheader from "./Subheader";
import Text from "./Text";

function renderWizardStepBlocks(
  blocks: WizardStepBlock[],
  values: WizardValues,
  formattedValues: WizardValues,
  onChange: WizardOnChange,
  files?: WizardFile[],
  onFilesChange?: (files: WizardFile[]) => void
) {
  return blocks.map((block, index) => {
    switch (block.type) {
      case WizardStepBlockType.BOX:
        return (
          <Box key={index}>
            {renderWizardStepBlocks(
              block.blocks,
              values,
              formattedValues,
              onChange,
              files,
              onFilesChange
            )}
          </Box>
        );

      case WizardStepBlockType.SUBHEADER:
        return (
          <Subheader
            key={index}
            value={block.value}
            formattedValues={formattedValues}
          />
        );

      case WizardStepBlockType.TEXT:
        return (
          <Text
            key={index}
            value={block.value}
            formattedValues={formattedValues}
          />
        );

      case WizardStepBlockType.FIELD:
        return (
          <Field
            key={index}
            type={block.fieldType}
            name={block.name}
            label={block.label}
            mandatory={block.mandatory}
            options={block.options}
            values={values}
            onChange={onChange}
            files={files}
            onFilesChange={onFilesChange}
          />
        );

      case WizardStepBlockType.FIELD_VALUE:
        return (
          <FieldValue
            key={index}
            title={block.title}
            value={block.value}
            formattedValues={formattedValues}
          />
        );

      case WizardStepBlockType.FILES_LIST:
        return (
          <FilesList key={index} title={block.title} files={files || []} />
        );

      default:
        return <div key={index}>{JSON.stringify(block)}</div>;
    }
  });
}

interface Props {
  config: WizardStepConfig;
  values: WizardValues;
  formattedValues: WizardValues;
  onChange: WizardOnChange;
  onBack: () => void;
  onNext: () => void;
  onSubmit: () => void;
  files?: WizardFile[];
  onFilesChange?: (files: WizardFile[]) => void;
  simpleHeader?: boolean;
  last?: boolean;
  headerColor?: string;
}

const WizardStep: React.FC<Props> = ({
  config,
  values,
  formattedValues,
  onChange,
  onBack,
  onNext,
  onSubmit,
  files,
  onFilesChange,
  simpleHeader,
  last,
  headerColor
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<WizardErrors>({});
  const valid = Object.keys(errors).length === 0;

  // Validate on changes
  useEffect(() => {
    setErrors(validate(values, config.blocks));
  }, [values, config]);

  async function sendCoreData() {
    try {
      const fieldsToUpdate: any = {};

      PREFILL_FIELDS.forEach(field => {
        if (values[field.name]) {
          fieldsToUpdate[field.name] = values[field.name];
        }
      });

      await dispatch(fetchUserUpdate(fieldsToUpdate));
    } catch (error) {
      console.error("Failed to save core data");
      console.error(error);
    }
  }

  return (
    <MainLayout title={config.header} simpleHeader={simpleHeader}>
      <MainLayout.PageHeader
        header={config.header}
        subHeader={config.subHeader}
        noPaper={config.blocks[0].type === "fieldValue"}
        backgroundColor={headerColor}
      />
      <MainLayout.Main>
        {renderWizardStepBlocks(
          config.blocks,
          values,
          formattedValues,
          onChange,
          files,
          onFilesChange
        )}

        <MainLayout.Actions>
          {last ? (
            <Button
              disabled={!valid}
              loading={loading}
              onClick={async () => {
                fillDefaults(values, config.blocks, onChange);
                setLoading(true);
                await sendCoreData();
                await onSubmit();
                setLoading(false);
              }}
            >
              {config.buttonText || "Submit"}
            </Button>
          ) : (
            <Button
              disabled={!valid}
              loading={loading}
              onClick={async () => {
                fillDefaults(values, config.blocks, onChange);
                setLoading(true);
                await sendCoreData();
                await onNext();
                setLoading(false);
              }}
            >
              {config.buttonText || "Next"}
            </Button>
          )}

          <Button
            type="link"
            padding="none"
            onClick={() => {
              onBack();
            }}
          >
            Back
          </Button>
        </MainLayout.Actions>
      </MainLayout.Main>
    </MainLayout>
  );
};

export default WizardStep;

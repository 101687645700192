import paymentsService from "../../services/payments";
import { IInvoiceStatus, InvoiceCreateData } from "../../types/paymentsService";
import { AppThunk } from "../../types/store";
import { trackInvoiceAction } from "../../utils/analytics";
import {
  CREATE_AND_SEND_INVOICE,
  CREATE_AND_SEND_INVOICE_FAILURE,
  CREATE_AND_SEND_INVOICE_REQUEST,
  CREATE_AND_SEND_INVOICE_SUCCESS
} from "../constants";
import { createAction } from "../meta/types";

export const sendInvoiceActions = createAction(
  CREATE_AND_SEND_INVOICE,
  CREATE_AND_SEND_INVOICE_REQUEST,
  CREATE_AND_SEND_INVOICE_SUCCESS,
  CREATE_AND_SEND_INVOICE_FAILURE
)<{ data: InvoiceCreateData }, { invoiceId: string }, Error>();

export const createAndSendInvoice = (
  data: InvoiceCreateData
): AppThunk<any> => async dispatch => {
  dispatch(sendInvoiceActions.request({ data }));
  try {
    const { invoiceId } = await paymentsService.createInvoice(data);

    trackInvoiceAction("Create and Send", invoiceId, data.status, {
      location: "Create"
    });

    await paymentsService.updateInvoice(invoiceId, {
      status: IInvoiceStatus.Open
    });

    dispatch(sendInvoiceActions.success({ invoiceId }));
  } catch (error) {
    dispatch(sendInvoiceActions.failure(error));
  }
};

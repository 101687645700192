import { MemberClientState, MemberClientStateData } from "../../types/store";
import {
  FETCH_CREATE_MEMBER_CLIENT_FAILURE,
  FETCH_CREATE_MEMBER_CLIENT_REQUEST,
  FETCH_CREATE_MEMBER_CLIENT_SUCCESS,
  FETCH_MEMBER_CLIENT_DETAILS_FAILURE,
  FETCH_MEMBER_CLIENT_DETAILS_REQUEST,
  FETCH_MEMBER_CLIENT_DETAILS_SUCCESS,
  FETCH_MEMBER_CLIENT_LIST_FAILURE,
  FETCH_MEMBER_CLIENT_LIST_REQUEST,
  FETCH_MEMBER_CLIENT_LIST_SUCCESS
} from "../constants";

/* List */

export const fetchMemberClientListRequest = () =>
  ({ type: FETCH_MEMBER_CLIENT_LIST_REQUEST } as const);

export const fetchMemberClientListSuccess = (
  byId: {
    [id: string]: MemberClientState;
  },
  listIds: string[]
) =>
  ({
    type: FETCH_MEMBER_CLIENT_LIST_SUCCESS,
    payload: { byId, listIds }
  } as const);

export const fetchMemberClientListFailure = (error: string) =>
  ({
    type: FETCH_MEMBER_CLIENT_LIST_FAILURE,
    payload: { error }
  } as const);

/* Details */

export const fetchMemberClientDetailsRequest = (memberClientId: string) =>
  ({
    type: FETCH_MEMBER_CLIENT_DETAILS_REQUEST,
    payload: { memberClientId }
  } as const);

export const fetchMemberClientDetailsSuccess = (
  memberClientId: string,
  memberClient: MemberClientStateData
) =>
  ({
    type: FETCH_MEMBER_CLIENT_DETAILS_SUCCESS,
    payload: { memberClientId, memberClient }
  } as const);

export const fetchMemberClientDetailsFailure = (
  memberClientId: string,
  error: string
) =>
  ({
    type: FETCH_MEMBER_CLIENT_DETAILS_FAILURE,
    payload: { memberClientId, error }
  } as const);

/* Create */

export const fetchCreateMemberClientRequest = () =>
  ({
    type: FETCH_CREATE_MEMBER_CLIENT_REQUEST
  } as const);

export const fetchCreateMemberClientSuccess = (
  memberClient: MemberClientStateData
) =>
  ({
    type: FETCH_CREATE_MEMBER_CLIENT_SUCCESS,
    payload: { memberClient }
  } as const);

export const fetchCreateMemberClientFailure = (error: string) =>
  ({
    type: FETCH_CREATE_MEMBER_CLIENT_FAILURE,
    payload: { error }
  } as const);

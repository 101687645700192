import * as apiService from "../../services/api";
import { signInActions } from "./userActions";
import { AppThunk } from "../../types/store";
import { track } from "../../utils/analytics";
import { setAuthToken } from "../../utils/auth";
import { fetchUser } from "./fetchUser";

export const signIn = (
  data: apiService.SignInData
): AppThunk<any> => async dispatch => {
  dispatch(signInActions.request());

  try {
    const { token } = await apiService.signIn(data);
    setAuthToken(token);
    await dispatch(fetchUser());
    track("Log In");

    dispatch(signInActions.success());
  } catch (error) {
    dispatch(signInActions.failure(error));
  }
};

import paymentsService from "../../services/payments";
import { fetchMCCsActions } from "./paymentsActions";
import { AppThunk } from "../../types/store";

export const fetchMCCs = (): AppThunk<any> => async dispatch => {
  dispatch(fetchMCCsActions.request(undefined));

  try {
    const mccs = await paymentsService.getMCCs();

    dispatch(
      fetchMCCsActions.success({
        mccs: mccs.map(mcc => ({
          value: mcc.code,
          label: mcc.title
        }))
      })
    );
  } catch (error) {
    dispatch(fetchMCCsActions.failure(error));
  }
};

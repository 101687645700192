import React from "react";
import styles from "./styles.module.scss";

interface Props {
  header: string;
  subHeader: string;
}

const Pill: React.FC<Props> = ({ header, subHeader, children }) => (
  <main className={styles.pillBackground}>
    <div className={styles.pill}>
      <div className={styles.pillBody}>
        <div className={styles.pillHeader}>
          <h1>{header}</h1>
          <h2>{subHeader}</h2>
        </div>
        {children}
      </div>
    </div>
  </main>
);

export default Pill;

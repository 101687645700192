import React from "react";
import moment from "moment";
import { Badge } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import classnames from "classnames";

import ProfilePhoto from "../ProfilePhoto";
import classes from "./styles.module.scss";

interface Props {
  category: string;
  subject: string;
  lastMessageName: string;
  lastMessageText: string;
  lastMessagePhoto?: string;
  completed?: boolean;
  completedDate?: Date;
  onClick: () => void;
  innerIndent?: boolean;
  active?: boolean;
  noHover?: boolean;
  unreadCount?: number;
}

// To be added with unread functionality
const StyledBadge = withStyles({
  badge: {
    backgroundColor: "#F5554B",
    transform: "scale(1) translate(40%, -40%)"
  }
})(Badge);

const Goal: React.FC<Props> = ({
  category,
  subject,
  lastMessageName,
  lastMessageText,
  lastMessagePhoto,
  completed,
  completedDate,
  onClick,
  innerIndent,
  active,
  noHover,
  unreadCount
}) => (
  <div
    className={classnames(classes.container, {
      [classes.innerIndent]: innerIndent,
      [classes.active]: active,
      [classes.noHover]: noHover
    })}
    onClick={onClick}
  >
    <div className={classes.top}>
      <div className={classes.category}>{category}</div>
      {completed && (
        <div>
          Completed {completedDate && moment(completedDate).format("DD/MM/YY")}
        </div>
      )}
    </div>
    <h2>{subject}</h2>
    <div className={classes.lastMessage}>
      <div className={classes.lastMessagePhotoContainer}>
        {unreadCount && unreadCount > 0 ? (
          <StyledBadge badgeContent={unreadCount} color="primary">
            <ProfilePhoto
              url={lastMessagePhoto}
              name={lastMessageName}
              className={classes.lastMessagePhoto}
            />
          </StyledBadge>
        ) : (
          <ProfilePhoto
            url={lastMessagePhoto}
            name={lastMessageName}
            className={classes.lastMessagePhoto}
          />
        )}
      </div>
      <div className={classes.lastMessageMain}>
        <div className={classes.lastMessageName}>{lastMessageName}</div>
        <div>
          {lastMessageText.length > 25
            ? `${lastMessageText.slice(0, 25)}...`
            : lastMessageText}
        </div>
      </div>
    </div>
  </div>
);

export default Goal;

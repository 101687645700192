import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { fetchUser } from "../../store/actions/fetchUser";
import AgentAssignment from "../../components/AgentAssignment";
import Modal from "../../components/Modal";
import WizardStep from "../../components/WizardStep";
import { createTicket } from "../../services/api";
import { getUserFields } from "../../store/selectors/user";
import { GoalCreationSectionConfig } from "../../types/goals";
import { GlobalStore } from "../../types/store";
import {
  WizardFile,
  WizardStepConfig,
  WizardValues
} from "../../types/wizardStep";
import { track } from "../../utils/analytics";
import { getCoreData, getUrl, prepareTicketData } from "../../utils/newTicket";

type OwnProps = {
  config: WizardStepConfig[];
  currentStepIndex: number;
  sectionPath: string[];
  parentSection: GoalCreationSectionConfig;
};

type RouterProps = RouteComponentProps<{ [key: string]: string }>;

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & RouterProps & StateProps;

const NewTicketWizard: React.FC<Props> = ({
  config,
  currentStepIndex,
  sectionPath,
  parentSection,
  history,
  userFields
}) => {
  const dispatch = useDispatch();

  const [values, setValues] = useState<WizardValues>({});
  const [formattedValues, setFormattedValues] = useState<WizardValues>({});
  const [files, setFiles] = useState<WizardFile[]>([]);
  const [successModalVisible, setSuccesModalVisibility] = useState(false);
  const [ticketId, setTicketId] = useState("");
  const [agent, setAgent] = useState<{ name: string; photo?: string } | null>(
    null
  );

  useEffect(() => {
    const coreData = getCoreData(config, userFields);

    setValues(prevValues => ({ ...prevValues, ...coreData.values }));
    setFormattedValues(prevFormattedValues => ({
      ...prevFormattedValues,
      ...coreData.formattedValues
    }));
  }, [config, userFields]);

  useEffect(() => {
    const currentStepConfig = config[currentStepIndex];
    const isReviewStep = config.length - 1 === currentStepIndex;

    track("View Create Goal Page", {
      category: parentSection.ticketCategoryName,
      subject: parentSection.ticketSubject,
      step: isReviewStep ? "review" : currentStepIndex,
      subjectStep: isReviewStep ? "Review" : currentStepConfig.header
    });
  }, [parentSection, config, currentStepIndex]);

  return (
    <>
      <Modal
        visible={successModalVisible}
        thin
        onClose={() => {
          history.push(`/goals/f/${ticketId}`);
        }}
      >
        <AgentAssignment
          agent={agent}
          onClick={() => {
            history.push(`/goals/f/${ticketId}`);
          }}
        />
      </Modal>
      <WizardStep
        config={config[currentStepIndex]}
        last={currentStepIndex === config.length - 1}
        values={values}
        formattedValues={formattedValues}
        onChange={(name, value, formattedValue) => {
          setValues({
            ...values,
            [name]: value
          });
          setFormattedValues({
            ...formattedValues,
            [name]: formattedValue || value
          });
        }}
        onBack={() => {
          history.push(
            currentStepIndex === 0
              ? getUrl(sectionPath.slice(0, sectionPath.length - 1))
              : getUrl(sectionPath, currentStepIndex - 1)
          );
        }}
        onNext={() => {
          track("Click Next on Create Goal");
          history.push(getUrl(sectionPath, currentStepIndex + 1));
        }}
        onSubmit={async () => {
          try {
            const ticket = await createTicket(
              prepareTicketData(formattedValues, config, parentSection, files)
            );

            dispatch(fetchUser());

            track("Submit Goal", {
              freshdeskTicketId: ticket.id,
              category: ticket.category,
              subject: ticket.subject,
              status: ticket.status,
              assignedAgentName: ticket.assignedAgent.agentName
            });

            setTicketId(ticket.id);
            setAgent({
              name: ticket.assignedAgent.agentName,
              photo: ticket.assignedAgent.avatarUrl
            });
            setSuccesModalVisibility(true);
            setValues({});
          } catch (error) {
            console.error("Failed to create a ticket");
            console.error(error);
          }
        }}
        files={files}
        onFilesChange={files => {
          setFiles(files);
        }}
        headerColor={
          currentStepIndex % 3 === 0
            ? "#E6D7EB"
            : currentStepIndex % 3 === 1
            ? "#DBF0FF"
            : currentStepIndex % 3 === 2
            ? "#FFDCD2"
            : "#FFDCD2"
        }
      />
    </>
  );
};

const mapStateToProps = (state: GlobalStore) => ({
  userFields: getUserFields(state)
});

export default withRouter(connect(mapStateToProps)(NewTicketWizard));

import {
  META_FAILURE_STATUS,
  META_REQUEST_STATUS,
  META_SUCCESS_STATUS
} from "./constants";

export type MetaStatus =
  | typeof META_REQUEST_STATUS
  | typeof META_SUCCESS_STATUS
  | typeof META_FAILURE_STATUS;

export type MetaAction = {
  type: string;
  key?: string;
  status: MetaStatus;
  payload?: any;
  actionName: string;
};

export function createAction<
  ActionName extends string,
  RequestType extends string,
  SuccessType extends string,
  FailureType extends string
>(
  actionName: ActionName,
  requestType: RequestType,
  successType: SuccessType,
  failureType: FailureType
): <RequestPayload = any, SuccessPayload = any, FailurePayload = any>() => {
  request(
    payload: RequestPayload,
    key?: string
  ): {
    type: RequestType;
    payload: RequestPayload;
    key?: string;
    status: typeof META_REQUEST_STATUS;
    actionName: ActionName;
  };
  request(): {
    type: RequestType;
    payload: RequestPayload;
    key?: string;
    status: typeof META_REQUEST_STATUS;
    actionName: ActionName;
  };
  success(
    payload: SuccessPayload,
    key?: string
  ): {
    type: SuccessType;
    payload: SuccessPayload;
    key?: string;
    status: typeof META_SUCCESS_STATUS;
    actionName: ActionName;
  };
  success(): {
    type: SuccessType;
    payload: SuccessPayload;
    key?: string;
    status: typeof META_SUCCESS_STATUS;
    actionName: ActionName;
  };
  failure(
    payload: FailurePayload,
    key?: string
  ): {
    type: FailureType;
    payload: string;
    key?: string;
    status: typeof META_FAILURE_STATUS;
    actionName: ActionName;
  };
  failure(): {
    type: FailureType;
    payload: string;
    key?: string;
    status: typeof META_FAILURE_STATUS;
    actionName: ActionName;
  };
} {
  const correctRequestType = `${actionName}_${META_REQUEST_STATUS}`;
  if (requestType !== correctRequestType) {
    console.error(
      `RequestType is not valid! Please use "${correctRequestType}" instead of ${requestType}"!`
    );
  }

  const correctSuccessType = `${actionName}_${META_SUCCESS_STATUS}`;
  if (successType !== correctSuccessType) {
    console.error(
      `SuccessType is not valid! Please use "${correctSuccessType}" instead of "${successType}"!`
    );
  }

  const correctFailureType = `${actionName}_${META_FAILURE_STATUS}`;
  if (failureType !== correctFailureType) {
    console.error(
      `FailureType is not valid! Please use "${correctFailureType}" instead of "${failureType}"!`
    );
  }

  return () => ({
    request: (payload = undefined as any, key = undefined as any) =>
      ({
        type: requestType,
        payload,
        status: META_REQUEST_STATUS,
        key,
        actionName
      } as const),
    success: (payload = undefined as any, key = undefined as any) =>
      ({
        type: successType,
        payload,
        status: META_SUCCESS_STATUS,
        key,
        actionName
      } as const),

    failure: (payload = undefined as any, key = undefined as any) => {
      let errorMessage = "Something went wrong. Please try again later.";
      const error = payload as any;

      if (typeof error?.response?.data?.error === "string") {
        errorMessage = error.response.data.error;
      } else if (typeof error?.response?.data?.errors === "string") {
        errorMessage = error.response.data.errors;
      } else if (error) {
        errorMessage = error.toString();
      }
      return {
        type: failureType,
        payload: errorMessage,
        status: META_FAILURE_STATUS,
        key,
        actionName
      } as const;
    }
  });
}

export type MetaState = {
  status: MetaStatus;
  try: number;
  error?: Error;
};

export type MetaStore = {
  [metaName: string]: {
    [metaKey: string]: MetaState;
  };
};

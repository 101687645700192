import classnames from "classnames";
import React from "react";
import iconFile from "../../assets/icons/file_24.svg";
import { WizardFile } from "../../types/wizardStep";
import fieldValueStyles from "../FieldValue/styles.module.scss";
import styles from "./styles.module.scss";

interface Props {
  title: string;
  files: WizardFile[];
}

const FilesList: React.FC<Props> = ({ title, files }) => (
  <div
    className={classnames(styles.wizardFilesList, fieldValueStyles.container)}
  >
    <div className={fieldValueStyles.label}>{title}</div>
    {files.length ? (
      <ul>
        {files.map((file, index) => (
          <li key={index}>
            <img src={iconFile} alt="file" className={styles.iconFile} />
            {file.fileName}
          </li>
        ))}
      </ul>
    ) : (
      <div
        className={classnames(
          fieldValueStyles.value,
          fieldValueStyles.valueMissing
        )}
      >
        Not included
      </div>
    )}
  </div>
);

export default FilesList;

import React, { useEffect } from "react";

interface Props {
  title: string;
}

export const EmptyLayout: React.FC<Props> = ({ title, children }) => {
  useEffect(() => {
    document.title = title + " - Wingspan";
    window.analytics.page();
  }, [title]);

  return <>{children}</>;
};

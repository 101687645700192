import classNames from "classnames";
import React from "react";
import classes from "./styles.module.scss";

export interface FieldContainerProps {
  label?: string;
  required?: boolean;
  error?: string;
  width?: string | number;
  smallIndent?: boolean;
}

const FieldContainer: React.FC<FieldContainerProps> = ({
  children,
  label,
  required,
  smallIndent,
  width,
  error
}) => (
  <div
    className={classNames(classes.container, {
      [classes.smallIndent]: smallIndent
    })}
    style={{ maxWidth: width }}
  >
    {label && (
      <label className={classes.label}>
        {label}
        {required && "*"}
      </label>
    )}
    <div className={classes.control}>{children}</div>
    {error && <div className={classes.error}>{error}</div>}
  </div>
);

export default FieldContainer;

import React from "react";
import Card from "../Card";

const Box: React.FC = ({ children }) => (
  <Card>
    <Card.Main>{children}</Card.Main>
  </Card>
);

export default Box;

import React from "react";
import classes from "./styles.module.scss";

interface Props {
  title?: string;
}

const Section: React.FC<Props> = ({ title, children }) => (
  <div className={classes.container}>
    {title && (
      <header>
        <h2>{title}</h2>
      </header>
    )}
    {children}
  </div>
);

export default Section;

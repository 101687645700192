import paymentsService from "../../services/payments";
import { AppThunk } from "../../types/store";
import { trackInvoiceAction } from "../../utils/analytics";
import {
  CANCEL_INVOICE,
  CANCEL_INVOICE_FAILURE,
  CANCEL_INVOICE_REQUEST,
  CANCEL_INVOICE_SUCCESS
} from "../constants";
import { createAction } from "../meta/types";
import { getInvoiceDataById } from "../selectors/invoices";

export const cancelInvoiceActions = createAction(
  CANCEL_INVOICE,
  CANCEL_INVOICE_REQUEST,
  CANCEL_INVOICE_SUCCESS,
  CANCEL_INVOICE_FAILURE
)<{ invoiceId: string }, { invoiceId: string }, Error>();

export const cancelInvoice = (invoiceId: string): AppThunk<any> => async (
  dispatch,
  getState
) => {
  dispatch(cancelInvoiceActions.request({ invoiceId }, invoiceId));
  try {
    await paymentsService.cancelInvoice(invoiceId);

    const invoice = getInvoiceDataById(getState(), invoiceId);

    trackInvoiceAction("Cancel Invoice", invoiceId, invoice.status);

    dispatch(cancelInvoiceActions.success({ invoiceId }, invoiceId));
  } catch (error) {
    dispatch(cancelInvoiceActions.failure(error, invoiceId));
  }
};

import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import MainLayout from "../../components/MainLayout";
import { GoalCreationSectionConfig } from "../../types/goals";
import { track } from "../../utils/analytics";
import Card from "../Card";
import NewTicketListItem from "../CreateNewGoalWidget/ListItem";
import List from "../List";

interface Props {
  config: GoalCreationSectionConfig;
}

const NewTicketSection: React.FC<Props> = ({ config }) => {
  useEffect(() => {
    track("View Create Goal Page", {
      category: config.ticketCategoryName,
      subject: config.ticketSubject
    });
  }, [config]);

  if (config.wizardSteps) {
    return <Redirect to={`${window.location.pathname}/0`} />;
  } else if (config.sections) {
    return (
      <MainLayout title={config.header || config.menuTitle}>
        <MainLayout.PageHeader
          header={config.header || config.menuTitle}
          subHeader={config.subHeader}
          backgroundColor="#FFDCD2"
        />
        <MainLayout.Main>
          <Card>
            <Card.Main noPadding>
              <List>
                {config.sections.map(section => (
                  <List.Item
                    key={section.slug}
                    link={`${window.location.pathname}/${section.slug}`}
                  >
                    <NewTicketListItem icon={section.menuIcon}>
                      {section.menuTitle}
                    </NewTicketListItem>
                  </List.Item>
                ))}
              </List>
            </Card.Main>
          </Card>
        </MainLayout.Main>
      </MainLayout>
    );
  } else {
    return <Redirect to="/dashboard" />;
  }
};

export default NewTicketSection;

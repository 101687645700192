import moment from "moment";
import React from "react";
import { Desktop, MobileTablet } from "../Responsive";
import TextField, { TextFieldProps } from "../TextField";

interface Props extends Omit<TextFieldProps, "onChange" | "value"> {
  value?: Date;
  onChange: (value: Date | null) => void;
}

const DateField: React.FC<Props> = ({ value, onChange, ...textFieldProps }) => (
  <>
    <MobileTablet>
      <TextField
        {...textFieldProps}
        type="date"
        value={value ? moment.utc(value).format("YYYY-MM-DD") : ""}
        onChange={({ target: { value } }) => {
          if (value) {
            const valueMoment = moment.utc(value, "YYYY-MM-DD");
            onChange(valueMoment.toDate());
          } else {
            onChange(null);
          }
        }}
        forceFocused
      />
    </MobileTablet>
    <Desktop>
      <TextField
        {...textFieldProps}
        value={value ? moment.utc(value).format("MM/DD/YYYY") : ""}
        onChange={({ target: { value } }) => {
          if (value) {
            if (value.length === 10) {
              const valueMoment = moment.utc(value, "MM/DD/YYYY");
              onChange(valueMoment.toDate());
            }
          } else {
            onChange(null);
          }
        }}
        formatOptions={{ date: true, datePattern: ["m", "d", "Y"] }}
      />
    </Desktop>
  </>
);

export default DateField;

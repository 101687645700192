import classNames from "classnames";
import React, { useState, useRef } from "react";
import FieldContainer, { FieldContainerProps } from "../FieldContainer";
import classes from "./styles.module.scss";
import { useDropdownPosition } from "../../../utils/useDropdownPosition";
import { Option } from "../../../types/option";

export interface AutocompleteFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    FieldContainerProps {
  type?: "text" | "email" | "password" | "number" | "phone" | "date";
  value?: any;
  inputWidth?: number | string;
  options: Option[];
  onAutoComplete: (value: string) => void;
}

const AutocompleteField: React.FC<AutocompleteFieldProps> = ({
  label,
  error,
  smallIndent,
  required,
  width,
  inputWidth,
  value,
  options,
  onAutoComplete,
  ...otherInputProps
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { position: dropdownPosition } = useDropdownPosition(
    dropdownRef,
    dropdownVisible,
    45
  );
  const [focused, setFocused] = useState(false);

  const visibleOptions = value
    ? options.filter(option =>
        (option.label || option.value)
          .toLowerCase()
          .trim()
          .includes(value.toLowerCase().trim())
      )
    : options;

  return (
    <FieldContainer
      label={label}
      required={required}
      error={error}
      smallIndent={smallIndent}
      width={width}
    >
      <div
        className={classNames(classes.control, {
          [classes.withError]: !!error,
          [classes.focused]: focused
        })}
        style={{ maxWidth: inputWidth }}
      >
        <input
          value={value}
          required={required}
          {...otherInputProps}
          onFocus={(...args) => {
            setDropdownVisible(true);
            setFocused(true);
            otherInputProps.onFocus && otherInputProps.onFocus(...args);
          }}
          onBlur={(...args) => {
            const selectedOption = options.find(
              option => (option.label || option.value) === value
            );
            if (selectedOption) onAutoComplete(selectedOption.value);
            setFocused(false);
            otherInputProps.onBlur && otherInputProps.onBlur(...args);
          }}
          autoComplete="off"
        />
      </div>
      {dropdownVisible && (
        <>
          <div
            className={classes.overlay}
            onClick={() => {
              setDropdownVisible(false);
            }}
          />
          <div
            className={classNames(classes.dropdown, {
              [classes.positionTop]: dropdownPosition === "top"
            })}
            ref={dropdownRef}
          >
            {visibleOptions.map(option => (
              <div
                key={option.value}
                className={classNames(classes.option, {
                  [classes.selected]: option.value === value
                })}
                onClick={() => {
                  setDropdownVisible(false);
                  onAutoComplete(option.value);
                }}
              >
                {option.label || option.value}
              </div>
            ))}
          </div>
        </>
      )}
    </FieldContainer>
  );
};

export default AutocompleteField;

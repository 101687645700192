import classnames from "classnames";
import { CleaveOptions } from "cleave.js/options";
import Cleave from "cleave.js/react";
import React, { useState } from "react";
import classes from "./styles.module.scss";

export interface TextFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  type?: "text" | "email" | "password" | "number" | "phone" | "date";
  value?: any;
  label: string;
  required?: boolean;
  error?: string;
  onClick?: () => void;
  forceFocused?: boolean;
  formatOptions?: CleaveOptions;
}

const TextField: React.FC<TextFieldProps> = ({
  type,
  name,
  value,
  onChange,
  label,
  required,
  error,
  disabled,
  onClick,
  forceFocused,
  formatOptions,
  ...otherInputProps
}) => {
  const [focus, setFocus] = useState(false);

  const inputProps = {
    type,
    name,
    value,
    onChange,
    required,
    disabled,
    onFocus: () => {
      setFocus(true);
    },
    onBlur: () => {
      setFocus(false);
    },
    onClick,
    ...otherInputProps
  };

  return (
    <div
      className={classnames(classes.container, {
        [classes.focus]: focus || forceFocused,
        [classes.filled]: value !== undefined && value !== "",
        [classes.withError]: !!error
      })}
    >
      <label onClick={onClick}>
        {label}
        {required && "*"}
      </label>
      {formatOptions ? (
        <Cleave {...inputProps} options={formatOptions} />
      ) : (
        <input {...inputProps} />
      )}
      {error && <div className={classes.error}>{error}</div>}
    </div>
  );
};

export default TextField;

import React, { useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { EmptyLayout } from "../../components/EmptyLayout";
import {
  InvoiceFormContainer,
  InvoiceFormValues
} from "../../components/payments/InvoiceForm";
import { InvoicePreviewContainer } from "../../components/payments/InvoicePreview";
import paymentsService from "../../services/payments";
import { fetchInvoiceList } from "../../store/actions/fetchInvoiceList";
import { fetchMemberClientList } from "../../store/actions/fetchMemberClientList";
import { openInvoicePreview } from "../../store/actions/openInvoicePreview";
import { updateInvoice } from "../../store/actions/updateInvoice";
import { getInvoiceDataById } from "../../store/selectors/invoices";
import { getMemberClientDataById } from "../../store/selectors/memberClients";
import { Currency, IInvoiceStatus } from "../../types/paymentsService";
import { GlobalStore } from "../../types/store";
import { trackInvoiceAction } from "../../utils/analytics";
import {
  generateLateFeeAfter,
  generateLateFeeConfig
} from "../../utils/invoiceHelper";

type OwnProps = RouteComponentProps<{ invoiceId: string }>;
type MapStateToProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & MapStateToProps;

const InvoiceEdit: React.FC<Props> = props => {
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    async (values: InvoiceFormValues, status: IInvoiceStatus) => {
      try {
        await paymentsService.updateMemberClient(
          props.invoiceData.memberClientId,
          {
            company: values.company,
            name: values.name,
            emailTo: values.email,
            emailCC: values.additionalEmails
          }
        );

        await dispatch(
          updateInvoice(props.match.params.invoiceId, {
            lineItems: values.lineItems.map(lineItem => ({
              description: lineItem.title,
              totalCost: lineItem.amount ? Number(lineItem.amount) : 0
            })),
            currency: Currency.USD,
            status,
            dueDate: values.dueDate,
            invoiceNotes: values.notes,
            // labels: {
            //   projectName: values.projectName
            // },
            creditFeeHandling: {
              memberPays: values.clientPaysProcessingFee ? 0 : 100,
              clientPays: values.clientPaysProcessingFee ? 100 : 0
            },
            lateFeeHandling: generateLateFeeConfig({
              lateFee: values.lateFee,
              lateFeeAmount: values.lateFeeAmount,
              lateFeeAfter: values.lateFeeAfter
            })
          })
        );

        await Promise.all([
          dispatch(fetchInvoiceList()),
          dispatch(fetchMemberClientList())
        ]);

        props.history.push("/invoices");
      } catch (error) {
        console.error("Failed to create invoice");
        console.error(error);
      }
    },
    [
      dispatch,
      props.history,
      props.invoiceData.memberClientId,
      props.match.params.invoiceId
    ]
  );

  return props.invoiceData && props.memberClientData ? (
    <EmptyLayout title="Edit invoice">
      <InvoiceFormContainer
        title="Edit invoice"
        initialValues={{
          memberClientId: props.memberClientData.memberClientId,
          company: props.memberClientData.company,
          name: props.memberClientData.name,
          email: props.memberClientData.emailTo,
          additionalEmails: props.memberClientData.emailCC || [],
          lineItems: props.invoiceData.lineItems.map(lineItem => ({
            title: lineItem.description || "",
            amount: String(lineItem.totalCost)
          })),
          clientPaysProcessingFee:
            props.invoiceData.creditFeeHandling?.memberPays !== 100,
          dueDateType: "hidden",
          dueDateAfter: 0,
          dueDate: props.invoiceData.dueDate,
          notes: props.invoiceData.invoiceNotes || "",
          projectName: "",
          lateFee: props.invoiceData.lateFeeHandling
            ? props.invoiceData.lateFeeHandling.lateFeeAmount
              ? "fixed"
              : "percent"
            : "none",
          lateFeeAmount: props.invoiceData.lateFeeHandling
            ? props.invoiceData.lateFeeHandling.lateFeeAmount
              ? String(props.invoiceData.lateFeeHandling.lateFeeAmount)
              : String(props.invoiceData.lateFeeHandling.lateFeePercentage)
            : "",
          lateFeeAfter: props.invoiceData.lateFeeHandling
            ? generateLateFeeAfter(props.invoiceData.lateFeeHandling.frequency)
            : "7"
        }}
        onSave={values => {
          handleSubmit(values, props.invoiceData.status);
          trackInvoiceAction(
            "Save & Exit",
            props.match.params.invoiceId,
            props.invoiceData.status,
            {
              location: "Edit"
            }
          );
        }}
        onSend={values => {
          handleSubmit(values, IInvoiceStatus.Open);
          trackInvoiceAction(
            "Send",
            props.match.params.invoiceId,
            IInvoiceStatus.Open,
            {
              location: "Edit"
            }
          );
        }}
        onPreview={values => {
          dispatch(openInvoicePreview(values));
          trackInvoiceAction(
            "Preview",
            props.match.params.invoiceId,
            props.invoiceData.status,
            {
              location: "Edit"
            }
          );
        }}
      />
      <InvoicePreviewContainer />
    </EmptyLayout>
  ) : null;
};

const mapStateToProps = (state: GlobalStore, props: OwnProps) => {
  const invoiceData = getInvoiceDataById(state, props.match.params.invoiceId);
  let memberClientData;
  if (invoiceData?.memberClientId)
    memberClientData = getMemberClientDataById(
      state,
      invoiceData?.memberClientId
    );

  return {
    invoiceData,
    memberClientData
  };
};

export const InvoiceEditContainer = connect(mapStateToProps)(InvoiceEdit);

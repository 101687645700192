import paymentsService from "../../services/payments";
import { fetchInvoiceListActions } from "./invoicesActions";
import { AppThunk } from "../../types/store";
import moment from "moment";
import { IInvoiceStatus, InvoiceResponse } from "../../types/paymentsService";
import { getInvoiceTotalAmount } from "../../utils/invoiceHelper";

export const fetchInvoiceList = (): AppThunk<any> => async dispatch => {
  dispatch(fetchInvoiceListActions.request());

  try {
    const visibleInvoices = await paymentsService.getInvoices();

    const byId: {
      [id: string]: InvoiceResponse;
    } = {};

    visibleInvoices.forEach(invoice => {
      byId[invoice.invoiceId] = invoice;
    });

    const listIds = visibleInvoices.map(invoice => invoice.invoiceId);

    const paidInvoices = await paymentsService.getInvoices({
      updatedAt: {
        ">=": moment()
          .subtract(30, "days")
          .toDate()
      },
      status: IInvoiceStatus.Paid
    });

    const outstandingInvoices = await paymentsService.getInvoices({
      createdAt: {
        ">=": moment()
          .subtract(30, "days")
          .toDate()
      },
      status: {
        in: [IInvoiceStatus.Open, IInvoiceStatus.Overdue]
      }
    });

    const stats = {
      paid: paidInvoices.reduce(
        (total, invoice) => total + getInvoiceTotalAmount(invoice),
        0
      ),
      outstanding: outstandingInvoices.reduce(
        (total, invoice) => total + getInvoiceTotalAmount(invoice),
        0
      )
    };

    dispatch(fetchInvoiceListActions.success({ byId, listIds, stats }));
  } catch (error) {
    dispatch(fetchInvoiceListActions.failure(error));
  }
};

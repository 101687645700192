import React from "react";
import { WizardValues } from "../../types/wizardStep";
import { replaceVariables } from "../../utils/wizardStep";
import styles from "./styles.module.scss";

interface Props {
  value: string;
  formattedValues: WizardValues;
}

const Subheader: React.FC<Props> = ({ value, formattedValues }) => (
  <h3 className={styles.wizardSubHeader}>
    {replaceVariables(value, formattedValues)}
  </h3>
);

export default Subheader;

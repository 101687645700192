import { UserQuestionnaire } from "../../services/api";
import { GlobalStore } from "../../types/store";

export const getUserAuthorized = (state: GlobalStore) => state.user.authorized;

export const getUserId = (state: GlobalStore) => state.user.data.id;

export const getUser_id = (state: GlobalStore) => state.user.data._id;

export const getUserRoleId = (state: GlobalStore) => state.user.data.roleId;

export const getUserFields = (state: GlobalStore) => state.user.data.fields;

export const getUserName = (state: GlobalStore) =>
  `${state.user.data.fields.firstName} ${state.user.data.fields.lastName}`;

export const getUserEmail = (state: GlobalStore) =>
  state.user.data.fields.email;

export const getUserCompanyName = (state: GlobalStore) =>
  state.user.data.fields.companyName;

export const getUserQuestionnaire = (state: GlobalStore): UserQuestionnaire =>
  state.user.data.fields.questionnaire || [];

export const getUserBusinessAddress = (state: GlobalStore) => ({
  address:
    state.user.data.fields.businessAddress || state.user.data.fields.address,
  addressLine2:
    state.user.data.fields.businessAddressLine2 ||
    state.user.data.fields.addressLine2,
  city: state.user.data.fields.businessCity || state.user.data.fields.city,
  state: state.user.data.fields.businessState || state.user.data.fields.state,
  zipCode:
    state.user.data.fields.businessZipCode || state.user.data.fields.zipCode
});

import React from "react";
import classes from "./styles.module.scss";

interface ColComponent extends React.FC {}

interface RowComponent extends React.FC {
  Col: ColComponent;
}

const Row: RowComponent = ({ children }) => (
  <div className={classes.row}>{children}</div>
);

Row.Col = ({ children }) => <div className={classes.col}>{children}</div>;

export default Row;

import { GlobalStore } from "../../types/store";
import { META_REQUEST_STATUS } from "./constants";
import { fixKey } from "./utils";
import { MetaState } from "./types";

export const getMetaIsRequested = (
  state: GlobalStore,
  prefix: string,
  key?: string
): boolean => !!state.meta[prefix]?.[fixKey(key)];

export const getMetaIsNotRequested = (
  state: GlobalStore,
  prefix: string,
  key?: string
): boolean => !getMetaIsRequested(state, prefix, key);

export const getMetaIsLoading = (
  state: GlobalStore,
  prefix: string,
  key?: string
): boolean => state.meta[prefix]?.[fixKey(key)]?.status === META_REQUEST_STATUS;

export const getMetaIsNotLoading = (
  state: GlobalStore,
  prefix: string,
  key?: string
): boolean => !getMetaIsLoading(state, prefix, key);

export const getMetaError = (
  state: GlobalStore,
  prefix: string,
  key?: string
): any => state.meta[prefix]?.[fixKey(key)]?.error;

export const getMeta = (
  state: GlobalStore,
  prefix: string,
  key?: string
): MetaState => state.meta[prefix]?.[fixKey(key)];

export const getMetaIsReady = (
  state: GlobalStore,
  prefix: string,
  key?: string
): boolean => {
  const meta = getMeta(state, prefix, key);
  return meta?.status === "SUCCESS";
};

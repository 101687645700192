import { Field, FieldProps } from "formik";
import React from "react";
import DropDown, { DropDownProps } from ".";

interface Props extends Omit<DropDownProps, "value" | "error" | "onChange"> {
  name: string;
}

const FormikDropDownField: React.FC<Props> = ({ name, ...props }) => (
  <Field name={name}>
    {({ field, form }: FieldProps) => (
      <DropDown
        value={field.value}
        onChange={newValue => {
          form.setFieldValue(field.name, newValue);
        }}
        error={
          form.touched[name] && form.errors[name]
            ? (form.errors[name] as string)
            : undefined
        }
        {...props}
      />
    )}
  </Field>
);

export default FormikDropDownField;

import { Field, FieldProps, getIn } from "formik";
import React from "react";
import RadioField, { RadioFieldProps } from ".";

interface Props extends Omit<RadioFieldProps, "value" | "error" | "onChange"> {
  name: string;
}

const FormikRadioField: React.FC<Props> = ({ name, ...props }) => (
  <Field name={name}>
    {({ field, form }: FieldProps) => (
      <RadioField
        name={field.name}
        value={field.value}
        onChange={field.onChange}
        error={
          getIn(form.touched, name) && getIn(form.errors, name)
            ? getIn(form.errors, name)
            : undefined
        }
        {...props}
      />
    )}
  </Field>
);

export default FormikRadioField;

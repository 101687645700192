import { getUserProfile } from "../../services/api";
import { fetchUserActions } from "./userActions";
import { AppThunk } from "../../types/store";
import { identify } from "../../utils/analytics";
import { fetchInvoiceList } from "./fetchInvoiceList";
import { fetchMembership } from "./fetchMembership";
import { fetchMCCs } from "./fetchMCCs";
import { fetchPaymentsStatus } from "./fetchPaymentsStatus";

export const fetchUser = (): AppThunk<any> => async dispatch => {
  dispatch(fetchUserActions.request());

  try {
    const {
      id,
      _id,
      roleId,
      goalsSubmitted,
      ...fields
    } = await getUserProfile();

    window.userEmail = fields.email;

    identify(_id, { roleId, ...fields });

    dispatch(
      fetchUserActions.success({
        id,
        _id,
        roleId,
        goalsSubmitted,
        fields
      })
    );

    await dispatch(fetchMembership());
    await dispatch(fetchPaymentsStatus());
    await dispatch(fetchMCCs());
    await dispatch(fetchInvoiceList());
  } catch (error) {
    dispatch(fetchUserActions.failure(error));
  }
};

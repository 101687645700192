import React from "react";
import classes from "./styles.module.scss";
import classNames from "classnames";

interface Props {
  clickable?: boolean;
  hoverable?: boolean;
}

const TableContainer: React.FC<Props> = ({
  clickable,
  hoverable,
  children
}) => (
  <div
    className={classNames(classes.container, {
      [classes.clickable]: clickable,
      [classes.hoverable]: hoverable
    })}
  >
    {children}
  </div>
);

export default TableContainer;

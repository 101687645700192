import { createAction } from "../meta/types";
import { GoalCreationConfig } from "../../types/goals";
import {
  FETCH_GOAL_CREATION_CONFIG,
  FETCH_GOAL_CREATION_CONFIG_FAILURE,
  FETCH_GOAL_CREATION_CONFIG_REQUEST,
  FETCH_GOAL_CREATION_CONFIG_SUCCESS
} from "../constants";

export const fetchGoalCreationConfigActions = createAction(
  FETCH_GOAL_CREATION_CONFIG,
  FETCH_GOAL_CREATION_CONFIG_REQUEST,
  FETCH_GOAL_CREATION_CONFIG_SUCCESS,
  FETCH_GOAL_CREATION_CONFIG_FAILURE
)<
  undefined,
  {
    config: GoalCreationConfig;
  },
  Error
>();

import _ from "lodash";
import paymentsService from "../services/payments";

export const createOrUpdateMemberClient = async (
  initialMemberClientId: string,
  company: string,
  name: string,
  email: string,
  additionalEmails: string[]
): Promise<string> => {
  let memberClientId;

  if (initialMemberClientId) {
    const memberClient = await paymentsService.getMemberClient(
      initialMemberClientId
    );

    if (memberClient) {
      if (
        memberClient.company === company &&
        memberClient.name === name &&
        memberClient.emailTo === email
      ) {
        // If client's name, company and emailTo matches to form values, no need to create a new one
        memberClientId = initialMemberClientId;

        if (
          !_.isEqual(
            additionalEmails.sort(),
            memberClient.emailCC ? memberClient.emailCC.sort() : []
          )
        ) {
          // If client's emailCC is not equal with form values, updating existing client
          await paymentsService.updateMemberClient(memberClientId, {
            emailCC: additionalEmails
          });
        }
      }
    }
  }

  if (!memberClientId) {
    // If there is no memberClientId found, creating a new one
    const createdMemberClient = await paymentsService.createMemberClient({
      company: company,
      name: name,
      emailTo: email,
      emailCC: additionalEmails
    });

    memberClientId = createdMemberClient.memberClientId;
  }

  return memberClientId;
};

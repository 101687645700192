import {
  UPDATE_INVOICE,
  UPDATE_INVOICE_FAILURE,
  UPDATE_INVOICE_REQUEST,
  UPDATE_INVOICE_SUCCESS
} from "../constants";
import paymentsService from "../../services/payments";
import { createAction } from "../meta/types";
import { InvoiceUpdateData } from "../../types/paymentsService";
import { AppThunk } from "../../types/store";

export const updateInvoiceActions = createAction(
  UPDATE_INVOICE,
  UPDATE_INVOICE_REQUEST,
  UPDATE_INVOICE_SUCCESS,
  UPDATE_INVOICE_FAILURE
)<
  { invoiceId: string; data: InvoiceUpdateData },
  { invoiceId: string },
  Error
>();

export const updateInvoice = (
  invoiceId: string,
  data: InvoiceUpdateData
): AppThunk<any> => async dispatch => {
  dispatch(updateInvoiceActions.request({ invoiceId, data }, invoiceId));
  try {
    await paymentsService.updateInvoice(invoiceId, data);

    dispatch(updateInvoiceActions.success({ invoiceId }, invoiceId));
  } catch (error) {
    dispatch(updateInvoiceActions.failure(error, invoiceId));
  }
};

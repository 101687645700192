import React from "react";
import { connect } from "react-redux";
import { getInvoicesListIds } from "../../../store/selectors/invoices";
import { GlobalStore } from "../../../types/store";
import TableContainer from "../TableContainer";
import { InvoicesListDesktopItemContainer } from "./Item";

type MapStateToProps = ReturnType<typeof mapStateToProps>;
type Props = MapStateToProps;

const InvoicesListDesktop: React.FC<Props> = props => (
  <TableContainer clickable hoverable>
    <table>
      <thead>
        <tr>
          <td>Status</td>
          <td>Due date</td>
          <td>Company</td>
          <td>Client</td>
          <td>Type</td>
          <td align="right">Amount</td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        {props.listIds.map(invoiceId => (
          <InvoicesListDesktopItemContainer
            key={invoiceId}
            invoiceId={invoiceId}
          />
        ))}
      </tbody>
    </table>
  </TableContainer>
);

const mapStateToProps = (state: GlobalStore) => ({
  listIds: getInvoicesListIds(state)
});

export const InvoicesListDesktopContainer = connect(mapStateToProps)(
  InvoicesListDesktop
);

import { FormikContext } from "formik";
import React, { useEffect } from "react";

interface Props {
  context: FormikContext<any>;
}

export const FormikScrollToError: React.FC<Props> = ({ context }) => {
  useEffect(() => {
    const errorKeys = Object.keys(context.errors);
    if (errorKeys.length > 0 && context.isSubmitting) {
      const selector = `[name="${errorKeys[0]}"]`;
      const input = document.querySelector(selector) as HTMLElement;
      if (input) {
        input.focus();
      }
    }
  }, [context.isSubmitting, context.errors]);

  return null;
};

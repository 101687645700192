import { RefObject, useEffect, useState } from "react";
import { useScroll } from "./useScroll";
import { useWindowSize } from "./useWindowSize";

export function useDropdownPosition(
  ref: RefObject<HTMLElement>,
  visible: boolean,
  offset: number
) {
  const windowSize = useWindowSize();
  const { scrollY } = useScroll();
  const [position, setPosition] = useState<"top" | "bottom">("bottom");

  useEffect(() => {
    if (ref.current) {
      const dropdownRect = ref.current.getBoundingClientRect();

      if (
        position === "bottom" &&
        dropdownRect.top - dropdownRect.height - offset > 0 &&
        windowSize.height < dropdownRect.bottom
      ) {
        setPosition("top");
      } else if (
        position === "top" &&
        windowSize.height > dropdownRect.height + dropdownRect.bottom + offset
      ) {
        setPosition("bottom");
      }
    }
  }, [windowSize, visible, position, scrollY, ref, offset]);

  return { position };
}

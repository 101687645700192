import { SwipeableDrawer } from "@material-ui/core";
import { MenuOutlined } from "@material-ui/icons";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { signOut } from "../../store/actions/signOut";
import messageCheck from "../../assets/icons/message_check_24.svg";
import logoIcon from "../../assets/images/logo-icon.svg";
import logo from "../../assets/images/logo.svg";
import {
  getUserAuthorized,
  getUserName,
  getUserRoleId
} from "../../store/selectors/user";
import { GlobalStore } from "../../types/store";
import URL from "../../types/url";
import ProfileDropdown from "../ProfileDropdown";
import ProfilePhoto from "../ProfilePhoto";
import { Mobile, TabletDesktop } from "../Responsive";
import SubscribeModal from "../SubscribeModal";
import Actions from "./Actions";
import Main from "./Main";
import PageHeader from "./PageHeader";
import Pill from "./Pill";
import StepsProgress from "./StepsProgress";
import classes from "./styles.module.scss";
import Tabs from "./Tabs";

type OwnProps = {
  title: string;
  simpleHeader?: boolean;
  currentPage?: string;
  wide?: boolean;
  disableHeaderAnimation?: boolean;
};

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StateProps;

interface MainLayoutComponent extends React.FC<Props> {
  Main: typeof Main;
  PageHeader: typeof PageHeader;
  Pill: typeof Pill;
  Tabs: typeof Tabs;
  StepsProgress: typeof StepsProgress;
  Actions: typeof Actions;
}

const Unauthorized: React.FC<{
  currentPage?: string;
}> = ({ currentPage }) => {
  const [drawerVisible, setDrawerVisible] = useState(false);

  return (
    <>
      <header className={classnames(classes.header, classes.fullHeader)}>
        <Mobile>
          <MenuOutlined
            htmlColor="#ABAEB5"
            onClick={() => {
              setDrawerVisible(true);
            }}
          />

          <div className={classes.logo}>
            <a href={URL.marketingDomain}>
              <img src={logoIcon} alt="Wingspan Logo" />
            </a>
          </div>

          <div style={{ width: 24 }} />
        </Mobile>

        <TabletDesktop>
          <a
            href={URL.marketingDomain}
            className={classnames(classes.logo, classes.logoNoHover)}
          >
            <img src={logo} alt="Wingspan logo" />
          </a>

          <div>
            <Link to="/sign-in">Sign In</Link>

            <Link to="/register" className={classes.secondary}>
              Sign Up
            </Link>
          </div>
        </TabletDesktop>
      </header>
      <SwipeableDrawer
        open={drawerVisible}
        onClose={() => {
          setDrawerVisible(false);
        }}
        onOpen={() => {}}
      >
        <div className={classes.drawer}>
          <div className={classes.drawerHeader}>
            <img
              src={logoIcon}
              alt="Wingspan Logo"
              className={classes.logoIcon}
            />
          </div>

          <div className={classes.drawerContent}>
            <div className={classes.drawerNav}>
              <ul>
                <li>
                  <a
                    href="{URL.marketingDomain}"
                    className={classnames({
                      [classes.selected]: currentPage === "landing"
                    })}
                  >
                    Home
                  </a>
                </li>
                <li>
                  <Link
                    to="/sign-in"
                    className={classnames({
                      [classes.selected]: currentPage === "login"
                    })}
                  >
                    Sign In
                  </Link>
                </li>
                <li>
                  <Link
                    to="/register"
                    className={classnames(classes.secondary, {
                      [classes.selected]: currentPage === "register"
                    })}
                  >
                    Sign Up
                  </Link>
                </li>
              </ul>
            </div>

            <div className={classes.drawerFooter}>
              <a className={classes.linkSmall} href={URL.termsOfService}>
                Terms &amp; Conditions
              </a>
              <a className={classes.linkSmall} href={URL.privacyPolicy}>
                Privacy Policy
              </a>
              <br />
              <span
                className={classnames(classes.linkSmall, classes.companyName)}
              >
                &copy;Wingspan Networks, Inc.
              </span>
            </div>
          </div>
        </div>
      </SwipeableDrawer>
    </>
  );
};

const SimpleHeader: React.FC<{}> = () => (
  <header className={classnames(classes.header, classes.simpleHeader)}>
    <div className={classes.logo}>
      <img src={logo} alt="Wingspan Logo" />
    </div>
  </header>
);

const mapStateToAuthorizedProps = (state: GlobalStore) => ({
  userRoleId: getUserRoleId(state),
  userName: getUserName(state)
});

type AuthorizedProps = ReturnType<typeof mapStateToAuthorizedProps> & {
  scrolledTop: boolean;
  currentPage?: string;
};

const _Authorized: React.FC<AuthorizedProps> = ({
  scrolledTop,
  currentPage,
  userRoleId,
  userName
}) => {
  const dispatch = useDispatch();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const waitlisted = userRoleId === 2;

  return (
    <>
      <div>
        <header
          className={classnames({
            [classes.header]: true,
            [classes.fullHeader]: true,
            [classes.highlightHeader]: !scrolledTop
          })}
        >
          <Mobile>
            <MenuOutlined
              htmlColor="#ABAEB5"
              onClick={() => {
                setDrawerVisible(true);
              }}
            />

            <div className={classes.logo}>
              <Link to="/dashboard">
                <img src={logoIcon} alt="Wingspan Logo" />
              </Link>
            </div>

            {!waitlisted ? (
              <Link to="/goals">
                <img
                  src={messageCheck}
                  alt="Goals"
                  style={{ verticalAlign: "bottom" }}
                />
              </Link>
            ) : (
              <div style={{ width: 24 }} />
            )}
          </Mobile>

          <TabletDesktop>
            <Link
              to="/dashboard"
              className={classnames(classes.logo, classes.logoNoHover)}
            >
              <img src={logo} alt="Wingspan logo" />
            </Link>

            {!waitlisted && (
              <div>
                <Link
                  to="/dashboard"
                  className={classnames({
                    [classes.selected]: currentPage === "dashboard"
                  })}
                >
                  Home
                </Link>

                <Link
                  to="/team"
                  className={classnames({
                    [classes.selected]: currentPage === "team"
                  })}
                >
                  Your Team
                </Link>

                <Link
                  to="/goals"
                  className={classnames({
                    [classes.selected]: currentPage === "goals"
                  })}
                >
                  Goals
                </Link>

                <Link to="/invoices">Invoices</Link>
              </div>
            )}

            <ProfileDropdown name={userName}>
              <ProfileDropdown.Item to="/profile">Profile</ProfileDropdown.Item>
              <ProfileDropdown.Item
                onClick={() => {
                  dispatch(signOut());
                }}
              >
                Sign Out
              </ProfileDropdown.Item>
            </ProfileDropdown>
          </TabletDesktop>
        </header>
      </div>
      <SwipeableDrawer
        open={drawerVisible}
        onClose={() => {
          setDrawerVisible(false);
        }}
        onOpen={() => {}}
      >
        <div className={classes.drawer}>
          <div className={classes.drawerHeader}>
            {/*one link*/}
            <Link to="/profile">
              <ProfilePhoto className={classes.avatar} name={userName} />
            </Link>
            <div className={classes.profileName}>
              <Link to="/profile">{userName}</Link>
            </div>
          </div>

          <div className={classes.drawerContent}>
            <div className={classes.drawerNav}>
              {waitlisted ? (
                <ul>
                  <li>
                    <Link
                      to="#"
                      className={classes.linkSmall}
                      onClick={() => {
                        dispatch(signOut());
                      }}
                    >
                      Sign Out
                    </Link>
                  </li>
                </ul>
              ) : (
                <ul>
                  <li>
                    <Link
                      to="/dashboard"
                      className={classnames({
                        [classes.selected]: currentPage === "dashboard"
                      })}
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/goals"
                      className={classnames({
                        [classes.selected]: currentPage === "goals"
                      })}
                    >
                      Goals
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/profile"
                      className={classnames({
                        [classes.selected]: currentPage === "profile"
                      })}
                    >
                      Profile
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/team"
                      className={classnames({
                        [classes.selected]: currentPage === "team"
                      })}
                    >
                      Your Team
                    </Link>
                  </li>
                  <li>
                    <Link to="/invoices">Invoices</Link>
                  </li>
                </ul>
              )}
            </div>

            <div className={classes.drawerFooter}>
              {!waitlisted && (
                <button
                  className={classes.linkSmall}
                  onClick={() => {
                    dispatch(signOut());
                  }}
                >
                  Sign Out
                </button>
              )}
              <br />
              <a className={classes.linkSmall} href={URL.termsOfService}>
                Terms &amp; Conditions
              </a>
              <a className={classes.linkSmall} href={URL.privacyPolicy}>
                Privacy Policy
              </a>
              <br />
              <span
                className={classnames(classes.linkSmall, classes.companyName)}
              >
                &copy;Wingspan Networks, Inc.
              </span>
            </div>
          </div>
        </div>
      </SwipeableDrawer>
    </>
  );
};

const Authorized = connect(mapStateToAuthorizedProps)(_Authorized);

const MainLayout: MainLayoutComponent = ({
  title,
  children,
  userAthorized,
  simpleHeader,
  currentPage,
  wide,
  disableHeaderAnimation
}) => {
  useEffect(() => {
    document.title = title + " - Wingspan Collective";
    window.analytics.page();
  }, [title]);

  const [scrolledTop, setScrolledTop] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset <= 5) {
        setScrolledTop(!disableHeaderAnimation);
      } else {
        setScrolledTop(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    // getting the initial y position value
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [disableHeaderAnimation]);

  return (
    <div className={classnames({ [classes.wide]: wide })}>
      {!userAthorized ? (
        <Unauthorized currentPage={currentPage} />
      ) : simpleHeader ? (
        <SimpleHeader />
      ) : (
        <Authorized currentPage={currentPage} scrolledTop={scrolledTop} />
      )}

      {children}

      <SubscribeModal />
    </div>
  );
};

MainLayout.Main = Main;
MainLayout.PageHeader = PageHeader;
MainLayout.Pill = Pill;
MainLayout.Tabs = Tabs;
MainLayout.StepsProgress = StepsProgress;
MainLayout.Actions = Actions;

const mapStateToProps = (state: GlobalStore) => ({
  userAthorized: getUserAuthorized(state)
});

export default connect(mapStateToProps)(MainLayout);

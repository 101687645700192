import { UserFields } from "../../services/api";
import { createAction } from "../meta/types";
import {
  FETCH_USER,
  FETCH_USER_FAILURE,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_UPDATE,
  FETCH_USER_UPDATE_FAILURE,
  FETCH_USER_UPDATE_REQUEST,
  FETCH_USER_UPDATE_SUCCESS,
  SIGN_IN,
  SIGN_IN_FAILURE,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_OUT,
  SIGN_OUT_FAILURE,
  SIGN_OUT_REQUEST,
  SIGN_OUT_SUCCESS,
  SIGN_UP,
  SIGN_UP_FAILURE,
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS
} from "../constants";

export const fetchUserActions = createAction(
  FETCH_USER,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE
)<
  undefined,
  {
    id: number;
    _id: string;
    roleId: number;
    goalsSubmitted: string[];
    fields: UserFields;
  },
  Error
>();

export const fetchUserUpdateActions = createAction(
  FETCH_USER_UPDATE,
  FETCH_USER_UPDATE_REQUEST,
  FETCH_USER_UPDATE_SUCCESS,
  FETCH_USER_UPDATE_FAILURE
)<undefined, { data: UserFields }, Error>();

export const signUpActions = createAction(
  SIGN_UP,
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE
)<undefined, undefined, string>();

export const signInActions = createAction(
  SIGN_IN,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE
)<undefined, undefined, string>();

export const signOutActions = createAction(
  SIGN_OUT,
  SIGN_OUT_REQUEST,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_FAILURE
)<undefined, undefined, string>();

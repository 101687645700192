import React from "react";
import { Link } from "react-router-dom";
import AGENTS from "../../types/team";
import Card from "../Card";
import ProfilePhoto from "../ProfilePhoto";
import classes from "./styles.module.scss";

interface Props {
  title: string;
  subtitle: string;
  link: string;
}

const TeamCTAWidget: React.FC<Props> = ({ title, subtitle, link }) => (
  <Card className={classes.container}>
    <Card.Main>
      <Link to={link}>
        <div className={classes.link}>
          <div>
            <h3>{title}</h3>
            <p>{subtitle}</p>
          </div>
          <div className={classes.profilePhotos}>
            {AGENTS.map((agent: any) => (
              <ProfilePhoto
                name={agent.name}
                key={agent.name}
                url={agent.avatarUrl}
                className={classes.profilePhoto}
              />
            ))}
          </div>
        </div>
      </Link>
    </Card.Main>
  </Card>
);

export default TeamCTAWidget;

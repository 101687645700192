function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from "react";
export default (({
  styles = {},
  ...props
}) => React.createElement("svg", _extends({
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  xmlns: "http://www.w3.org/2000/svg",
  xmlnsXlink: "http://www.w3.org/1999/xlink"
}, props), React.createElement("defs", null, React.createElement("path", {
  d: "M18 13h-5v5a1 1 0 01-2 0v-5H6a1 1 0 010-2h5V6a1 1 0 012 0v5h5a1 1 0 010 2z",
  id: "a"
})), React.createElement("g", {
  fill: "none",
  fillRule: "evenodd"
}, React.createElement("path", {
  d: "M0 0h24v24H0z"
}), React.createElement("mask", {
  id: "b",
  fill: "#fff"
}, React.createElement("use", {
  xlinkHref: "#a"
})), React.createElement("use", {
  fill: "#6B727F",
  fillRule: "nonzero",
  xlinkHref: "#a"
}), React.createElement("g", {
  mask: "url(#b)"
}, React.createElement("path", {
  fill: "#FFF",
  d: "M0 0h24v24H0z"
}), React.createElement("path", {
  fill: "#6B727F",
  d: "M0 0h24v24H0z"
})))));
import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import debounce from "lodash/debounce";

type AutoPositionProps = (
  | { topClassName: string; bottomClassName?: string }
  | { topClassName?: string; bottomClassName: string }
) & {
  as?: string;
  className?: string;
  offset?: number;
} & React.HTMLAttributes<HTMLElement>;

export const AutoPosition: React.FC<AutoPositionProps> = ({
  children,
  offset = 0,
  as = "div",
  topClassName = "",
  bottomClassName = "",
  className,
  ...rest
}) => {
  const ref = useRef<HTMLElement>(null);
  const [status, setStatus] = useState<"top" | "bottom" | null>(null);

  useEffect(() => {
    if (ref.current) {
      const dropdownRect = ref.current.getBoundingClientRect();

      if (status === null) {
        if (window.innerHeight > dropdownRect.bottom) {
          setStatus("bottom");
        } else if (dropdownRect.top - offset / 2 - offset >= 0) {
          setStatus("top");
        } else if (
          window.innerHeight - dropdownRect.bottom >=
          dropdownRect.top - offset / 2 - offset
        ) {
          setStatus("bottom");
        } else {
          setStatus("top");
        }
      }
    }
  }, [ref.current, offset, status]);

  useEffect(() => {
    const debouncedListener = debounce(() => {
      if (ref.current) {
        const dropdownRect = ref.current.getBoundingClientRect();

        if (status === "bottom") {
          if (window.innerHeight > dropdownRect.bottom) {
            setStatus("bottom");
          } else if (dropdownRect.top - offset / 2 - offset >= 0) {
            setStatus("top");
          } else if (
            window.innerHeight - dropdownRect.bottom >=
            dropdownRect.top - offset / 2 - offset
          ) {
            setStatus("bottom");
          } else {
            setStatus("top");
          }
        } else {
          if (window.innerHeight > dropdownRect.bottom + offset + offset / 2) {
            setStatus("bottom");
          } else if (dropdownRect.top > 0) {
            setStatus("top");
          } else {
            setStatus("bottom");
          }
        }
      }
    }, 500);

    window.addEventListener("scroll", debouncedListener);

    return () => {
      window.removeEventListener("scroll", debouncedListener);
    };
  }, [ref.current]);

  return React.createElement(as, {
    children,
    ref,
    className: classNames(className, {
      [topClassName]: status === "top",
      [bottomClassName]: status === "bottom" || status === null,
      [styles.hidden]: status !== "top" && status !== "bottom"
    }),
    ...rest
  });
};

import { InvoiceFormValues } from "../../components/payments/InvoiceForm";
import { OPEN_INVOICE_PREVIEW } from "../constants";

export const openInvoicePreview = (data: InvoiceFormValues) =>
  ({
    type: OPEN_INVOICE_PREVIEW,
    payload: {
      data
    }
  } as const);

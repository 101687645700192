import { formatMoney } from "accounting";
import moment from "moment";
import React from "react";
import classes from "./styles.module.scss";

interface Props {
  userCompany?: string;
  clientCompany: string;
  invoiceName: string;
  createdDate: Date;
  dueDateTypeOnReceipt?: boolean;
  dueDate: Date;
  clientName: string;
  clientEmail: string;
  additionalEmails?: string[];
  lineItems: {
    description: string;
    totalCost: number;
  }[];
  lateFees?: string[];
  notes?: string;
  projectName?: string;
}

export const InvoiceDetailsInfoSection: React.FC<Props> = props => {
  const sentDueText = `Sent ${moment
    .utc(props.createdDate)
    .format("MMMM D, YYYY")}, ${
    props.dueDateTypeOnReceipt
      ? "due upon receipt"
      : `due ${moment.utc(props.dueDate).format("MMMM D, YYYY")}`
  }`;

  return (
    <div className={classes.container}>
      <section className={classes.section}>
        <h2>Invoice details</h2>
        <h3>
          {props.userCompany ? `${props.userCompany} + ` : null}
          {props.clientCompany}
        </h3>
        <p>
          {props.invoiceName} <br />
          {sentDueText}
        </p>

        <h3>Bill to</h3>
        <p>
          {props.clientName} <br />
          {props.clientEmail}
        </p>

        {props.additionalEmails && props.additionalEmails.length > 0 && (
          <>
            <h3>Additional recipients</h3>
            <p>{props.additionalEmails.join(", ")}</p>
          </>
        )}
      </section>

      {props.lineItems.length > 0 && (
        <section className={classes.section}>
          {props.lineItems.map((lineItem, index) => (
            <div key={index} className={classes.lineItem}>
              <div className={classes.description}>{lineItem.description}</div>
              <div className={classes.totalCost}>
                {formatMoney(lineItem.totalCost)}
              </div>
            </div>
          ))}
        </section>
      )}

      {props.lateFees && props.lateFees.length > 0 && (
        <section className={classes.section}>
          <h3>Late fees</h3>
          {props.lateFees.map((lateFee, index) => (
            <p key={index}>{lateFee}</p>
          ))}
        </section>
      )}

      {props.notes && (
        <section className={classes.section}>{props.notes}</section>
      )}
    </div>
  );
};

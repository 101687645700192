import React from "react";
import { connect } from "react-redux";
import heroDashboard from "../assets/images/dashboard-hero.png";
import Columns from "../components/Columns";
import GetStartedWidget from "../components/GetStartedWidget";
import MainLayout from "../components/MainLayout";
import RecentActivityWidget from "../components/RecentActivityWidget";
import { Mobile, TabletDesktop } from "../components/Responsive";
import TeamCTAWidget from "../components/TeamCTAWidget";
import { getUserFields } from "../store/selectors/user";
import { GlobalStore } from "../types/store";
import { getGoalCreationConfig } from "../store/selectors/goals";

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = StateProps;

const Dashboard: React.FC<Props> = ({ config, userFields }) => (
  <MainLayout title={config.header} currentPage="dashboard" wide>
    <MainLayout.PageHeader
      backgroundImage={heroDashboard}
      backgroundColor="#DBF0FF"
    >
      <h1 className="hero">Hi {userFields.firstName},</h1>
      <h3>{config.header}</h3>
    </MainLayout.PageHeader>

    <MainLayout.Main>
      <Mobile>
        <TeamCTAWidget
          title="Ask Us Anything"
          subtitle="Your team is here to help"
          link="/create-goal"
        />
        <GetStartedWidget />
        {/*<CreateNewGoalWidget />*/}
      </Mobile>

      <TabletDesktop>
        <Columns>
          <Columns.Column>
            <GetStartedWidget />
            {/*<CreateNewGoalWidget />*/}
          </Columns.Column>

          <Columns.Column>
            <TeamCTAWidget
              title="Ask Us Anything"
              subtitle="Your team is here to help"
              link="/create-goal"
            />
            <RecentActivityWidget />
          </Columns.Column>
        </Columns>
      </TabletDesktop>
    </MainLayout.Main>
  </MainLayout>
);

const mapStateToProps = (state: GlobalStore) => ({
  config: getGoalCreationConfig(state),
  userFields: getUserFields(state)
});

export default connect(mapStateToProps)(Dashboard);

import React from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import classes from "./styles.module.scss";

interface Props {
  percentage: number;
}

const styles = buildStyles({
  pathColor: "#f5554b",
  trailColor: "rgba(0, 0, 0, 0.15)",
  textColor: "#282d37"
});

const CircularProgress: React.FC<Props> = ({ percentage }) => (
  <CircularProgressbar
    className={classes.container}
    value={percentage}
    strokeWidth={4}
    text={`${percentage}%`}
    styles={{
      ...styles,
      root: {
        ...styles.root,
        width: 40,
        height: 40
      },
      text: {
        ...styles.text,
        fontSize: 26,
        fontWeight: 500
      }
    }}
  />
);

export default CircularProgress;

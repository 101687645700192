import moment from "moment";
import React from "react";
import {
  WizardFile,
  WizardOnChange,
  WizardStepOption,
  WizardValues
} from "../../types/wizardStep";
import CheckBoxField from "../CheckBoxField";
import DateField from "../DateField";
import FileUploader from "../FileUploader";
import RadioField from "../RadioField";
import SelectSlider from "../SelectSlider";
import TextField from "../TextField";

interface Props {
  type:
    | "text"
    | "number"
    | "currency"
    | "email"
    | "phone"
    | "date"
    | "select"
    | "selectSlider"
    | "files"
    | "radio"
    | "checkbox"
    | undefined;
  name: string;
  label?: string;
  mandatory?: boolean;
  options?: WizardStepOption[];
  values: WizardValues;
  onChange: WizardOnChange;
  files?: WizardFile[];
  onFilesChange?: (files: WizardFile[]) => void;
}

const Field: React.FC<Props> = ({
  type,
  label,
  name,
  mandatory,
  values,
  onChange,
  options,
  files,
  onFilesChange
}) => {
  if (
    !type ||
    type === "text" ||
    type === "number" ||
    type === "email" ||
    type === "phone"
  )
    return (
      <TextField
        type={type}
        name={name}
        value={values[name] ? values[name] : ""}
        onChange={({ target: { value } }) => {
          onChange(name, value);
        }}
        label={label || ""}
        required={mandatory}
      />
    );
  else if (type === "currency") {
    return (
      <TextField
        name={name}
        value={values[name] ? values[name] : ""}
        onChange={({ target: { value } }) => {
          onChange(name, value);
        }}
        label={label || ""}
        required={mandatory}
        formatOptions={{
          prefix: "$",
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          rawValueTrimPrefix: true,
          noImmediatePrefix: true
        }}
      />
    );
  } else if (type === "files") {
    return (
      <FileUploader
        files={files || []}
        onChange={files => {
          if (onFilesChange) onFilesChange(files);
        }}
      />
    );
  } else if (type === "selectSlider" && options) {
    return (
      <SelectSlider
        options={options}
        value={values[name] ? values[name] : 1}
        onChange={value => {
          const selectedOption = options.find(
            option => Number(option.value) === value
          );
          const formattedValue = selectedOption
            ? selectedOption.label
            : undefined;

          onChange(name, value, formattedValue);
        }}
      />
    );
  } else if (type === "date") {
    return (
      <DateField
        label={label || ""}
        name={name}
        value={values[name]}
        onChange={value => {
          if (value) {
            const valueMoment = moment.utc(value);
            onChange(name, value, valueMoment.format("MM/DD/YY"));
          } else {
            onChange(name, null, null);
          }
        }}
      />
    );
  } else if (type === "radio" && options) {
    return (
      <RadioField
        name={name}
        label={label}
        options={options.map(option => ({
          ...option,
          value: String(option.value)
        }))}
        value={values[name] ? values[name] : ""}
        onChange={({ target: { value } }) => {
          const selectedOption = options.find(option => option.value === value);
          const formattedValue = selectedOption
            ? selectedOption.label
            : undefined;

          onChange(name, value, formattedValue);
        }}
        required={mandatory}
      />
    );
  } else if (type === "checkbox" && options) {
    return (
      <CheckBoxField
        label={label}
        options={options}
        value={values[name] ? values[name] : []}
        onChange={value => {
          const selectedOptions = options.filter(option =>
            value.includes(option.value)
          );

          const formattedValue =
            selectedOptions.length > 0
              ? selectedOptions.map(option => option.label).join(", ")
              : undefined;

          onChange(name, value, formattedValue);
        }}
        required={mandatory}
      />
    );
  } else return null;
};

export default Field;

import React from "react";
import documentIcon from "../../../assets/icons/onboarding/document.svg";
import healthIcon from "../../../assets/icons/onboarding/health.svg";
import personIcon from "../../../assets/icons/onboarding/person.svg";
import screenIcon from "../../../assets/icons/onboarding/screen.svg";
import { Mobile, TabletDesktop } from "../../Responsive";
import Row from "../../Row";
import Slider from "../../Slider";
import Item from "./Item";
import classes from "./styles.module.scss";

const Benefits: React.FC = () => (
  <div className={classes.container}>
    <Mobile>
      <Slider
        dots
        arrows={false}
        slidesToShow={1}
        centerMode
        swipeToSlide
        infinite={false}
      >
        <Item
          icon={personIcon}
          text="A dedicated team of experts on-call to help with any issue"
        />
        <Item
          icon={screenIcon}
          text="Manage revenue with tools for business deduction and tax withholding."
        />
        <Item
          icon={documentIcon}
          text="LLC incorporation and S Corp election to minimize taxes."
        />
        <Item
          icon={healthIcon}
          text="Access to premium health, retirement, and other benefits."
        />
      </Slider>
    </Mobile>
    <TabletDesktop>
      <Row>
        <Row.Col>
          <Item
            icon={personIcon}
            text="A dedicated team of experts on-call to help with any issue"
          />
        </Row.Col>
        <Row.Col>
          <Item
            icon={screenIcon}
            text="Manage revenue with tools for business deduction and tax withholding."
          />
        </Row.Col>
        <Row.Col>
          <Item
            icon={documentIcon}
            text="LLC incorporation and S Corp election to minimize taxes."
          />
        </Row.Col>
        <Row.Col>
          <Item
            icon={healthIcon}
            text="Access to premium health, retirement, and other benefits."
          />
        </Row.Col>
      </Row>
    </TabletDesktop>
  </div>
);

export default Benefits;

import { combineReducers, Reducer } from "redux";
import { InferValueTypes } from "../../types/utils";
import {
  fetchMCCsActions,
  fetchPaymentsStatusActions
} from "../actions/paymentsActions";
import { invoices } from "./invoices";
import { memberClients } from "./memberClients";
import { PaymentsDataStore } from "../../types/store";
import {
  FETCH_MCCS_SUCCESS,
  FETCH_PAYMENTS_STATUS_SUCCESS
} from "../constants";

export type InvoicesActions =
  | ReturnType<InferValueTypes<typeof fetchPaymentsStatusActions>>
  | ReturnType<InferValueTypes<typeof fetchMCCsActions>>;

export const getDefaultPaymentsStore: () => PaymentsDataStore = () => ({
  status: {
    enabled: false
  },
  mccs: []
});

const data: Reducer<PaymentsDataStore, InvoicesActions> = (
  state = getDefaultPaymentsStore(),
  action
) => {
  switch (action.type) {
    case FETCH_PAYMENTS_STATUS_SUCCESS:
      return {
        ...state,
        status: action.payload.status
      };

    case FETCH_MCCS_SUCCESS:
      return {
        ...state,
        mccs: action.payload.mccs
      };

    default: {
      return state;
    }
  }
};

export const payments = combineReducers({
  data,
  invoices,
  memberClients
});

import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Redirect, RouteComponentProps, Switch } from "react-router-dom";
import { fetchInvoiceList } from "../../store/actions/fetchInvoiceList";
import { fetchMemberClientList } from "../../store/actions/fetchMemberClientList";
import facePhoto from "../../assets/images/jessica-lavender.png";
import { CenteredSpinner } from "../../components/CenteredSpinner";
import Button from "../../components/payments/Button";
import Header from "../../components/payments/Header";
import Layout from "../../components/payments/Layout";
import PrivateRoute from "../../components/PrivateRoute";
import { getInvoicesListIds } from "../../store/selectors/invoices";
import { getMemberClientsListMeta } from "../../store/selectors/memberClients";
import { getMetaError, getMetaIsLoading } from "../../store/meta/selectors";
import { getPaymentsStatus } from "../../store/selectors/payments";
import { getUserFields } from "../../store/selectors/user";
import { GlobalStore } from "../../types/store";
import InvoicesCreate from "./Create";
import { InvoiceEditContainer } from "./Edit";
import { InvoicesList } from "./List";
import { FETCH_INVOICE_LIST } from "../../store/constants";
import { track } from "../../utils/analytics";

type MapStateToProps = ReturnType<typeof mapStateToProps>;
type RouterProps = RouteComponentProps;

type ListProps = RouterProps & MapStateToProps;

const InvoicesRoot: React.FC<ListProps> = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchInvoiceList());
    dispatch(fetchMemberClientList());
  }, [dispatch]);

  if (!props.paymentsEnabled) {
    return <Redirect to="/invoices/set-up" />;
  } else if (props.loading) {
    return (
      <Layout title="Invoices">
        <CenteredSpinner />
      </Layout>
    );
  } else if (props.error) {
    return (
      <Layout title="Invoices">
        <p style={{ color: "red" }}>Something went wrong</p>
      </Layout>
    );
  } else if (
    props.listIds.length === 0 &&
    window.location.pathname !== "/invoices/create"
  ) {
    return (
      <Layout title="Let’s get invoicing">
        <Header
          title={`Let’s get invoicing, ${props.userFields.firstName}.`}
          description="Once you start sending invoices, this will be your one-stop shop for managing clients, payments, projects, and more."
          face={facePhoto}
          centered
        />
        <div style={{ textAlign: "center" }}>
          <Button
            fullWidthOnMobile
            onClick={() => {
              track("Invoice", {
                action: "Click Create Invoice"
              });
              props.history.push("/invoices/create");
            }}
          >
            Create Invoice
          </Button>
        </div>
      </Layout>
    );
  } else {
    return (
      <Switch>
        <PrivateRoute
          path="/invoices/create"
          exact
          component={InvoicesCreate}
        />
        <PrivateRoute
          path="/invoices/:invoiceId/edit"
          exact
          component={InvoiceEditContainer}
        />
        <PrivateRoute path="/invoices" component={InvoicesList} />
      </Switch>
    );
  }
};

const mapStateToProps = (state: GlobalStore) => ({
  paymentsEnabled: getPaymentsStatus(state),
  userFields: getUserFields(state),
  listIds: getInvoicesListIds(state),
  loading:
    getMetaIsLoading(state, FETCH_INVOICE_LIST) ||
    getMemberClientsListMeta(state).loading,
  error:
    getMetaError(state, FETCH_INVOICE_LIST) ||
    getMemberClientsListMeta(state).error
});

export const InvoicesRootContainer = connect(mapStateToProps)(InvoicesRoot);

import React from "react";
import iconClock from "../../assets/icons/clock_summary_24.svg";
import AssetLink from "./AssetLink";
import classes from "./SummaryReply.module.scss";

interface Props {
  category: string;
  subject: string;
  summary: string;
  files: [any];
}

const SummaryReply: React.FC<Props> = ({
  category,
  subject,
  summary,
  files
}) => {
  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <img src={iconClock} alt="summary" className={classes.iconClock} />
        <div className={classes.textContainer}>
          <div className={classes.subject}>{subject}</div>
          <div className={classes.category}>{category}</div>
          <div className={classes.summary}>{summary}</div>
          {files.map((file, index) => {
            return (
              <AssetLink
                key={`file-link-summary-${file.fileName || index}`}
                fileName={file.fileName}
                url={file.url}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SummaryReply;

import React, { ChangeEventHandler, ReactNode } from "react";
import FieldContainer, { FieldContainerProps } from "../FieldContainer";
import classes from "./styles.module.scss";
import classNames from "classnames";

interface Props extends FieldContainerProps {
  name: string;
  options: {
    value: string;
    label?: string | ReactNode;
  }[];
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
}

const RadioFeild: React.FC<Props> = ({
  label,
  required,
  error,
  name,
  options,
  value,
  onChange,
  disabled
}) => (
  <FieldContainer label={label} required={required} error={error}>
    <div
      className={classNames(classes.container, {
        [classes.disabled]: disabled
      })}
    >
      {options.map(option => {
        const id = `option-${name}-${option.value}`;
        return (
          <div key={option.value} className={classes.option}>
            <input
              id={id}
              className={classes.control}
              type="radio"
              name={name}
              value={option.value}
              checked={option.value === value}
              onChange={onChange}
              disabled={disabled}
            />
            <label htmlFor={id} className={classes.label}>
              {option.label || option.value}
            </label>
          </div>
        );
      })}
    </div>
  </FieldContainer>
);

export default RadioFeild;

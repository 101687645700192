import { Reducer } from "redux";
import { InferValueTypes } from "../../types/utils";
import {
  fetchMembershipActions,
  fetchMembershipPlansActions
} from "../actions/membershipActions";
import { MembershipStore } from "../../types/store";
import {
  FETCH_MEMBERSHIP_PLANS_SUCCESS,
  FETCH_MEMBERSHIP_SUCCESS,
  SET_SUBSCRIBE_MODAL_VISIBLE
} from "../constants";

export type InvoicesActions =
  | ReturnType<InferValueTypes<typeof fetchMembershipActions>>
  | ReturnType<InferValueTypes<typeof fetchMembershipPlansActions>>
  | {
      type: "SET_SUBSCRIBE_MODAL_VISIBLE";
      payload: { visible: boolean };
    };

export const getDefaultMembershipStore: () => MembershipStore = () => ({
  plans: [],
  subscribeModalVisible: false
});

export const membership: Reducer<MembershipStore, InvoicesActions> = (
  state = getDefaultMembershipStore(),
  action
) => {
  switch (action.type) {
    case SET_SUBSCRIBE_MODAL_VISIBLE:
      return {
        ...state,
        subscribeModalVisible: action.payload.visible
      };

    case FETCH_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        info: action.payload.info,
        paymentMethod: action.payload.paymentMethod,
        paymentHistory: action.payload.paymentHistory
      };

    case FETCH_MEMBERSHIP_PLANS_SUCCESS:
      return {
        ...state,
        plans: action.payload.plans
      };

    default: {
      return state;
    }
  }
};

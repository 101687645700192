import { Close } from "@material-ui/icons";
import classNames from "classnames";
import React, { useEffect } from "react";
import classes from "./styles.module.scss";

interface Props {
  visible: boolean;
  onClose: () => void;
  header?: string;
  thin?: boolean;
}

const Modal: React.FC<Props> = ({
  visible,
  onClose,
  header,
  thin,
  children
}) => {
  useEffect(() => {
    if (visible) {
      document.body.classList.add(classes.withModal);
    } else {
      document.body.classList.remove(classes.withModal);
    }
  }, [visible]);

  return visible ? (
    <>
      <div
        className={classes.overlay}
        onClick={() => {
          onClose();
        }}
      ></div>
      <div className={classNames(classes.container, { [classes.thin]: thin })}>
        <div className={classes.closeButton}>
          <Close
            onClick={() => {
              onClose();
            }}
          />
        </div>
        {header && <h1>{header}</h1>}
        <div>{children}</div>
      </div>
    </>
  ) : null;
};

export default Modal;

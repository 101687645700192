import { MenuOutlined } from "@material-ui/icons";
import classNames from "classnames";
import React from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { signOut } from "../../../store/actions/signOut";
import chatIcon from "../../../assets/icons/chat_24.svg";
import logoIcon from "../../../assets/images/logo-icon.svg";
import logo from "../../../assets/images/logo.svg";
import { getUserCompanyName, getUserName } from "../../../store/selectors/user";
import { GlobalStore } from "../../../types/store";
import { Mobile, TabletDesktop } from "../../Responsive";
import classes from "./styles.module.scss";

type OwnProps = {
  setDrawerVisible: (visible: boolean) => void;
};

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StateProps;

const Header: React.FC<Props> = ({
  userName,
  userCompanyName,
  setDrawerVisible
}) => {
  const dispatch = useDispatch();

  return (
    <header className={classes.header}>
      <Mobile>
        <MenuOutlined
          htmlColor="#6B727F"
          onClick={() => {
            setDrawerVisible(true);
          }}
        />

        <div className={classes.logo}>
          <Link to="/dashboard">
            <img src={logoIcon} alt="Wingspan Logo" />
          </Link>
        </div>

        <div className={classes.goalsIcon}>
          <Link to="/goals">
            <img src={chatIcon} alt="Goals" width="18" />
          </Link>
        </div>
      </Mobile>

      <TabletDesktop>
        <div className={classNames(classes.logo, classes.logoNoHover)}>
          <Link to="/dashboard">
            <img src={logo} alt="Wingspan logo" />
          </Link>
        </div>

        <nav className={classes.navigation}>
          <ul>
            <li
              className={classNames({
                [classes.selected]: window.location.pathname.includes(
                  "/dashboard"
                )
              })}
            >
              <Link to="/dashboard">Home</Link>
            </li>
            <li
              className={classNames({
                [classes.selected]: window.location.pathname.includes("/team")
              })}
            >
              <Link to="/team">Your Team</Link>
            </li>
            <li
              className={classNames({
                [classes.selected]: window.location.pathname.includes("/goals")
              })}
            >
              <Link to="/goals">Goals</Link>
            </li>
            <li
              className={classNames({
                [classes.selected]: window.location.pathname.includes(
                  "/invoices"
                )
              })}
            >
              <Link to="/invoices">Invoices</Link>
            </li>
          </ul>
        </nav>

        <div className={classes.profileDropdown}>
          <div className={classes.avatar}>
            {userName
              .split(" ")
              .map(word => word[0])
              .join("")}
          </div>
          <div className={classes.details}>
            <div className={classes.name}>{userName}</div>
            {userCompanyName && (
              <div className={classes.company}>{userCompanyName}</div>
            )}
          </div>

          <div className={classes.dropdown}>
            <ul>
              <li>
                <Link to="/profile">Profile</Link>
              </li>
              <li
                onClick={() => {
                  dispatch(signOut());
                }}
              >
                Sign Out
              </li>
            </ul>
          </div>
        </div>
      </TabletDesktop>
    </header>
  );
};

const mapStateToProps = (state: GlobalStore) => ({
  userCompanyName: getUserCompanyName(state),
  userName: getUserName(state)
});

export default connect(mapStateToProps)(Header);

import React from "react";
import classes from "./styles.module.scss";

interface Props {
  title: string;
}

const LabelWithDescription: React.FC<Props> = ({ title, children }) => (
  <div className={classes.container}>
    <div className={classes.title}>{title}</div>
    <div className={classes.description}>{children}</div>
  </div>
);

export default LabelWithDescription;

import { Option } from "../../types/option";
import { PaymentsStatus } from "../../types/paymentsService";
import { createAction } from "../meta/types";
import {
  FETCH_MCCS,
  FETCH_MCCS_FAILURE,
  FETCH_MCCS_REQUEST,
  FETCH_MCCS_SUCCESS,
  FETCH_PAYMENTS_STATUS,
  FETCH_PAYMENTS_STATUS_FAILURE,
  FETCH_PAYMENTS_STATUS_REQUEST,
  FETCH_PAYMENTS_STATUS_SUCCESS
} from "../constants";

export const fetchPaymentsStatusActions = createAction(
  FETCH_PAYMENTS_STATUS,
  FETCH_PAYMENTS_STATUS_REQUEST,
  FETCH_PAYMENTS_STATUS_SUCCESS,
  FETCH_PAYMENTS_STATUS_FAILURE
)<
  undefined,
  {
    status: PaymentsStatus;
  }
>();

export const fetchMCCsActions = createAction(
  FETCH_MCCS,
  FETCH_MCCS_REQUEST,
  FETCH_MCCS_SUCCESS,
  FETCH_MCCS_FAILURE
)<
  undefined,
  {
    mccs: Option[];
  }
>();

import classnames from "classnames";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import iconArrow from "../../assets/icons/arrow_forward_ios_24.svg";
import classes from "./styles.module.scss";

interface Props extends RouteComponentProps {
  link?: string;
  onClick?: () => void;
  withIcon?: boolean;
}

const Item: React.FC<Props> = ({
  children,
  onClick,
  link,
  withIcon,
  history
}) => (
  <div
    className={classnames(classes.item, {
      [classes.clickable]: onClick || link
    })}
    onClick={() => {
      if (onClick) onClick();
      else if (link) history.push(link);
    }}
  >
    {children}
    {withIcon && (
      <div className={classes.arrow}>
        <img src={iconArrow} alt="select" />
      </div>
    )}
  </div>
);

export default withRouter(Item);

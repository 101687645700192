import React from "react";
import { WizardValues } from "../../types/wizardStep";
import FieldValueStyled from "../FieldValue";

interface Props {
  value: string;
  title: string;
  formattedValues: WizardValues;
}

const FieldValue: React.FC<Props> = ({ title, value, formattedValues }) => {
  const regexp = /\$\$[a-zA-Z]*/;

  while (value.search(regexp) >= 0) {
    const match = value.match(regexp);
    if (match) {
      const searchValue = match[0];
      const name = searchValue.slice(2);
      value = value.replace(
        searchValue,
        formattedValues[name] ? formattedValues[name] : ""
      );
    }
  }

  return (
    <FieldValueStyled label={title} valueBold>
      {value}
    </FieldValueStyled>
  );
};

export default FieldValue;

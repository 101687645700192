import React from "react";
import classes from "./styles.module.scss";
import classnames from "classnames";

const Header: React.FC = ({ children }) => (
  <div className={classes.header}>{children}</div>
);

interface MainProps {
  noPadding?: boolean;
}

const Main: React.FC<MainProps> = ({ children, noPadding }) => (
  <div className={classnames(classes.main, { [classes.noPadding]: noPadding })}>
    {children}
  </div>
);

const Footer: React.FC = ({ children }) => (
  <div className={classes.footer}>{children}</div>
);

interface Props {
  className?: string;
}

interface CardComponent extends React.FC<Props> {
  Header: React.FC;
  Main: React.FC<MainProps>;
  Footer: React.FC;
}

const Card: CardComponent = ({ children, className }) => {
  return (
    <div className={classnames(classes.container, className)}>{children}</div>
  );
};

Card.Header = Header;
Card.Main = Main;
Card.Footer = Footer;

export default Card;

import React, { ChangeEventHandler, ReactNode } from "react";
import FieldContainer, { FieldContainerProps } from "../FieldContainer";
import classes from "./styles.module.scss";

export interface RadioFieldProps extends FieldContainerProps {
  name: string;
  options: {
    value: string;
    label?: string | ReactNode;
  }[];
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

const RadioFeild: React.FC<RadioFieldProps> = ({
  label,
  required,
  error,
  smallIndent,
  name,
  options,
  value,
  onChange
}) => (
  <FieldContainer
    label={label}
    required={required}
    error={error}
    smallIndent={smallIndent}
  >
    <div className={classes.container}>
      {options.map(option => {
        const id = `option-${name}-${option.value}`;
        return (
          <div key={option.value} className={classes.option}>
            <input
              id={id}
              className={classes.control}
              type="radio"
              name={name}
              value={option.value}
              checked={option.value === value}
              onChange={onChange}
            />
            <label htmlFor={id} className={classes.label}>
              {option.label || option.value}
            </label>
          </div>
        );
      })}
    </div>
  </FieldContainer>
);

export default RadioFeild;

import React from "react";
import classes from "./styles.module.scss";

interface Props {
  secondary?: boolean;
  onClick?: () => any;
  disabled?: boolean;
  loading?: boolean;
}

const Button: React.FC<Props> = ({
  secondary,
  onClick,
  children,
  disabled,
  loading
}) => (
  <button
    disabled={disabled || loading}
    className={secondary ? classes.secondary : classes.primary}
    onClick={() => {
      if (onClick) onClick();
    }}
  >
    {loading ? <div className={classes.loader} /> : children}
  </button>
);

export default Button;

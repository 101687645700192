const URI_PREFIX = "https://my.wingspan.app/public_images/headshots";

const AGENTS = [
  {
    name: "Anthony Mironov",
    categoryId: 0,
    specialties: "Finance & Operations",
    avatarUrl: `${URI_PREFIX}/advocate_headshot_anthony_mironov.jpg`
  },
  {
    name: "Jim Hardman",
    categoryId: 1,
    specialties: "Benefits & Insurance",
    avatarUrl: `${URI_PREFIX}/advocate_headshot_jim_hardman.jpg`
  },
  {
    name: "John Thompson",
    categoryId: 2,
    specialties: "Contract Wizard",
    avatarUrl: `${URI_PREFIX}/advocate_headshot_john_thompson.jpg`
  },
  {
    name: "Lizzie Bildner",
    categoryId: 3,
    specialties: "Customer support, operations, marketing",
    avatarUrl: `${URI_PREFIX}/advocate_headshot_lizzie_bildner.jpg`
  }
];

export default AGENTS;

import classNames from "classnames";
import { CleaveOptions } from "cleave.js/options";
import Cleave from "cleave.js/react";
import React, { useState } from "react";
import { Icon, IconName } from "../../Icon/Icon";
import FieldContainer, { FieldContainerProps } from "../FieldContainer";
import classes from "./styles.module.scss";

export interface TextFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    FieldContainerProps {
  type?: "text" | "email" | "password" | "number" | "phone" | "date";
  value?: any;
  formatOptions?: CleaveOptions;
  inputWidth?: number | string;
  measurement?: string;
  icon?: IconName;
}

const TextField: React.FC<TextFieldProps> = ({
  formatOptions,
  label,
  error,
  smallIndent,
  required,
  width,
  inputWidth,
  measurement,
  icon,
  ...otherInputProps
}) => {
  const [focused, setFocused] = useState(false);

  const inputProps = {
    ...otherInputProps,
    required,
    onFocus: (event: any) => {
      setFocused(true);
      otherInputProps.onFocus && otherInputProps.onFocus(event);
    },
    onBlur: (event: any) => {
      setFocused(false);
      otherInputProps.onBlur && otherInputProps.onBlur(event);
    }
  };

  return (
    <FieldContainer
      label={label}
      required={required}
      error={error}
      smallIndent={smallIndent}
      width={width}
    >
      <div
        className={classNames(classes.control, {
          [classes.withError]: !!error,
          [classes.focused]: focused
        })}
        style={{ maxWidth: inputWidth }}
      >
        {icon && <Icon name={icon} className={classes.icon} />}
        {formatOptions ? (
          <Cleave {...inputProps} options={formatOptions} />
        ) : (
          <input {...inputProps} />
        )}
        {measurement && (
          <div className={classes.measurement}>{measurement}</div>
        )}
      </div>
    </FieldContainer>
  );
};

export default TextField;

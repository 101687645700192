import { InvoiceFormValues } from "../components/payments/InvoiceForm";
import {
  FrequencyInterval,
  InvoiceResponse,
  LateFeeConfig,
  FrequencyConfig
} from "../types/paymentsService";
import moment from "moment";

export const getInvoiceTotalAmount = (invoice: InvoiceResponse) =>
  invoice.lineItems.reduce((total, item) => total + item.totalCost, 0);

export const generateLateFeeConfig = (
  values: Pick<InvoiceFormValues, "lateFee" | "lateFeeAmount" | "lateFeeAfter">
): LateFeeConfig | undefined =>
  values.lateFee !== "none"
    ? {
        lateFeePercentage:
          values.lateFee === "percent"
            ? Number(values.lateFeeAmount)
            : undefined,
        lateFeeAmount:
          values.lateFee === "fixed" ? Number(values.lateFeeAmount) : undefined,
        frequency:
          values.lateFeeAfter === "7"
            ? {
                interval: FrequencyInterval.Weekly,
                every: 1,
                dayInInterval: 1,
                startDate: new Date(),
                endDate: moment()
                  .add(1, "minute")
                  .toDate()
              }
            : values.lateFeeAfter === "14"
            ? {
                interval: FrequencyInterval.Weekly,
                every: 2,
                dayInInterval: 1,
                startDate: new Date(),
                endDate: moment()
                  .add(1, "minute")
                  .toDate()
              }
            : values.lateFeeAfter === "30"
            ? {
                interval: FrequencyInterval.Monthly,
                every: 1,
                dayInInterval: 1,
                startDate: new Date(),
                endDate: moment()
                  .add(1, "minute")
                  .toDate()
              }
            : values.lateFeeAfter === "every7"
            ? {
                interval: FrequencyInterval.Weekly,
                every: 1,
                dayInInterval: 1,
                startDate: new Date()
              }
            : values.lateFeeAfter === "every14"
            ? {
                interval: FrequencyInterval.Weekly,
                every: 2,
                dayInInterval: 1,
                startDate: new Date()
              }
            : {
                // every30
                interval: FrequencyInterval.Monthly,
                every: 1,
                dayInInterval: 1,
                startDate: new Date()
              }
      }
    : undefined;

export const generateLateFeeAfter = (
  frequency: FrequencyConfig
): "7" | "14" | "30" | "every7" | "every14" | "every30" =>
  frequency.interval === FrequencyInterval.Weekly &&
  frequency.every === 1 &&
  frequency.endDate
    ? "7"
    : frequency.interval === FrequencyInterval.Weekly &&
      frequency.every === 2 &&
      frequency.endDate
    ? "14"
    : frequency.interval === FrequencyInterval.Monthly &&
      frequency.every === 1 &&
      frequency.endDate
    ? "30"
    : frequency.interval === FrequencyInterval.Weekly && frequency.every === 1
    ? "every7"
    : frequency.interval === FrequencyInterval.Weekly && frequency.every === 2
    ? "every14"
    : "every30";

export const lateFeeAfterOptions = [
  {
    value: "7",
    label: "7 days overdue"
  },
  {
    value: "14",
    label: "14 days overdue"
  },
  {
    value: "30",
    label: "30 days overdue"
  },
  {
    value: "every7",
    label: "Every 7 days overdue"
  },
  {
    value: "every14",
    label: "Every 14 days overdue"
  },
  {
    value: "every30",
    label: "Every 30 days overdue"
  }
];

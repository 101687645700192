function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from "react";
export default (({
  styles = {},
  ...props
}) => React.createElement("svg", _extends({
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  xmlns: "http://www.w3.org/2000/svg"
}, props), React.createElement("path", {
  d: "M18.295 5.705a.997.997 0 00-1.41 0L12 10.59 7.115 5.705a.997.997 0 10-1.41 1.41L10.59 12l-4.885 4.885a.997.997 0 101.41 1.41L12 13.41l4.885 4.885a.997.997 0 001.41-1.41L13.41 12l4.885-4.885a.997.997 0 000-1.41z"
})));
export interface PrefillField {
  name: string;
  customSource?: string;
}

export const PREFILL_FIELDS: PrefillField[] = [
  { name: "dob" },
  { name: "gender" },
  { name: "spouse" },
  { name: "kids" },
  { name: "spouseAge" },
  { name: "spouseGender" },
  { name: "childOneAge" },
  { name: "childOneGender" },
  { name: "childTwoAge" },
  { name: "childTwoGender" },
  { name: "childAdditional" },
  { name: "legalName" },
  { name: "address" },
  { name: "city" },
  { name: "state" },
  { name: "zipCode" },
  { name: "businessName" },
  {
    name: "businessAddress",
    customSource: "address"
  },
  {
    name: "businessCity",
    customSource: "city"
  },
  {
    name: "businessState",
    customSource: "state"
  },
  { name: "taxClassification" },
  { name: "filingType" },
  {
    name: "businessZipCode",
    customSource: "zipCode"
  },
  { name: "businessPhone" },
  {
    name: "businessEmail",
    customSource: "email"
  },
  { name: "firstName" },
  { name: "lastName" },
  { name: "nickName" },
  {
    name: "organizerLLC",
    customSource: "legalName"
  }
];

import { GlobalStore } from "../types/store";
import { IInvoiceStatus } from "../types/paymentsService";

export const loadSegment = () => {
  try {
    if (process.env.REACT_APP_SEGMENT_API_KEY)
      window.analytics.load(process.env.REACT_APP_SEGMENT_API_KEY);
  } catch (error) {
    console.error("Failed loading Segment.io");
    console.error(error);
  }
};

export function checkIfEmailWingspan() {
  return (
    !window.userEmail ||
    (window.userEmail &&
      !window.userEmail.includes("wingspancollective.com") &&
      !window.userEmail.includes("wingspan.app"))
  );
}

export function identify(..._args: any) {
  if (checkIfEmailWingspan()) {
    window.analytics.identify(...arguments);
  }
}

export function track(..._args: any) {
  if (checkIfEmailWingspan()) {
    window.analytics.track(...arguments);
  }
}

export function makeEnrichIdentifyFromState(state: GlobalStore) {
  return function(
    plan: string,
    subscribedDate: Date,
    subscriptionStarts: Date
  ) {
    const userData = state.user.data;

    if (userData) {
      identify(userData._id, {
        roleId: userData.roleId,
        ...userData.fields,
        plan,
        subscribedDate,
        subscriptionStarts
      });
    }
  };
}

export function trackInvoiceAction(
  action: string,
  invoiceId: string | null,
  status: IInvoiceStatus | null,
  payload?: object
) {
  track("Invoice", { action: action, invoiceId, status, ...payload });
}

import paymentsService from "../../services/payments";
import { fetchPaymentsStatusActions } from "./paymentsActions";
import { AppThunk } from "../../types/store";

export const fetchPaymentsStatus = (): AppThunk<any> => async dispatch => {
  dispatch(fetchPaymentsStatusActions.request(undefined));

  try {
    const status = await paymentsService.getStatus();

    dispatch(
      fetchPaymentsStatusActions.success({
        status
      })
    );
  } catch (error) {
    dispatch(fetchPaymentsStatusActions.failure(error));
  }
};

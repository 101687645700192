import classNames from "classnames";
import React, { useState } from "react";
import FieldContainer, { FieldContainerProps } from "../FieldContainer";
import classes from "./styles.module.scss";

export interface TextAreaFieldProps
  extends React.InputHTMLAttributes<HTMLTextAreaElement>,
    FieldContainerProps {
  type?: "text" | "email" | "password" | "number" | "phone" | "date";
  width?: number | string;
}

const TextField: React.FC<TextAreaFieldProps> = ({
  label,
  error,
  required,
  smallIndent,
  width,
  ...otherInputProps
}) => {
  const [focused, setFocused] = useState(false);

  const inputProps = {
    ...otherInputProps,
    onFocus: (event: any) => {
      setFocused(true);
      otherInputProps.onFocus && otherInputProps.onFocus(event);
    },
    onBlur: (event: any) => {
      setFocused(false);
      otherInputProps.onBlur && otherInputProps.onBlur(event);
    }
  };

  return (
    <FieldContainer
      label={label}
      required={required}
      error={error}
      smallIndent={smallIndent}
    >
      <div
        className={classNames(classes.control, {
          [classes.withError]: !!error,
          [classes.focused]: focused
        })}
        style={{ maxWidth: width }}
      >
        <textarea {...inputProps}></textarea>
      </div>
    </FieldContainer>
  );
};

export default TextField;

import {
  makeStyles,
  Tab as MaterialTab,
  Tabs as MaterialTabs
} from "@material-ui/core";
import React, { Children, useState } from "react";
import Tab from "./Tab";

type TabComponent = typeof Tab;

interface TabsComponent extends React.FC {
  Tab: TabComponent;
}

const useContainerStyles = makeStyles({
  root: {
    marginTop: -48
  },
  indicator: {
    backgroundColor: "#F5554B"
  }
});

const useTabStyles = makeStyles({
  root: {
    padding: 13,
    backgroundColor: "#F3F5F9",
    color: "#ABAEB5",
    fontFamily: "Centra No2",
    fontSize: 14,
    lineHeight: 1.5,
    textTransform: "capitalize",
    opacity: 1
  },
  selected: {
    color: "#282D37" // This is not working
  }
});

const Tabs: TabsComponent = ({ children }) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const containerClasses = useContainerStyles();
  const tabClasses = useTabStyles();

  const handleChange = (_: any, newCurrentTabIndex: number) => {
    setCurrentTabIndex(newCurrentTabIndex);
  };

  const visibleTab = Children.toArray(children).find(
    (_: any, index) => index === currentTabIndex
  );

  return (
    <>
      <MaterialTabs
        className={containerClasses.root}
        value={currentTabIndex}
        onChange={handleChange}
        variant="fullWidth"
      >
        {Children.map(children, (child: any, index: number) => (
          <MaterialTab
            className={tabClasses.root}
            label={child.props.name}
            id={`simple-tab-${index}`}
            aria-controls={`simple-tabpanel-${index}`}
          />
        ))}
      </MaterialTabs>

      {visibleTab}
    </>
  );
};

Tabs.Tab = Tab;

export default Tabs;

import { formatMoney } from "accounting";
import moment from "moment";
import React, { useCallback } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getInvoiceDataById } from "../../../store/selectors/invoices";
import { getMemberClientDataById } from "../../../store/selectors/memberClients";
import { GlobalStore } from "../../../types/store";
import { getInvoiceTotalAmount } from "../../../utils/invoiceHelper";
import { InvoiceActionsDropdownContainer } from "../InvoiceActionsDropdown";
import InvoiceStatus from "../InvoiceStatus";
import classes from "./styles.module.scss";

interface OwnProps extends RouteComponentProps {
  invoiceId: string;
}
type MapStateToProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & MapStateToProps;

const InvoicesListMobileItem: React.FC<Props> = props => {
  const onView = useCallback(() => {
    props.history.push(`/invoices/${props.invoiceId}`);
  }, [props.history, props.invoiceId]);

  return props.invoiceData && props.memberClientData ? (
    <div className={classes.item}>
      <div className={classes.clickable} onClick={onView}>
        <div className={classes.status}>
          <InvoiceStatus status={props.invoiceData.status} hideText />
        </div>
        <div className={classes.info}>
          <div className={classes.dueDate}>
            Due {moment.utc(props.invoiceData.dueDate).format("MMM D, YYYY")}
          </div>
          <div className={classes.client}>{props.memberClientData.company}</div>
        </div>
        <div className={classes.totalAmount}>
          {formatMoney(getInvoiceTotalAmount(props.invoiceData))}
        </div>
      </div>
      <div className={classes.actions}>
        <InvoiceActionsDropdownContainer invoiceId={props.invoiceId} />
      </div>
    </div>
  ) : null;
};

const mapStateToProps = (state: GlobalStore, props: OwnProps) => {
  const invoiceData = getInvoiceDataById(state, props.invoiceId);
  let memberClientData;
  if (invoiceData?.memberClientId)
    memberClientData = getMemberClientDataById(
      state,
      invoiceData?.memberClientId
    );

  return {
    invoiceData,
    memberClientData
  };
};

export const InvoicesListMobileItemContainer = withRouter(
  connect(mapStateToProps)(InvoicesListMobileItem)
);

import { ConnectedRouter } from "connected-react-router";
import React, { useEffect } from "react";
import { connect, Provider, ReactReduxContext } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { InitAppContainer } from "./components/AppLoading";
import PrivateRoute from "./components/PrivateRoute";
import ScrollToTop from "./components/ScrollToTop";
import "./global.scss";
import Chat from "./screens/Chat";
import CreateNewGoal from "./screens/CreateNewGoal";
import Dashboard from "./screens/Dashboard";
import Goals from "./screens/Goals";
import { InvoicesRootContainer } from "./screens/invoices";
import InvoicesSetUpFillingType from "./screens/invoices/SetUpFillingType";
import InvoicesSetUpForm from "./screens/invoices/SetUpForm";
import Login from "./screens/LogIn";
import NewTicket from "./screens/NewTicket";
import OnboardingIntro from "./screens/onboarding/Intro";
import OnboardingQuestionnaire from "./screens/onboarding/Questionnaire";
import OnboardingSubscribe from "./screens/onboarding/Subscribe";
import OnboardingSuccess from "./screens/onboarding/Success";
import PasswordReset from "./screens/PasswordReset";
import Profile from "./screens/Profile";
import Register from "./screens/Register";
import RequestPasswordReset from "./screens/RequestPasswordReset";
import Subscribe from "./screens/Subscribe";
import Team from "./screens/Team";
import { history, store } from "./store";
import URL from "./types/url";
import { GlobalStore } from "./types/store";
import { getUserAuthorized } from "./store/selectors/user";
import { loadSegment } from "./utils/analytics";
import { Notifications } from "./components/Notification/Notification";

const GuestApp: React.FC = () => (
  <>
    <ScrollToTop />
    <Switch>
      <Route path="/sign-in" component={Login} />
      <Route path="/register" component={Register} />
      <Route path="/reset-password" exact component={RequestPasswordReset} />
      <Route path="/reset-password/:code" component={PasswordReset} />
      <Route path="/privacy-policy" />
      <Route render={() => <Redirect to="/sign-in" />} />
    </Switch>
  </>
);

const UserApp: React.FC = () => (
  <InitAppContainer>
    <ScrollToTop />
    <Notifications />
    <Switch>
      <PrivateRoute path="/onboarding" exact component={OnboardingIntro} />
      <PrivateRoute
        path="/onboarding/questionnaire/:step"
        exact
        component={OnboardingQuestionnaire}
      />
      <PrivateRoute
        path="/onboarding/success"
        exact
        component={OnboardingSuccess}
      />
      <PrivateRoute
        path="/onboarding/subscribe"
        exact
        component={OnboardingSubscribe}
      />

      <PrivateRoute path="/dashboard" exact component={Dashboard} />
      <PrivateRoute path="/dashboard/:step1" exact component={NewTicket} />
      <PrivateRoute
        path="/dashboard/:step1/:step2"
        exact
        component={NewTicket}
      />
      <PrivateRoute
        path="/dashboard/:step1/:step2/:step3"
        exact
        component={NewTicket}
      />
      <PrivateRoute
        path="/dashboard/:step1/:step2/:step3/:step4"
        exact
        component={NewTicket}
      />

      <PrivateRoute path="/profile" exact component={Profile} />
      <PrivateRoute path="/team" exact component={Team} />
      <PrivateRoute path="/goals" exact component={Goals} />
      <PrivateRoute path="/goals/f/:freshdeskTicketId" exact component={Chat} />
      <PrivateRoute path="/create-goal" exact component={CreateNewGoal} />

      <PrivateRoute path="/subscribe" exact component={Subscribe} />

      <PrivateRoute
        path="/invoices/set-up"
        exact
        component={InvoicesSetUpFillingType}
      />
      <PrivateRoute
        path="/invoices/set-up/2"
        exact
        component={InvoicesSetUpForm}
      />
      <PrivateRoute path="/invoices" component={InvoicesRootContainer} />

      <PrivateRoute
        path="/admin"
        allowedRoleIds={[0]}
        exact
        component={() => {
          window.location.href = URL.retool;
          return null;
        }}
      />

      <Route path="/privacy-policy" />
      <Route
        path={["/sign-in", "/register"]}
        render={props => {
          if (props.location.hash) {
            return <Redirect to={props.location.hash.slice(1)} />;
          } else if (props.location.pathname.includes("/register")) {
            return <Redirect to="/onboarding" />;
          } else {
            return <Redirect to="/dashboard" />;
          }
        }}
      />
      <Redirect from="*" to="/dashboard" />
    </Switch>
  </InitAppContainer>
);

const Main = connect((state: GlobalStore) => ({
  isAuthorized: getUserAuthorized(state)
}))(({ isAuthorized }) => {
  useEffect(() => {
    loadSegment();
  }, []);

  if (isAuthorized) {
    return <UserApp />;
  } else {
    return <GuestApp />;
  }
});

const App: React.FC = () => (
  <Provider store={store} context={ReactReduxContext}>
    <ConnectedRouter history={history} context={ReactReduxContext}>
      <Main />
    </ConnectedRouter>
  </Provider>
);

export default App;

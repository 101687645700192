import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import classNames from "classnames";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { cancelInvoice } from "../../../store/actions/cancelInvoice";
import { copyInvoiceLink } from "../../../store/actions/copyInvoiceLink";
import { deleteInvoice } from "../../../store/actions/deleteInvoice";
import { downloadInvoicePDF } from "../../../store/actions/downloadInvoicePDF";
import { fetchInvoiceList } from "../../../store/actions/fetchInvoiceList";
import { sendInvoiceReminder } from "../../../store/actions/sendInvoiceReminder";
import { updateInvoice } from "../../../store/actions/updateInvoice";
import { getInvoiceDataById } from "../../../store/selectors/invoices";
import { IInvoiceStatus } from "../../../types/paymentsService";
import { GlobalStore } from "../../../types/store";
import { trackInvoiceAction } from "../../../utils/analytics";
import ThreeDotsButton from "../ThreeDotsButton";
import classes from "./styles.module.scss";
import { AutoPosition } from "../../AutoPosition";

interface OwnProps extends RouteComponentProps {
  invoiceId: string;
}
type MapStateToProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps &
  MapStateToProps & {
    buttons?: boolean;
    noView?: boolean;
    noEdit?: boolean;
    noCancel?: boolean;
    noDelete?: boolean;
    noDownloadPDF?: boolean;
    noCopyInvoiceLink?: boolean;
    noSendReminder?: boolean;
  };

const InvoiceActionsDropdown: React.FC<Props> = props => {
  const dispatch = useDispatch();

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [showButtons, setShowButtons] = useState(false);

  if (props.invoiceData) {
    const showView = !props.noView;
    const showEdit =
      !props.noEdit && props.invoiceData.status !== IInvoiceStatus.Paid;
    const showDelete =
      !props.noDelete && props.invoiceData.status === IInvoiceStatus.Draft;
    const showCancel =
      !props.noCancel && props.invoiceData.status === IInvoiceStatus.Open;
    const showMarkPaid = props.invoiceData.status !== IInvoiceStatus.Paid;
    const showDownloadPDF =
      !props.noDownloadPDF && !!props.invoiceData.attachments.invoicePdf;

    const showCopyInvoiceLink =
      !props.noCopyInvoiceLink && !!props.invoiceData.attachments.invoiceLink;

    const showSendReminder =
      !props.noSendReminder &&
      (props.invoiceData.status === IInvoiceStatus.Open ||
        props.invoiceData.status === IInvoiceStatus.Overdue);

    if (!(showView || showEdit || showDelete || showCancel || showMarkPaid)) {
      return null;
    }

    const view = () => {
      props.history.push(`/invoices/${props.invoiceId}`);
      setDropdownVisible(false);
    };

    const edit = () => {
      trackInvoiceAction(
        "Edit Invoice",
        props.invoiceId,
        props.invoiceData.status
      );

      props.history.push(`/invoices/${props.invoiceId}/edit`);
      setDropdownVisible(false);
    };

    const remove = async () => {
      await dispatch(deleteInvoice(props.invoiceId));
      await dispatch(fetchInvoiceList());

      setDropdownVisible(false);
    };

    const cancel = async () => {
      await dispatch(cancelInvoice(props.invoiceId));
      await dispatch(fetchInvoiceList());
      setDropdownVisible(false);
    };

    const markPaid = async () => {
      trackInvoiceAction(
        "Mark Paid",
        props.invoiceId,
        props.invoiceData.status
      );

      await dispatch(
        updateInvoice(props.invoiceId, {
          status: IInvoiceStatus.Paid
        })
      );
      await dispatch(fetchInvoiceList());

      setDropdownVisible(false);
    };

    const downloadPDF = async () => {
      await dispatch(downloadInvoicePDF(props.invoiceId));

      setDropdownVisible(false);
    };

    const sendReminder = async () => {
      await dispatch(sendInvoiceReminder(props.invoiceId));

      setDropdownVisible(false);
    };

    const copyLink = async () => {
      await dispatch(copyInvoiceLink(props.invoiceId));

      setDropdownVisible(false);
    };

    return (
      <div
        className={classNames(classes.container, {
          [classes.containerWithButtons]: showButtons
        })}
      >
        {props.buttons ? (
          showButtons ? (
            <>
              <ul className={classes.actions}>
                {showView && (
                  <li className={classes.view} onClick={view}>
                    View
                  </li>
                )}

                {showEdit && (
                  <li className={classes.edit} onClick={edit}>
                    Edit
                  </li>
                )}
                {showDelete && (
                  <li className={classes.delete} onClick={remove}>
                    Delete
                  </li>
                )}
                {showCancel && (
                  <li className={classes.cancel} onClick={cancel}>
                    Cancel
                  </li>
                )}
                {showMarkPaid && (
                  <li className={classes.markPaid} onClick={markPaid}>
                    Mark paid
                  </li>
                )}
                {showDownloadPDF && (
                  <li className={classes.downloadPdf} onClick={downloadPDF}>
                    Download PDF
                  </li>
                )}
                {showSendReminder && (
                  <li className={classes.sendReminder} onClick={sendReminder}>
                    Send reminder
                  </li>
                )}
                {showCopyInvoiceLink && (
                  <li className={classes.downloadPdf} onClick={copyLink}>
                    Copy invoice link
                  </li>
                )}
              </ul>
              <span
                className={classes.button}
                onClick={() => {
                  setShowButtons(false);
                }}
              >
                <ArrowDropUp />
                Hide actions
              </span>
            </>
          ) : (
            <>
              <span
                className={classes.button}
                onClick={() => {
                  setShowButtons(true);
                }}
              >
                <ArrowDropDown />
                More actions
              </span>
            </>
          )
        ) : (
          <ThreeDotsButton
            onClick={() => {
              trackInvoiceAction(
                "Click Submenu",
                props.invoiceId,
                props.invoiceData.status,
                {
                  location:
                    props.location.pathname === "/invoices"
                      ? "Activity"
                      : "Detail"
                }
              );
              setDropdownVisible(true);
            }}
          />
        )}
        {dropdownVisible && (
          <>
            <div
              className={classes.overlay}
              onClick={() => {
                setDropdownVisible(false);
              }}
            />
            <AutoPosition
              className={classes.dropdown}
              topClassName={classes.positionTop}
              bottomClassName={classes.positionBottom}
              offset={30}
            >
              <ul>
                {showView && (
                  <li className={classes.view} onClick={view}>
                    View
                  </li>
                )}

                {showEdit && (
                  <li className={classes.edit} onClick={edit}>
                    Edit
                  </li>
                )}
                {showDelete && (
                  <li className={classes.delete} onClick={remove}>
                    Delete
                  </li>
                )}
                {showCancel && (
                  <li className={classes.cancel} onClick={cancel}>
                    Cancel
                  </li>
                )}
                {showMarkPaid && (
                  <li className={classes.markPaid} onClick={markPaid}>
                    Mark paid
                  </li>
                )}
                {showDownloadPDF && (
                  <li className={classes.downloadPdf} onClick={downloadPDF}>
                    Download PDF
                  </li>
                )}
                {showSendReminder && (
                  <li className={classes.sendReminder} onClick={sendReminder}>
                    Send reminder
                  </li>
                )}
                {showCopyInvoiceLink && (
                  <li className={classes.downloadPdf} onClick={copyLink}>
                    Copy invoice link
                  </li>
                )}
              </ul>
            </AutoPosition>
          </>
        )}
      </div>
    );
  }

  return null;
};

const mapStateToProps = (state: GlobalStore, props: OwnProps) => ({
  invoiceData: getInvoiceDataById(state, props.invoiceId)
});

export const InvoiceActionsDropdownContainer = withRouter(
  connect(mapStateToProps)(InvoiceActionsDropdown)
);

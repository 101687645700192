import { CleaveOptions } from "cleave.js/options";
import Cleave from "cleave.js/react";
import React from "react";
import FieldContainer, { FieldContainerProps } from "../FieldContainer";
import classes from "./styles.module.scss";

export interface TextFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    FieldContainerProps {
  type?: "text" | "email" | "password" | "number" | "phone" | "date";
  value?: any;
  formatOptions?: CleaveOptions;
}

const TextField: React.FC<TextFieldProps> = ({
  formatOptions,
  label,
  error,
  required,
  ...otherInputProps
}) => {
  const inputProps = {
    ...otherInputProps,
    required
  };

  return (
    <FieldContainer label={label} required={required} error={error}>
      <div className={classes.control}>
        {formatOptions ? (
          <Cleave {...inputProps} options={formatOptions} />
        ) : (
          <input {...inputProps} />
        )}
      </div>
    </FieldContainer>
  );
};

export default TextField;

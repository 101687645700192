import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

import { Asset, createAsset } from "../../services/api";
import styles from "./styles.module.scss";
import iconClose from "../../assets/icons/close_24.svg";
import iconFile from "../../assets/icons/file_24.svg";
import iconFileCopy from "../../assets/icons/file_copy_24.svg";

interface Props {
  files: Asset[];
  onChange: (values: Asset[]) => void;
  error?: string;
}

const FileUploader: React.FC<Props> = ({ files, onChange, error }) => {
  const onDrop = useCallback(
    async acceptedFiles => {
      const uploaders = acceptedFiles.map((file: File) => createAsset(file));

      const assets: Asset[] = await Promise.all(uploaders);

      onChange([...files, ...assets]);
    },
    [files, onChange]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png, application/pdf, xls"
  });

  return (
    <div className={styles.fileUploader}>
      <div {...getRootProps()} className={styles.uploadBox}>
        <form action="" encType="multipart/form-data">
          <input {...getInputProps()} />
        </form>
        {isDragActive ? (
          <div>Drop files here</div>
        ) : (
          <div>
            <p>
              <img src={iconFileCopy} alt="Files" className={styles.fileCopy} />
              Upload documents
            </p>
            <p className="modest">Upload any .jpg, .png, .pdf, .xls.</p>
          </div>
        )}
      </div>
      <ul className={styles.uploadedList}>
        {files.map((file, index) => (
          <li key={index}>
            <img src={iconFile} alt="file" className={styles.iconFile} />
            {file.fileName}
            <button
              className={styles.remove}
              onClick={() => {
                onChange(files.filter(f => f.fileName !== file.fileName));
              }}
            >
              <img src={iconClose} alt="Remove" />
            </button>
          </li>
        ))}
      </ul>
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

export default FileUploader;

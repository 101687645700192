import React from "react";
import styles from "./styles.module.scss";

interface Props {
  name: string;
}

const Tab: React.FC<Props> = ({ children }) => (
  <div className={styles.tab}>{children}</div>
);

export default Tab;

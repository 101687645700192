import classNames from "classnames";
import React, { useState, useRef, useCallback } from "react";
import classes from "./styles.module.scss";
import FieldContainer, { FieldContainerProps } from "../FieldContainer";
import { useDropdownPosition } from "../../../utils/useDropdownPosition";

export interface DropDownProps extends FieldContainerProps {
  options: {
    label?: string;
    value: string;
  }[];
  onChange: (value: string) => void;
  value: string;
  placeholder?: string;
  searchable?: boolean;
  searchPlaceholder?: string;
}

const DropDownField: React.FC<DropDownProps> = ({
  value,
  options,
  onChange,
  placeholder,
  searchable,
  searchPlaceholder,
  label,
  required,
  error,
  smallIndent,
  width
}) => {
  const [visible, setVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { position: dropdownPosition } = useDropdownPosition(
    dropdownRef,
    visible,
    45
  );
  const [searchText, setSearchText] = useState("");

  const visibleOptions = searchText
    ? options.filter(option =>
        (option.label || option.value)
          .toLowerCase()
          .includes(searchText.toLowerCase())
      )
    : options;
  const selectedOption = options.find(option => option.value === value);

  const hideDropdown = useCallback(() => {
    setVisible(false);
    setSearchText("");
  }, []);

  return (
    <FieldContainer
      label={label}
      required={required}
      error={error}
      smallIndent={smallIndent}
      width={width}
    >
      {visible && (
        <div
          className={classes.overlay}
          onClick={() => {
            hideDropdown();
          }}
        />
      )}
      <div
        className={classNames(classes.control, {
          [classes.focused]: visible,
          [classes.withError]: !!error,
          [classes.searchable]: searchable
        })}
      >
        <div
          className={classes.input}
          onClick={() => {
            if (visible) {
              if (!searchable) hideDropdown();
            } else {
              setVisible(true);
            }
          }}
        >
          {visible && searchable ? (
            <input
              placeholder={searchPlaceholder}
              value={searchText}
              onChange={event => {
                setSearchText(event.target.value);
              }}
              autoFocus
            />
          ) : selectedOption ? (
            <div className={classes.value}>
              {selectedOption.label || selectedOption.value}
            </div>
          ) : (
            placeholder && (
              <div className={classes.placeholder}>{placeholder}</div>
            )
          )}
          <div className={classes.icon}></div>
        </div>
        {visible && (
          <div
            className={classNames(classes.dropdown, {
              [classes.positionTop]: dropdownPosition === "top"
            })}
            ref={dropdownRef}
          >
            {visibleOptions.map(option => (
              <div
                key={option.value}
                className={classNames(classes.option, {
                  [classes.selected]: option.value === value
                })}
                onClick={() => {
                  onChange(option.value);
                  hideDropdown();
                }}
              >
                {option.label || option.value}
              </div>
            ))}
          </div>
        )}
      </div>
    </FieldContainer>
  );
};

export default DropDownField;

const URL = {
  marketingDomain: process.env.REACT_APP_MARKETING_DOMAIN,
  termsOfService:
    "https://support.wingspan.app/support/solutions/articles/47000438531-terms-of-service",
  privacyPolicy:
    "https://support.wingspan.app/support/solutions/articles/47000517542-privacy-policy",
  retool: "https://wingspan.retool.com/apps/Wingspan%20Admin",
  stripeConnectedTerms: "https://stripe.com/connect-account/legal"
};

export default URL;

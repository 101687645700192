import { GlobalStore } from "../../types/store";

export const getMemberClientsListIds = (state: GlobalStore) =>
  state.payments.memberClients.listIds;

export const getMemberClientsById = (state: GlobalStore) =>
  state.payments.memberClients.byId;

export const getMemberClientsListMeta = (state: GlobalStore) =>
  state.payments.memberClients.meta;

export const getMemberClientDataById = (
  state: GlobalStore,
  memberClientId: string
) => state.payments.memberClients.byId[memberClientId]?.data;

export const getMemberClientMetaById = (
  state: GlobalStore,
  memberClientId: string
) => state.payments.memberClients.byId[memberClientId]?.meta;

import React, { useCallback } from "react";
import classes from "./ChatTextField.module.scss";
import attachIcon from "../../assets/icons/attach_24.svg";
import { useDropzone } from "react-dropzone";

interface Props {
  setValue: (value: string) => void;
  sendReply: () => void;
  value: string;
  sendFile: (file: File) => void;
}

const ChatTextField: React.FC<Props> = ({
  setValue,
  sendFile,
  sendReply,
  value
}) => {
  const onDrop = useCallback(
    async acceptedFiles => {
      const uploaders = acceptedFiles.map((file: File) => sendFile(file));
      await Promise.all(uploaders);
    },
    [sendFile]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png, application/pdf, xls"
  });

  return (
    <div className={classes.container}>
      <div {...getRootProps()} className={classes.attach}>
        <form action="" encType="multipart/form-data">
          <input {...getInputProps()} />
        </form>
        <img src={attachIcon} alt="Send a file" />
      </div>

      <textarea
        value={value}
        rows={1}
        placeholder="Type a message..."
        onChange={e => setValue(e.target.value)}
        onKeyDown={e => {
          if (e.keyCode === 13 && !e.shiftKey) {
            sendReply();
            e.stopPropagation();
            e.preventDefault();
          }
        }}
      />
      <button className={classes.sendButton} onClick={() => sendReply()}>
        Send
      </button>
    </div>
  );
};

export default ChatTextField;

import React from "react";
import classNames from "classnames";
import style from "./Icon.module.scss";
import iconNames from "../../assets/icons/list.json";

type IconNames = typeof iconNames;

export type IconName = keyof IconNames;

interface SVGIcon {
  default: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
}

const packIcons: { [name: string]: SVGIcon } = {};

function importAll(context: any) {
  context.keys().forEach((path: string) => {
    const file = path.split("/").pop() as string;
    const name = file.split(".").shift() as string;
    packIcons[name] = context(path);
  });
}

importAll(
  require.context(
    '!!react-svg-loader?{"svgo": {"plugins": [{"removeViewBox": false, "removeDimensions": true}]}}!../../assets/icons',
    true,
    /\.svg$/
  )
);

interface IconProps extends React.HTMLAttributes<HTMLElement> {
  name: IconName;
  className?: string;
}

export const Icon: React.FC<IconProps> = ({
  name,
  className,
  children,
  ...rest
}) => (
  <i className={classNames(style.icon, className)} {...rest}>
    {React.createElement(packIcons[name].default, {
      className: style.svg,
      preserveAspectRatio: "xMaxYMax meet",
      width: "100%",
      height: "100%"
    })}
  </i>
);

import paymentsService from "../../services/payments";
import {
  fetchMemberClientListFailure,
  fetchMemberClientListRequest,
  fetchMemberClientListSuccess
} from "./memberClientsActions";
import { AppThunk, MemberClientState } from "../../types/store";

export const fetchMemberClientList = (): AppThunk<any> => async dispatch => {
  dispatch(fetchMemberClientListRequest());

  try {
    const response = await paymentsService.getMemberClients();

    const byId: {
      [id: string]: MemberClientState;
    } = {};

    response.forEach(memberClient => {
      byId[memberClient.memberClientId] = {
        meta: {
          loading: false
        },
        data: memberClient
      };
    });

    const listIds = response.map(memberClient => memberClient.memberClientId);

    dispatch(fetchMemberClientListSuccess(byId, listIds));
  } catch (error) {
    dispatch(fetchMemberClientListFailure(error));
  }
};

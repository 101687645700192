import React, { useEffect } from "react";
import logoIcon from "../../../assets/images/logo-icon.svg";
import logo from "../../../assets/images/logo.svg";
import { Mobile, TabletDesktop } from "../../Responsive";
import Actions from "./Actions";
import MainBox from "./MainBox";
import classes from "./styles.module.scss";

interface Props {
  title: string;
}

interface LayoutComponent extends React.FC<Props> {
  MainBox: typeof MainBox;
  Actions: typeof Actions;
}

const Layout: LayoutComponent = ({ title, children }) => {
  useEffect(() => {
    document.title = title + " - Wingspan Collective";
    window.analytics.page();
  }, [title]);

  return (
    <div className={classes.container}>
      <header className={classes.header}>
        <Mobile>
          <img src={logoIcon} alt="Wingspan Logo" />
        </Mobile>
        <TabletDesktop>
          <img src={logo} alt="Wingspan Logo" />
        </TabletDesktop>
      </header>

      <main className={classes.main}>{children}</main>
    </div>
  );
};

Layout.MainBox = MainBox;
Layout.Actions = Actions;

export default Layout;

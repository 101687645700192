import React from "react";
import classes from "./styles.module.scss";
import { IInvoiceStatus } from "../../../types/paymentsService";
import classNames from "classnames";

interface Props {
  status: IInvoiceStatus;
  hideText?: boolean;
}

const InvoiceStatus: React.FC<Props> = ({ status, hideText }) => (
  <div
    className={classNames(classes.container, {
      [classes.paid]: status === IInvoiceStatus.Paid,
      [classes.scheduled]: status === IInvoiceStatus.Open,
      [classes.draft]: status === IInvoiceStatus.Draft,
      [classes.overdue]: status === IInvoiceStatus.Overdue,
      [classes.cancel]: status === IInvoiceStatus.Cancelled,
      [classes.hideText]: hideText
    })}
  >
    {status}
  </div>
);

export default InvoiceStatus;

export function generateFormStatus(error: any) {
  let message = "Something went wrong. Please try again later.";

  if (error.response) {
    if (error.response.data.error) error = error.response.data.error;
    else if (error.response.data.errors) error = error.response.data.errors;
  }

  if (typeof error === "string") message = error;
  else if (error.message) message = error.message;

  return {
    type: "error",
    message
  };
}

import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { fetchUserUpdate } from "../../store/actions/fetchUserUpdate";
import LabelWithDescription from "../../components/LabelWithDescription";
import Button from "../../components/onboarding/Button";
import DateField from "../../components/onboarding/DateField";
import DropDownField from "../../components/onboarding/DropDownField";
import Layout from "../../components/onboarding/Layout";
import TextField from "../../components/onboarding/TextField";
import RadioField from "../../components/RadioField";
import Row from "../../components/Row";
import { UserQuestionnaire } from "../../services/api";
import {
  getUserAuthorized,
  getUserQuestionnaire
} from "../../store/selectors/user";
import { GlobalStore } from "../../types/store";

type StateProps = ReturnType<typeof mapStateToProps>;

type RouteProps = RouteComponentProps<{ step: string }>;

type Props = RouteProps & StateProps;

const Questionnaire: React.FC<Props> = ({
  userQuestionnaire,
  match: {
    params: { step }
  },
  history
}) => {
  const dispatch = useDispatch();

  const initialValues: any = {};
  userQuestionnaire.forEach(field => {
    initialValues[field.name] = field.response;
  });

  const [loading, setLoading] = useState(false);

  const [workSituation, setWorkSituation] = useState(
    initialValues.workSituation || ""
  );
  const [revenue, setRevenue] = useState(initialValues.revenue || "");
  const [expenses, setExpenses] = useState(initialValues.expenses || "");
  const [zipCode, setZipCode] = useState(initialValues.zipCode || "");
  const [dob, setDob] = useState<Date | null>(initialValues.dob);
  const [gender, setGender] = useState(initialValues.gender || "");

  async function sendQuestionnaireData() {
    try {
      const questionnaire: UserQuestionnaire = [];

      if (workSituation) {
        questionnaire.push({
          name: "workSituation",
          type: "radio",
          response: workSituation
        });
      }

      if (revenue) {
        questionnaire.push({
          name: "revenue",
          type: "text",
          response: revenue
        });
      }

      if (expenses) {
        questionnaire.push({
          name: "expenses",
          type: "text",
          response: expenses
        });
      }

      if (zipCode) {
        questionnaire.push({
          name: "zipCode",
          type: "text",
          response: zipCode
        });
      }

      if (dob) {
        questionnaire.push({
          name: "dob",
          type: "date",
          response: dob
        });
      }

      if (gender) {
        questionnaire.push({
          name: "gender",
          type: "dropDown",
          response: gender
        });
      }

      await dispatch(
        fetchUserUpdate({
          questionnaire,
          zipCode,
          dob: dob ? dob : undefined,
          gender
        })
      );
    } catch (error) {
      console.error("Failed to send questionnaire result");
      console.error(error);
    }
  }

  switch (step) {
    case "1":
      return (
        <Layout title="Work Situation">
          <h1>Work Situation</h1>
          <Layout.MainBox description="Which of the following best describes your situation?">
            <RadioField
              name="workSituation"
              value={workSituation}
              options={[
                {
                  label: (
                    <LabelWithDescription title="1099 Only">
                      I’m an independent contractor who receives 1099 forms for
                      taxes.
                    </LabelWithDescription>
                  ),
                  value: "1099 Only"
                },
                {
                  label: (
                    <LabelWithDescription title="W-2 Only">
                      I’m a full-time employee who receives a W-2 for taxes.
                    </LabelWithDescription>
                  ),
                  value: "W-2 Only"
                },
                {
                  label: (
                    <LabelWithDescription title="1099 and W-2">
                      I’m an employee with a side gig. I receive both W-2 and
                      1099 forms.
                    </LabelWithDescription>
                  ),
                  value: "1099 and W-2"
                }
              ]}
              onChange={({ target: { value: newValue } }) => {
                setWorkSituation(newValue);
              }}
            />
          </Layout.MainBox>
          <Layout.Actions>
            <Button
              disabled={!workSituation}
              loading={loading}
              onClick={async () => {
                setLoading(true);
                await sendQuestionnaireData();
                history.push("/onboarding/questionnaire/2");
                setLoading(false);
              }}
            >
              Next
            </Button>
            <Button
              secondary
              onClick={() => {
                history.push("/onboarding");
              }}
            >
              Back
            </Button>
          </Layout.Actions>
        </Layout>
      );

    case "2":
      return (
        <Layout title="Revenue">
          <h1>Revenue</h1>
          <Layout.MainBox description="What is your anticipated total income from all jobs in 2020?">
            <Row>
              <Row.Col></Row.Col>
              <Row.Col>
                <TextField
                  value={revenue}
                  onChange={(event: any) => {
                    setRevenue(event.target.rawValue);
                  }}
                  placeholder="$90,000"
                  formatOptions={{
                    prefix: "$",
                    numeral: true,
                    numeralThousandsGroupStyle: "thousand",
                    rawValueTrimPrefix: true,
                    noImmediatePrefix: true
                  }}
                />
              </Row.Col>
              <Row.Col></Row.Col>
            </Row>
          </Layout.MainBox>
          <Layout.Actions>
            <Button
              disabled={!revenue}
              loading={loading}
              onClick={async () => {
                setLoading(true);
                await sendQuestionnaireData();
                history.push("/onboarding/questionnaire/3");
                setLoading(false);
              }}
            >
              Next
            </Button>
            <Button
              secondary
              onClick={() => {
                history.push("/onboarding/questionnaire/1");
              }}
            >
              Back
            </Button>
          </Layout.Actions>
        </Layout>
      );

    case "3":
      return (
        <Layout title="Expenses">
          <h1>Expenses</h1>
          <Layout.MainBox description="How much are your anticipated expenses from freelancing in 2020?">
            <Row>
              <Row.Col></Row.Col>
              <Row.Col>
                <TextField
                  value={expenses}
                  onChange={(event: any) => {
                    setExpenses(event.target.rawValue);
                  }}
                  placeholder="$5,000"
                  formatOptions={{
                    prefix: "$",
                    numeral: true,
                    numeralThousandsGroupStyle: "thousand",
                    rawValueTrimPrefix: true,
                    noImmediatePrefix: true,
                    numericOnly: true
                  }}
                />
              </Row.Col>
              <Row.Col></Row.Col>
            </Row>
          </Layout.MainBox>
          <Layout.Actions>
            <Button
              disabled={!expenses}
              loading={loading}
              onClick={async () => {
                setLoading(true);
                await sendQuestionnaireData();
                history.push("/onboarding/questionnaire/4");
                setLoading(false);
              }}
            >
              Next
            </Button>
            <Button
              secondary
              onClick={() => {
                history.push("/onboarding/questionnaire/2");
              }}
            >
              Back
            </Button>
          </Layout.Actions>
        </Layout>
      );

    case "4":
      return (
        <Layout title="Personal Details">
          <h1>Personal Details</h1>
          <Layout.MainBox description="Some final questions to get to know you better.">
            <Row>
              <Row.Col>
                <TextField
                  type="number"
                  label="Zip Code"
                  value={zipCode}
                  onChange={({ target: { value: newValue } }) => {
                    setZipCode(newValue);
                  }}
                  placeholder="12345"
                />
              </Row.Col>
              <Row.Col>
                <DateField
                  label="Date of Birth"
                  value={dob}
                  onChange={newValue => {
                    setDob(newValue);
                  }}
                />
              </Row.Col>
              <Row.Col>
                <DropDownField
                  label="Gender"
                  value={gender}
                  onChange={newValue => {
                    setGender(newValue);
                  }}
                  options={[
                    {
                      label: "Male",
                      value: "male"
                    },
                    {
                      label: "Female",
                      value: "female"
                    },
                    {
                      label: "Other / I'd rather not say",
                      value: "other"
                    }
                  ]}
                  placeholder="Choose One"
                />
              </Row.Col>
            </Row>
          </Layout.MainBox>
          <Layout.Actions>
            <Button
              disabled={!zipCode || !dob || !gender}
              loading={loading}
              onClick={async () => {
                setLoading(true);
                await sendQuestionnaireData();
                history.push("/onboarding/success");
                setLoading(false);
              }}
            >
              Next
            </Button>
            <Button
              secondary
              onClick={() => {
                history.push("/onboarding/questionnaire/3");
              }}
            >
              Back
            </Button>
          </Layout.Actions>
        </Layout>
      );

    default:
      return null;
  }
};

const mapStateToProps = (state: GlobalStore) => ({
  userAuthorized: getUserAuthorized(state),
  userQuestionnaire: getUserQuestionnaire(state)
});

export default connect(mapStateToProps)(Questionnaire);

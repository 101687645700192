import React from "react";
import classes from "./styles.module.scss";

interface Props {
  onClick: () => void;
}

const ThreeDotsButton: React.FC<Props> = ({ onClick }) => (
  <div className={classes.container} onClick={onClick}>
    <div className={classes.dot} />
    <div className={classes.dot} />
    <div className={classes.dot} />
  </div>
);

export default ThreeDotsButton;

import axios from "axios";
import moment from "moment";
import { GoalCreationConfig } from "../types/goals";
import { WizardStepConfig } from "../types/wizardStep";
import { getAuthToken } from "../utils/auth";

const request = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: "json",
  transformRequest: (body: Object, headers) => {
    const token = getAuthToken();
    if (token && token !== "undefined")
      headers["Authorization"] = `Token ${token}`;

    if (body && body.constructor.name === "FormData") {
      headers["Content-Type"] = "multipart/form-data";
      return body;
    } else return JSON.stringify(body);
  },
  headers: {
    "Content-Type": "application/json"
  },
  timeout: 50000,
  withCredentials: true
});

//
// Auth
//

export interface SignUpData {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}

export const signUp = async (requestData: SignUpData) => {
  const { data } = await request.post("/user/signup", requestData);

  return data;
};

export interface SignInData {
  email: string;
  password: string;
}

export const signIn = async (requestData: SignInData) => {
  const { data } = await request.post("/user/login", requestData);

  return data;
};

export const signOut = async () => {
  const { data } = await request.get("/user/logout");

  return data;
};

export const createPasswordReset = async (email: string) => {
  const { data } = await request.post("/user/password/reset", { email });
  return data;
};

export const validatePasswordReset = async (code: string) => {
  const { data } = await request.get(`/user/password/reset/${code}`);
  return data;
};

export const resetPasswordWithCode = async (code: string, password: string) => {
  const { data } = await request.put(`/user/password/reset/${code}`, {
    password
  });
  return data;
};

export const resetPassword = async (password: string, newPassword: string) => {
  const { data } = await request.put(`/user/password/reset`, {
    password,
    newPassword
  });
  return data;
};

//
// User profile
//

export type UserQuestionnaire = {
  name: string;
  type: string;
  response: any;
}[];

export interface UserFields {
  email: string;

  questionnaire?: UserQuestionnaire;
  paymentsEnabled?: boolean;
  tosAcceptanceDate?: string;
  tosAcceptanceIP?: string;

  // external references
  freshdeskContactId?: number;
  stripeRepresentative?: string;
  stripeAccount?: string;

  // person core
  firstName: string;
  lastName: string;
  nickName?: string;
  legalName?: string;
  dob?: Date;
  gender?: string;
  profilePhoto?: string;

  address?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  phone?: string;

  // External bank account
  accountNumber?: string;
  accountRoutingNumber?: string;
  accountHolderName?: string;

  // company information
  businessProfileMCC?: string;
  companyName?: string;
  companyTaxId?: string;
  description?: string;
  businessName?: string;
  businessAddress?: string;
  businessAddressLine2?: string;
  businessCity?: string;
  businessState?: string;
  businessZipCode?: string;
  businessPhone?: string;
  businessEmail?: string;
  businessProfileURL?: string;

  // person additional info
  filingType?: "personal" | "business";
  verificationDocumentFrontStripe?: string;
  verificationDocumentFront?: string;
  idNumber?: string;
  ssnLast4?: string;
  taxClassification?: string;
  spouse?: boolean;
  kids?: boolean;
  spouseAge?: number;
  spouseGender?: string;
  childOneAge?: number;
  childOneGender?: string;
  childTwoAge?: number;
  childTwoGender?: string;
  childAdditional?: boolean;
}

export interface UserProfileResponse extends UserFields {
  _id: string;
  id: number;
  roleId: number;
  goalsSubmitted: string[];
}

export type UpdateProfileData = Partial<UserFields>;

export const getUserProfile = async (): Promise<UserProfileResponse> => {
  const { data } = await request.get("/user/profile");

  if (data.dob) data.dob = moment.utc(data.dob.split(".")[0]).toDate();

  return data;
};

export const updateProfile = async (
  values: UpdateProfileData
): Promise<UserProfileResponse> => {
  const { data } = await request.post("/user/profile", values);
  return data;
};

//
// Questionnaire
//

export const getQuestionnaireConfig = async (): Promise<{
  questionnaire: {
    version: string;
    wizardSteps: WizardStepConfig[];
  };
}> => {
  const { data } = await request.get("/ui-config/questionnaire");
  return data;
};

//
// Create goal UI config
//

export const getGoalCreationConfig = async (): Promise<GoalCreationConfig> => {
  const {
    data: { dashboard }
  } = await request.get("/ui-config/dashboard");
  return dashboard;
};

//
// Goals
//

export const getTicket = async (id: string) => {
  const { data } = await request.get(`/chat/${id}`);
  return data;
};

export interface CreateTicketDataAttribute {
  type: "fieldValue" | "subHeader";
  title?: string;
  value: string;
  display: boolean;
}

export interface CreateTicketData {
  subject: string;
  description: string;
  categoryId: number;
  attributes: CreateTicketDataAttribute[];
  assets: string[];
  summary?: string;
  slug?: string;
}

export const createTicket = async (ticketData: CreateTicketData) => {
  const { data } = await request.post("/chat", ticketData);

  return data;
};

export const createTicketReply = async (id: string, text: string) => {
  const { data } = await request.post(`/chat/${id}/reply`, { text });

  return data;
};

export const getTickets = async () => {
  const { data } = await request.get("/tickets");
  return data;
};

export const getRecentActivity = async () => {
  const { data } = await request.get("/tickets");

  if (data.active) data.active.reverse();
  if (data.completed) data.completed.reverse();

  let result: any[] = data.active.slice(0, 3);
  if (result.length < 3)
    result = [...result, ...data.completed.slice(0, 3 - result.length)];

  return result;
};

//
// Assets
//

export interface Asset {
  id: string;
  fileName: string;
}

export const createAsset = async (file: File): Promise<Asset> => {
  const formData = new FormData();
  formData.append("files", file);

  const { data } = await request.post("/assets", formData);

  return {
    id: data.id,
    fileName: data.fileName
  };
};

export const attachAssetToChat = async (
  chatId: string,
  assetId: string
): Promise<Asset> => {
  const { data } = await request.post(`/chat/${chatId}/asset`, {
    assetId
  });

  return data;
};

//
// Subscription
//

export interface Subscription {
  subscriptionId: string;
  planId: string;
  planNickname: string;
  status: string;
  cancelAtPeriodEnd?: boolean;
  currentPeriodEnd?: Date;
  currentPeriodStart?: Date;
  latestInvoiceId?: string;
  trialEnd: Date;
}

export interface SubscriptionPaymentMethod {
  cardHolderName: string;
  expiryDate: string;
  last4: string;
  paymentMethodId: string;
}

export interface SubscriptionPlan {
  amount: number;
  currency: string;
  interval: "day" | "week" | "month" | "year";
  planId: string;
  title: string;
  trialPeriodDays: number;
}

export const getSubscription = async (): Promise<Subscription> => {
  const { data } = await request.get("/user/membership/subscription");

  return {
    subscriptionId: data.subscriptionId,
    planId: data.planId,
    planNickname: data.planNickname,
    status: data.subscriptistatusonId,
    cancelAtPeriodEnd: data.cancelAtPeriodEnd || undefined,
    currentPeriodEnd: data.currentPeriodEnd
      ? new Date(data.currentPeriodEnd)
      : undefined,
    currentPeriodStart: data.currentPeriodStart
      ? new Date(data.currentPeriodStart)
      : undefined,
    latestInvoiceId: data.latestInvoiceId || undefined,
    trialEnd: new Date(data.trialEnd)
  };
};

// Update or Create subscription if it's not exist
export const updateSubscription = async (
  planId: string
): Promise<Subscription> => {
  const { data } = await request.post("/user/membership/subscription", {
    planId
  });
  return data;
};

export const getSubscriptionPaymentMethod = async (): Promise<SubscriptionPaymentMethod> => {
  const { data } = await request.get("/user/membership/payment-method");
  return data;
};

// Update or Create subscription billing if it's not exist
export const updateSubscriptionPaymentMethod = async (
  stripeToken: string
): Promise<SubscriptionPaymentMethod> => {
  const { data } = await request.post("/user/membership/payment-method", {
    paymentMethodId: stripeToken
  });
  return data;
};

export interface SubscriptionInvoice {
  invoiceId: string;
  invoiceStatus: "draft" | "open" | "paid" | "uncollectible" | "void";
  amountPaid: number;
  paidAt: Date;
  nextPaymentAttempt: Date | null;
  periodStart: Date;
  periodEnd: Date;
  invoicePdf: string;
  startingBalance: number;
  charge?: {
    chargeId: string;
    chargeStatus: "succeeded" | "pending" | "failed";
    amount: number;
    cardLast4: string;
    created: Date;
  };
}

export const getSubscriptionPaymentHistory = async (): Promise<SubscriptionInvoice[]> => {
  const { data } = await request.get("/user/membership/payment-history");
  return data;
};

export const deleteSubscription = async () => {
  const { data } = await request.delete("/user/membership/subscription");
  return data;
};

export const getSubscriptionPlans = async (): Promise<SubscriptionPlan[]> => {
  const { data } = await request.get("/user/membership/plans");
  return data;
};

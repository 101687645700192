function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from "react";
export default (({
  styles = {},
  ...props
}) => React.createElement("svg", _extends({
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  xmlns: "http://www.w3.org/2000/svg",
  xmlnsXlink: "http://www.w3.org/1999/xlink"
}, props), React.createElement("defs", null, React.createElement("path", {
  d: "M9.5 5.75V16.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V4a2.5 2.5 0 015 0v10.5c0 .55-.45 1-1 1s-1-.45-1-1V5.75a.75.75 0 00-1.5 0v8.75a2.5 2.5 0 005 0V4c0-2.21-1.79-4-4-4S0 1.79 0 4v12.5C0 19.54 2.46 22 5.5 22s5.5-2.46 5.5-5.5V5.75a.75.75 0 10-1.5 0z",
  id: "a"
})), React.createElement("g", {
  fill: "none",
  fillRule: "evenodd"
}, React.createElement("path", {
  d: "M0 0h24v24H0z"
}), React.createElement("g", {
  transform: "translate(7 1)"
}, React.createElement("mask", {
  id: "b",
  fill: "#fff"
}, React.createElement("use", {
  xlinkHref: "#a"
})), React.createElement("use", {
  fill: "#6B727F",
  fillRule: "nonzero",
  xlinkHref: "#a"
}), React.createElement("g", {
  mask: "url(#b)"
}, React.createElement("path", {
  fill: "#FFF",
  d: "M-7-1h24v24H-7z"
}), React.createElement("path", {
  fill: "#6B727F",
  d: "M-7-1h24v24H-7z"
}))))));
import { Form, Formik } from "formik";
import React from "react";
import { connect, useDispatch } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";
import * as Yup from "yup";
import { signIn } from "../store/actions/signIn";
import Button from "../components/Button";
import Card from "../components/Card";
import ErrorMessage from "../components/ErrorMessage";
import MainLayout from "../components/MainLayout";
import TextField from "../components/TextField";
import { getMetaIsLoading } from "../store/meta/selectors";
import { GlobalStore } from "../types/store";
import { SIGN_IN } from "../store/constants";

type StateProps = ReturnType<typeof mapStateToProps>;

type RouteProps = RouteComponentProps;

type Props = RouteProps & StateProps;

const LogIn: React.FC<Props> = ({ loading }) => {
  const dispatch = useDispatch();

  return (
    <MainLayout title="Sign In" currentPage="login">
      <MainLayout.PageHeader backgroundColor="#E6D7EB">
        <h1 className="hero">Sign In</h1>
        <p>Enter your account details and let's get to work.</p>
      </MainLayout.PageHeader>
      <MainLayout.Main>
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Please enter a valid email address")
              .required("Email is required"),
            password: Yup.string().required("Password is required")
          })}
          onSubmit={async (values, { setSubmitting }) => {
            dispatch(signIn(values));
            setSubmitting(false);
          }}
        >
          {({ handleSubmit, values, handleChange, touched, errors }) => (
            <Form>
              <Card>
                <Card.Main>
                  <ErrorMessage actionTypePrefix={SIGN_IN} />

                  <TextField
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    label="Email Address"
                    required
                    error={
                      touched.email && errors.email ? errors.email : undefined
                    }
                  />

                  <TextField
                    type="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    label="Password"
                    required
                    error={
                      touched.password && errors.password
                        ? errors.password
                        : undefined
                    }
                  />
                </Card.Main>
              </Card>

              <p className="modest" style={{ textAlign: "center" }}>
                Forgot Your Password?{" "}
                <Link to="/reset-password">Reset Password</Link>
              </p>
              <MainLayout.Actions>
                <Button
                  loading={loading}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Sign In
                </Button>
                <p className="secondaryAction">
                  Not a Member Yet? <Link to="/register">Sign Up</Link>
                </p>
              </MainLayout.Actions>
            </Form>
          )}
        </Formik>
      </MainLayout.Main>
    </MainLayout>
  );
};

const mapStateToProps = (state: GlobalStore) => ({
  loading: getMetaIsLoading(state, SIGN_IN)
});

export default connect(mapStateToProps)(LogIn);

import React from "react";
import { connect } from "react-redux";
import { getInvoicesIsSent } from "../../store/selectors/invoices";
import { getPaymentsStatus } from "../../store/selectors/payments";
import { GlobalStore } from "../../types/store";
import Card from "../Card";
import CircularProgress from "../CircularProgress";
import List from "../List";
import ListItem from "./ListItem";
import classes from "./styles.module.scss";

type MapStateToProps = ReturnType<typeof mapStateToProps>;
type Props = MapStateToProps;

const GetStartedWidget: React.FC<Props> = ({
  userGoalsSubmitted,
  invoicesIsSent,
  paymentStatus
}) => {
  const list: {
    link: string;
    label: string;
    completed: boolean;
    score: number;
  }[] = [
    {
      link: "/invoices/set-up",
      label: "Set up payments",
      completed: paymentStatus,
      score: 25
    },
    {
      link: paymentStatus ? "/invoices/create" : "/invoices/set-up",
      label: "Create Invoice",
      completed: invoicesIsSent,
      score: 25
    },
    // {
    //   link: "/dashboard/legal/business-expenses",
    //   label: "Automate Expenses Management",
    //   completed: userGoalsSubmitted.includes("business-expenses"),
    //   score: 25
    // },
    {
      link: "/dashboard/benefits-assessment",
      label: "Manage Benefits",
      completed: userGoalsSubmitted.includes("benefits-assessment"),
      score: 25
    },
    {
      link: "/dashboard/legal/incorporate",
      label: "Get Incorporated",
      completed: userGoalsSubmitted.includes("incorporate"),
      score: 25
    }
  ];

  const percent = list.reduce(
    (total, item) => (item.completed ? total + item.score : total),
    0
  );

  return percent < 100 ? (
    <Card>
      <Card.Header>
        Get Started with Wingspan
        <div className={classes.progress}>
          <CircularProgress percentage={percent} />
        </div>
      </Card.Header>
      <Card.Main noPadding>
        <List>
          {list.map(item => (
            <List.Item key={item.label} link={item.link}>
              <ListItem complete={item.completed}>{item.label}</ListItem>
            </List.Item>
          ))}
        </List>
      </Card.Main>
    </Card>
  ) : null;
};

const mapStateToProps = (state: GlobalStore) => ({
  userGoalsSubmitted: state.user.data?.goalsSubmitted || [],
  invoicesIsSent: getInvoicesIsSent(state),
  paymentStatus: getPaymentsStatus(state)
});

export default connect(mapStateToProps)(GetStartedWidget);

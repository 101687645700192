import { GlobalStore } from "../../types/store";

export const getMembershipInfo = (state: GlobalStore) => state.membership.info;

export const getMembershipPlanId = (state: GlobalStore) =>
  state.membership.info?.planId;

export const getMembershipPaymentMethod = (state: GlobalStore) =>
  state.membership.paymentMethod;

export const getMembershipPaymentHistory = (state: GlobalStore) =>
  state.membership.paymentHistory;

export const getMembershipPlans = (state: GlobalStore) =>
  state.membership.plans;

export const getSubscriptionModalVisibility = (state: GlobalStore) =>
  state.membership.subscribeModalVisible;

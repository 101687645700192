import React from "react";
import classes from "./styles.module.scss";

interface Props {
  monthlyPrice: number;
  interval: string;
  annualSavings?: string;
}

const Label: React.FC<Props> = ({ monthlyPrice, interval, annualSavings }) => (
  <div className={classes.label}>
    {annualSavings && (
      <div className={classes.hint}>SAVE ${annualSavings} PER YEAR!</div>
    )}
    <b>${monthlyPrice} Monthly Price </b> &nbsp;&nbsp;|&nbsp;&nbsp; Billed{" "}
    {interval === "year" ? "Annually" : "Monthly"}
  </div>
);

export default Label;

import React, { ChangeEventHandler } from "react";
import Columns from "../Columns";
import TextField from "../TextField";

interface Props {
  values: {
    name: string;
    card: string;
    expirationDate: string;
    cvv: string;
    zip: string;
  };
  errors: {
    name?: string;
    card?: string;
    expirationDate?: string;
    cvv?: string;
    zip?: string;
  };
  touched: {
    name?: boolean;
    card?: boolean;
    expirationDate?: boolean;
    cvv?: boolean;
    zip?: boolean;
  };
  onChange: ChangeEventHandler;
}

const PaymentFields: React.FC<Props> = ({
  values,
  onChange,
  touched,
  errors
}) => (
  <>
    <TextField
      label="Name on Card"
      name="name"
      value={values.name}
      onChange={onChange}
      error={touched.name && errors.name ? errors.name : undefined}
      required
    />

    <TextField
      label="Card Number"
      name="card"
      value={values.card}
      onChange={onChange}
      formatOptions={{
        creditCard: true
      }}
      error={touched.card && errors.card ? errors.card : undefined}
      required
    />

    <Columns forceMobile>
      <Columns.Column>
        <TextField
          label="Expiration Date"
          name="expirationDate"
          value={values.expirationDate}
          onChange={onChange}
          formatOptions={{
            date: true,
            datePattern: ["m", "y"]
          }}
          error={
            touched.expirationDate && errors.expirationDate
              ? errors.expirationDate
              : undefined
          }
          required
        />
      </Columns.Column>
      <Columns.Column>
        <TextField
          label="CVV Code"
          maxLength={3}
          formatOptions={{
            numericOnly: true
          }}
          name="cvv"
          value={values.cvv}
          onChange={onChange}
          error={touched.cvv && errors.cvv ? errors.cvv : undefined}
          required
        />
      </Columns.Column>
    </Columns>

    <TextField
      label="Billing Zip"
      maxLength={5}
      formatOptions={{
        numericOnly: true
      }}
      name="zip"
      value={values.zip}
      onChange={onChange}
      error={touched.zip && errors.zip ? errors.zip : undefined}
      required
    />
  </>
);

export default PaymentFields;

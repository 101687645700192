import { Field, FieldProps, getIn } from "formik";
import React from "react";
import SwitchCheckboxField, { SwitchCheckboxFieldProps } from ".";

interface Props
  extends Omit<SwitchCheckboxFieldProps, "value" | "error" | "onChange"> {
  name: string;
}

const FormikSwitchCheckboxField: React.FC<Props> = ({ name, ...props }) => (
  <Field name={name}>
    {({ field, form }: FieldProps) => (
      <SwitchCheckboxField
        value={field.value}
        onChange={value => {
          form.setFieldValue(field.name, value);
        }}
        error={
          getIn(form.touched, name) && getIn(form.errors, name)
            ? getIn(form.errors, name)
            : undefined
        }
        {...props}
      />
    )}
  </Field>
);

export default FormikSwitchCheckboxField;

import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import React from "react";
import FieldContainer from "../FieldContainer";
import Label from "../Label";

interface Props {
  options: {
    label?: string | React.ReactNode;
    value: string | number;
  }[];
  value: any[];
  label?: string;
  onChange: (value: (string | number)[]) => void;
  error?: string;
  required?: boolean;
}

const CheckBoxField: React.FC<Props> = ({
  value,
  options,
  label,
  onChange,
  error,
  required
}) => (
  <FieldContainer label={label} required={required} error={error}>
    <FormGroup>
      {options.map(option => (
        <FormControlLabel
          key={option.value}
          control={
            <Checkbox
              checked={value.includes(option.value)}
              onChange={({ target: { checked } }) => {
                onChange(
                  checked
                    ? [...value, option.value]
                    : value.filter(item => item !== option.value)
                );
              }}
              value={option.value}
            />
          }
          label={
            option.label ? (
              typeof option.label === "string" ? (
                <Label>{option.label}</Label>
              ) : (
                option.label
              )
            ) : (
              <Label>{option.value}</Label>
            )
          }
        />
      ))}
    </FormGroup>
  </FieldContainer>
);

export default CheckBoxField;

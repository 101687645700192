import React from "react";
import { connect } from "react-redux";
import { getGoalCreationConfig } from "../../store/selectors/goals";
import { GlobalStore } from "../../types/store";
import Card from "../Card";
import List from "../List";
import ListItem from "./ListItem";

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = StateProps;

const CreateNewGoalWidget: React.FC<Props> = ({ config }) => (
  <Card>
    {/*<Card.Header>Create a New Goal</Card.Header>*/}
    <Card.Main noPadding>
      <List>
        {config.sections.map(section => (
          <List.Item
            key={section.slug}
            link={`/dashboard/${section.slug}`}
            withIcon
          >
            <ListItem icon={section.menuIcon}>{section.menuTitle}</ListItem>
          </List.Item>
        ))}
      </List>
    </Card.Main>
  </Card>
);

const mapStateToProps = (state: GlobalStore) => ({
  config: getGoalCreationConfig(state)
});

export default connect(mapStateToProps)(CreateNewGoalWidget);

export const COPY_INVOICE_LINK = "COPY_INVOICE_LINK";
export const COPY_INVOICE_LINK_REQUEST = "COPY_INVOICE_LINK_REQUEST";
export const COPY_INVOICE_LINK_SUCCESS = "COPY_INVOICE_LINK_SUCCESS";
export const COPY_INVOICE_LINK_FAILURE = "COPY_INVOICE_LINK_FAILURE";

export const DOWNLOAD_INVOICE_PDF = "DOWNLOAD_INVOICE_PDF";
export const DOWNLOAD_INVOICE_PDF_REQUEST = "DOWNLOAD_INVOICE_PDF_REQUEST";
export const DOWNLOAD_INVOICE_PDF_SUCCESS = "DOWNLOAD_INVOICE_PDF_SUCCESS";
export const DOWNLOAD_INVOICE_PDF_FAILURE = "DOWNLOAD_INVOICE_PDF_FAILURE";

export const SEND_INVOICE_REMINDER = "SEND_INVOICE_REMINDER";
export const SEND_INVOICE_REMINDER_REQUEST = "SEND_INVOICE_REMINDER_REQUEST";
export const SEND_INVOICE_REMINDER_SUCCESS = "SEND_INVOICE_REMINDER_SUCCESS";
export const SEND_INVOICE_REMINDER_FAILURE = "SEND_INVOICE_REMINDER_FAILURE";

export const DELETE_INVOICE = "DELETE_INVOICE";
export const DELETE_INVOICE_REQUEST = "DELETE_INVOICE_REQUEST";
export const DELETE_INVOICE_SUCCESS = "DELETE_INVOICE_SUCCESS";
export const DELETE_INVOICE_FAILURE = "DELETE_INVOICE_FAILURE";

export const CANCEL_INVOICE = `CANCEL_INVOICE`;
export const CANCEL_INVOICE_REQUEST = `CANCEL_INVOICE_REQUEST`;
export const CANCEL_INVOICE_SUCCESS = `CANCEL_INVOICE_SUCCESS`;
export const CANCEL_INVOICE_FAILURE = `CANCEL_INVOICE_FAILURE`;

export const CREATE_AND_SEND_INVOICE = "CREATE_AND_SEND_INVOICE";
export const CREATE_AND_SEND_INVOICE_REQUEST =
  "CREATE_AND_SEND_INVOICE_REQUEST";
export const CREATE_AND_SEND_INVOICE_SUCCESS =
  "CREATE_AND_SEND_INVOICE_SUCCESS";
export const CREATE_AND_SEND_INVOICE_FAILURE =
  "CREATE_AND_SEND_INVOICE_FAILURE";

export const CREATE_INVOICE = "CREATE_INVOICE";
export const CREATE_INVOICE_REQUEST = "CREATE_INVOICE_REQUEST";
export const CREATE_INVOICE_SUCCESS = "CREATE_INVOICE_SUCCESS";
export const CREATE_INVOICE_FAILURE = "CREATE_INVOICE_FAILURE";

export const UPDATE_INVOICE = "UPDATE_INVOICE";
export const UPDATE_INVOICE_REQUEST = "UPDATE_INVOICE_REQUEST";
export const UPDATE_INVOICE_SUCCESS = "UPDATE_INVOICE_SUCCESS";
export const UPDATE_INVOICE_FAILURE = "UPDATE_INVOICE_FAILURE";

export const INIT_APP = "INIT_APP";
export const INIT_APP_REQUEST = "INIT_APP_REQUEST";
export const INIT_APP_SUCCESS = "INIT_APP_SUCCESS";
export const INIT_APP_FAILURE = "INIT_APP_FAILURE";
export const FETCH_GOAL_CREATION_CONFIG = "FETCH_GOAL_CREATION_CONFIG";
export const FETCH_GOAL_CREATION_CONFIG_REQUEST =
  "FETCH_GOAL_CREATION_CONFIG_REQUEST";
export const FETCH_GOAL_CREATION_CONFIG_SUCCESS =
  "FETCH_GOAL_CREATION_CONFIG_SUCCESS";
export const FETCH_GOAL_CREATION_CONFIG_FAILURE =
  "FETCH_GOAL_CREATION_CONFIG_FAILURE";
export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";
export const FETCH_USER_UPDATE = "FETCH_USER_UPDATE";
export const FETCH_USER_UPDATE_REQUEST = "FETCH_USER_UPDATE_REQUEST";
export const FETCH_USER_UPDATE_SUCCESS = "FETCH_USER_UPDATE_SUCCESS";
export const FETCH_USER_UPDATE_FAILURE = "FETCH_USER_UPDATE_FAILURE";
export const SIGN_UP = "SIGN_UP";
export const SIGN_UP_REQUEST = "SIGN_UP_REQUEST";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_FAILURE = "SIGN_UP_FAILURE";
export const SIGN_IN = "SIGN_IN";
export const SIGN_IN_REQUEST = "SIGN_IN_REQUEST";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_FAILURE = "SIGN_IN_FAILURE";
export const SIGN_OUT = "SIGN_OUT";
export const SIGN_OUT_REQUEST = "SIGN_OUT_REQUEST";
export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";
export const SIGN_OUT_FAILURE = "SIGN_OUT_FAILURE";
export const FETCH_PAYMENTS_STATUS = "FETCH_PAYMENTS_STATUS";
export const FETCH_PAYMENTS_STATUS_REQUEST = "FETCH_PAYMENTS_STATUS_REQUEST";
export const FETCH_PAYMENTS_STATUS_SUCCESS = "FETCH_PAYMENTS_STATUS_SUCCESS";
export const FETCH_PAYMENTS_STATUS_FAILURE = "FETCH_PAYMENTS_STATUS_FAILURE";
export const FETCH_MCCS = "FETCH_MCCS";
export const FETCH_MCCS_REQUEST = "FETCH_MCCS_REQUEST";
export const FETCH_MCCS_SUCCESS = "FETCH_MCCS_SUCCESS";
export const FETCH_MCCS_FAILURE = "FETCH_MCCS_FAILURE";
export const FETCH_MEMBER_CLIENT_LIST_REQUEST =
  "FETCH_MEMBER_CLIENT_LIST_REQUEST";
export const FETCH_MEMBER_CLIENT_LIST_SUCCESS =
  "FETCH_MEMBER_CLIENT_LIST_SUCCESS";
export const FETCH_MEMBER_CLIENT_LIST_FAILURE =
  "FETCH_MEMBER_CLIENT_LIST_FAILURE";
export const FETCH_MEMBER_CLIENT_DETAILS_REQUEST =
  "FETCH_MEMBER_CLIENT_DETAILS_REQUEST";
export const FETCH_MEMBER_CLIENT_DETAILS_SUCCESS =
  "FETCH_MEMBER_CLIENT_DETAILS_SUCCESS";
export const FETCH_MEMBER_CLIENT_DETAILS_FAILURE =
  "FETCH_MEMBER_CLIENT_DETAILS_FAILURE";
export const FETCH_CREATE_MEMBER_CLIENT_REQUEST =
  "FETCH_CREATE_MEMBER_CLIENT_REQUEST";
export const FETCH_CREATE_MEMBER_CLIENT_SUCCESS =
  "FETCH_CREATE_MEMBER_CLIENT_SUCCESS";
export const FETCH_CREATE_MEMBER_CLIENT_FAILURE =
  "FETCH_CREATE_MEMBER_CLIENT_FAILURE";
export const FETCH_INVOICE_LIST = "FETCH_INVOICE_LIST";
export const FETCH_INVOICE_LIST_REQUEST = "FETCH_INVOICE_LIST_REQUEST";
export const FETCH_INVOICE_LIST_SUCCESS = "FETCH_INVOICE_LIST_SUCCESS";
export const FETCH_INVOICE_LIST_FAILURE = "FETCH_INVOICE_LIST_FAILURE";
export const FETCH_MEMBERSHIP = "FETCH_MEMBERSHIP";
export const FETCH_MEMBERSHIP_REQUEST = "FETCH_MEMBERSHIP_REQUEST";
export const FETCH_MEMBERSHIP_SUCCESS = "FETCH_MEMBERSHIP_SUCCESS";
export const FETCH_MEMBERSHIP_FAILURE = "FETCH_MEMBERSHIP_FAILURE";
export const FETCH_MEMBERSHIP_PLANS = "FETCH_MEMBERSHIP_PLANS";
export const FETCH_MEMBERSHIP_PLANS_REQUEST = "FETCH_MEMBERSHIP_PLANS_REQUEST";
export const FETCH_MEMBERSHIP_PLANS_SUCCESS = "FETCH_MEMBERSHIP_PLANS_SUCCESS";
export const FETCH_MEMBERSHIP_PLANS_FAILURE = "FETCH_MEMBERSHIP_PLANS_FAILURE";
export const SET_SUBSCRIBE_MODAL_VISIBLE = "SET_SUBSCRIBE_MODAL_VISIBLE";

export const OPEN_INVOICE_PREVIEW = "OPEN_INVOICE_PREVIEW";
export const CLOSE_INVOICE_PREVIEW = "CLOSE_INVOICE_PREVIEW";

import { createAction } from "../meta/types";
import { InvoiceResponse } from "../../types/paymentsService";
import {
  FETCH_INVOICE_LIST,
  FETCH_INVOICE_LIST_FAILURE,
  FETCH_INVOICE_LIST_REQUEST,
  FETCH_INVOICE_LIST_SUCCESS
} from "../constants";

export const fetchInvoiceListActions = createAction(
  FETCH_INVOICE_LIST,
  FETCH_INVOICE_LIST_REQUEST,
  FETCH_INVOICE_LIST_SUCCESS,
  FETCH_INVOICE_LIST_FAILURE
)<
  void,
  {
    byId: {
      [id: string]: InvoiceResponse;
    };
    listIds: string[];
    stats: {
      paid: number;
      outstanding: number;
    };
  },
  { error: Error }
>();

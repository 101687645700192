import React from "react";
import { Edit } from "@material-ui/icons";
import classes from "./styles.module.scss";

interface Props {
  onClick: () => void;
}

const EditButton: React.FC<Props> = ({ onClick }) => (
  <div className={classes.editButton}>
    <Edit
      onClick={() => {
        onClick();
      }}
    />
  </div>
);

export default EditButton;

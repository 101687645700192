import * as apiService from "../../services/api";
import { fetchMembershipPlansActions } from "./membershipActions";
import { AppThunk } from "../../types/store";

export const fetchMembershipPlans = (): AppThunk<any> => async dispatch => {
  dispatch(fetchMembershipPlansActions.request());

  try {
    const plans = await apiService.getSubscriptionPlans();

    dispatch(
      fetchMembershipPlansActions.success({
        plans
      })
    );
  } catch (error) {
    dispatch(fetchMembershipPlansActions.failure(error));
  }
};

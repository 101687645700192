import { Reducer } from "redux";
import { InferValueTypes } from "../../types/utils";
import * as actionCreators from "../actions/memberClientsActions";
import { MemberClientsStore } from "../../types/store";
import {
  FETCH_MEMBER_CLIENT_DETAILS_FAILURE,
  FETCH_MEMBER_CLIENT_DETAILS_REQUEST,
  FETCH_MEMBER_CLIENT_DETAILS_SUCCESS,
  FETCH_MEMBER_CLIENT_LIST_FAILURE,
  FETCH_MEMBER_CLIENT_LIST_REQUEST,
  FETCH_MEMBER_CLIENT_LIST_SUCCESS
} from "../constants";

export type MemberClientsActions = ReturnType<
  InferValueTypes<typeof actionCreators>
>;

export const getDefaultMemberClients: () => MemberClientsStore = () => ({
  byId: {},
  listIds: [],
  meta: {
    loading: false
  }
});

export const memberClients: Reducer<
  MemberClientsStore,
  MemberClientsActions
> = (state = getDefaultMemberClients(), action) => {
  switch (action.type) {
    case FETCH_MEMBER_CLIENT_LIST_REQUEST:
      return {
        ...state,
        meta: {
          loading: true
        }
      };

    case FETCH_MEMBER_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          ...action.payload.byId
        },
        listIds: action.payload.listIds,
        meta: {
          loading: false
        }
      };

    case FETCH_MEMBER_CLIENT_LIST_FAILURE:
      return {
        ...state,
        meta: {
          loading: false,
          error: action.payload.error
        }
      };

    case FETCH_MEMBER_CLIENT_DETAILS_REQUEST: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.memberClientId]: {
            meta: { loading: true }
          }
        }
      };
    }
    case FETCH_MEMBER_CLIENT_DETAILS_SUCCESS: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.memberClientId]: {
            meta: { loading: false },
            data: action.payload.memberClient
          }
        }
      };
    }
    case FETCH_MEMBER_CLIENT_DETAILS_FAILURE: {
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.memberClientId]: {
            meta: {
              loading: false,
              error: action.payload.error
            }
          }
        }
      };
    }
    default: {
      return state;
    }
  }
};

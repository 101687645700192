import React from "react";
import classes from "./styles.module.scss";
import classNames from "classnames";

interface Props {
  selected: boolean;
  mostPopular: boolean;
  monthlyPrice: number;
  interval: string;
  annualSavings?: number;
  onClick: () => void;
}

const PlanOption: React.FC<Props> = ({
  selected,
  mostPopular,
  monthlyPrice,
  interval,
  annualSavings,
  onClick
}) => (
  <div
    className={classNames(classes.planOption, { [classes.selected]: selected })}
    onClick={onClick}
  >
    {mostPopular && <div className={classes.label}>Most Popular!</div>}
    <div className={classes.price}>${monthlyPrice}/mo</div>
    <div className={classes.description}>
      Billed {interval === "year" ? "Annually" : "Monthly"}
      {annualSavings && (
        <>
          {" "}
          – <span>Save ${annualSavings} per year!</span>
        </>
      )}
    </div>
  </div>
);

export default PlanOption;

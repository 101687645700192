import { getInvoiceDataById } from "./invoices";
import { getMemberClientDataById } from "./memberClients";
import { GlobalStore } from "../../types/store";

export const getMemberClientDataByInvoiceId = (
  state: GlobalStore,
  invoiceId: string
) => {
  const invoiceData = getInvoiceDataById(state, invoiceId);
  if (invoiceData?.memberClientId) {
    return getMemberClientDataById(state, invoiceData.memberClientId);
  }

  return undefined;
};

import { SwipeableDrawer } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { signOut } from "../../../store/actions/signOut";
import { getUserName } from "../../../store/selectors/user";
import { GlobalStore } from "../../../types/store";
import URL from "../../../types/url";
import ProfilePhoto from "../../ProfilePhoto";
import classes from "./styles.module.scss";

type OwnProps = {
  visible: boolean;
  onClose: () => void;
};

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StateProps;

const Drawer: React.FC<Props> = ({ visible, onClose, userName }) => {
  const dispatch = useDispatch();

  return (
    <SwipeableDrawer open={visible} onClose={onClose} onOpen={() => {}}>
      <div className={classes.drawer}>
        <div className={classes.drawerHeader}>
          <Link to="/profile">
            <ProfilePhoto className={classes.avatar} name={userName} />
          </Link>
          <div className={classes.profileName}>
            <Link to="/profile">{userName}</Link>
          </div>
        </div>

        <div className={classes.drawerContent}>
          <div className={classes.drawerNav}>
            <ul>
              <li>
                <Link
                  to="/dashboard"
                  className={classNames({
                    [classes.selected]: window.location.pathname.includes(
                      "/dashboard"
                    )
                  })}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/goals"
                  className={classNames({
                    [classes.selected]: window.location.pathname.includes(
                      "/gols"
                    )
                  })}
                >
                  Goals
                </Link>
              </li>
              <li>
                <Link
                  to="/profile"
                  className={classNames({
                    [classes.selected]: window.location.pathname.includes(
                      "/profile"
                    )
                  })}
                >
                  Profile
                </Link>
              </li>
              <li>
                <Link
                  to="/team"
                  className={classNames({
                    [classes.selected]: window.location.pathname.includes(
                      "/team"
                    )
                  })}
                >
                  Your Team
                </Link>
              </li>
              <li>
                <Link
                  to="/invoices"
                  className={classNames({
                    [classes.selected]: window.location.pathname.includes(
                      "/invoices"
                    )
                  })}
                >
                  Invoices
                </Link>
              </li>
            </ul>
          </div>

          <div className={classes.drawerFooter}>
            <button
              className={classes.linkSmall}
              onClick={async () => {
                dispatch(signOut());
              }}
            >
              Sign Out
            </button>
            <br />
            <a className={classes.linkSmall} href={URL.termsOfService}>
              Terms &amp; Conditions
            </a>
            <a className={classes.linkSmall} href={URL.privacyPolicy}>
              Privacy Policy
            </a>
            <br />
            <span
              className={classNames(classes.linkSmall, classes.companyName)}
            >
              &copy;Wingspan Networks, Inc.
            </span>
          </div>
        </div>
      </div>
    </SwipeableDrawer>
  );
};

const mapStateToProps = (state: GlobalStore) => ({
  userName: getUserName(state)
});

export default connect(mapStateToProps)(Drawer);

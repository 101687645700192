import axios from "axios";
import qs from "qs";
import {
  InvoiceCreateData,
  InvoiceResponse,
  InvoicesFilter,
  InvoiceUpdateData,
  MemberClientCreateData,
  MemberClientResponse,
  MemberClientUpdateData,
  PaymentsIndustry,
  PaymentsStatus
} from "../types/paymentsService";
import { getAuthToken } from "../utils/auth";

const request = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/payments`,
  responseType: "json",
  transformRequest: (body: Object, headers) => {
    const token = getAuthToken();
    if (token && token !== "undefined")
      headers["Authorization"] = `Token ${token}`;

    if (body && body.constructor.name === "FormData") {
      headers["Content-Type"] = "multipart/form-data";
      return body;
    } else return JSON.stringify(body);
  },
  headers: {
    "Content-Type": "application/json"
  },
  timeout: 50000,
  withCredentials: true,
  paramsSerializer: function(params) {
    return qs.stringify(params);
  }
});

const getStatus = async (): Promise<PaymentsStatus> => {
  const { data } = await request.get("/service");
  return data;
};

const getMCCs = async (): Promise<PaymentsIndustry[]> => {
  const { data } = await request.get("/mcc");
  return data;
};

const enable = async (userId: string): Promise<PaymentsStatus> => {
  const { data } = await request.patch(`/service/${userId}`);
  return data;
};

/* Member Clients */

const getMemberClients = async (): Promise<MemberClientResponse[]> => {
  const { data } = await request.get(`/memberClient`);
  return data;
};

const getMemberClient = async (
  memberClientId: string
): Promise<MemberClientResponse> => {
  const { data } = await request.get(`/memberClient/${memberClientId}`);
  return data;
};

const createMemberClient = async (
  requestData: MemberClientCreateData
): Promise<MemberClientResponse> => {
  const { data } = await request.post(`/memberClient`, requestData);
  return data;
};

const updateMemberClient = async (
  memberClientId: string,
  requestData: MemberClientUpdateData
): Promise<MemberClientResponse> => {
  const { data } = await request.patch(
    `/memberClient/${memberClientId}`,
    requestData
  );
  return data;
};

const deleteMemberClient = async (memberClientId: string) => {
  const { data } = await request.delete(`/memberClient/${memberClientId}`);
  return data;
};

/* Invoices */

const getInvoices = async (
  filter?: InvoicesFilter
): Promise<InvoiceResponse[]> => {
  const { data } = await request.get(`/invoice`, {
    params: {
      filter
    }
  });
  return data;
};

const getInvoice = async (invoiceId: string): Promise<InvoiceResponse> => {
  const { data } = await request.get(`/invoice/${invoiceId}`);
  return data;
};

const createInvoice = async (
  requestData: InvoiceCreateData
): Promise<InvoiceResponse> => {
  const { data } = await request.post(`/invoice`, requestData);
  return data;
};

const updateInvoice = async (
  invoiceId: string,
  requestData: InvoiceUpdateData
) => {
  const { data } = await request.patch(`/invoice/${invoiceId}`, requestData);
  return data;
};

const deleteInvoice = async (invoiceId: string) => {
  const { data } = await request.delete(`/invoice/${invoiceId}`);
  return data;
};

const cancelInvoice = async (invoiceId: string) => {
  const { data } = await request.patch(`/invoice/${invoiceId}`, {
    status: "Cancelled"
  });
  return data;
};

const paymentsService = {
  getStatus,
  getMCCs,
  enable,
  getMemberClients,
  getMemberClient,
  createMemberClient,
  updateMemberClient,
  deleteMemberClient,
  getInvoices,
  getInvoice,
  createInvoice,
  updateInvoice,
  deleteInvoice,
  cancelInvoice
};

export default paymentsService;

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from "react";
export default (({
  styles = {},
  ...props
}) => React.createElement("svg", _extends({
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}, props), React.createElement("path", {
  fillRule: "evenodd",
  clipRule: "evenodd",
  d: "M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z",
  fill: "#fff"
}), React.createElement("path", {
  d: "M21.5 12a9.5 9.5 0 11-19 0 9.5 9.5 0 0119 0z",
  stroke: "#4CB0F3"
}), React.createElement("path", {
  d: "M12 6v6l3 3",
  stroke: "#4CB0F3",
  strokeLinecap: "round"
})));
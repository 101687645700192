import {
  Subscription,
  SubscriptionInvoice,
  SubscriptionPaymentMethod,
  SubscriptionPlan
} from "../../services/api";
import { createAction } from "../meta/types";
import {
  FETCH_MEMBERSHIP,
  FETCH_MEMBERSHIP_FAILURE,
  FETCH_MEMBERSHIP_PLANS,
  FETCH_MEMBERSHIP_PLANS_FAILURE,
  FETCH_MEMBERSHIP_PLANS_REQUEST,
  FETCH_MEMBERSHIP_PLANS_SUCCESS,
  FETCH_MEMBERSHIP_REQUEST,
  FETCH_MEMBERSHIP_SUCCESS,
  SET_SUBSCRIBE_MODAL_VISIBLE
} from "../constants";

export const fetchMembershipActions = createAction(
  FETCH_MEMBERSHIP,
  FETCH_MEMBERSHIP_REQUEST,
  FETCH_MEMBERSHIP_SUCCESS,
  FETCH_MEMBERSHIP_FAILURE
)<
  undefined,
  {
    info: Subscription;
    paymentMethod: SubscriptionPaymentMethod;
    paymentHistory: SubscriptionInvoice[];
  },
  Error
>();

export const fetchMembershipPlansActions = createAction(
  FETCH_MEMBERSHIP_PLANS,
  FETCH_MEMBERSHIP_PLANS_REQUEST,
  FETCH_MEMBERSHIP_PLANS_SUCCESS,
  FETCH_MEMBERSHIP_PLANS_FAILURE
)<
  undefined,
  {
    plans: SubscriptionPlan[];
  },
  Error
>();

export const setSubscribeModalVisible = (visible: boolean) => ({
  type: SET_SUBSCRIBE_MODAL_VISIBLE,
  payload: { visible }
});

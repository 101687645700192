import React from "react";
import classes from "./styles.module.scss";

interface Props {
  icon: string;
  text: string;
}

const Item: React.FC<Props> = ({ icon, text }) => (
  <div className={classes.item}>
    <div className={classes.icon}>
      <img src={icon} alt="" />
    </div>
    <div className={classes.text}>{text}</div>
  </div>
);

export default Item;

import moment from "moment";
import { PREFILL_FIELDS } from "../constants/prefillFields";
import { CreateTicketData, CreateTicketDataAttribute } from "../services/api";
import { GoalCreationSectionConfig } from "../types/goals";
import {
  WizardFile,
  WizardStepConfig,
  WizardValues
} from "../types/wizardStep";
import { getFieldLabel, getFieldsKeys, replaceVariables } from "./wizardStep";

export enum StepType {
  SECTION,
  WIZARD_STEP
}

export type Step = StepSection | StepWizardStep;

interface StepSection {
  type: StepType.SECTION;
  config: GoalCreationSectionConfig;
  categoryName: string;
  subject?: string;
  slug?: string;
}

interface StepWizardStep {
  type: StepType.WIZARD_STEP;
  config: WizardStepConfig;
  id: number;
  categoryName: string;
  subject: string;
  title: string;
  review?: boolean;
  slug?: string;
}

export function getUrl(sectionPath: string[], wizzardStepIndex?: number) {
  if (sectionPath.length > 0) {
    return `/dashboard/${sectionPath.join("/")}${
      wizzardStepIndex !== undefined ? `/${wizzardStepIndex}` : ""
    }`;
  } else {
    return "/dashboard";
  }
}

export function prepareTicketData(
  formattedValues: WizardValues,
  config: WizardStepConfig[],
  parentSection: GoalCreationSectionConfig,
  files?: WizardFile[]
): CreateTicketData {
  const ticketAttributes: CreateTicketDataAttribute[] = [];

  for (let index = 0; index < Object.keys(formattedValues).length; index++) {
    const key = Object.keys(formattedValues)[index];
    ticketAttributes.push({
      type: "fieldValue",
      title: getFieldLabel(config, key) || key,
      value: formattedValues[key],
      display: true
    });
  }

  return {
    slug: parentSection.slug,
    subject: replaceVariables(
      parentSection.ticketSubject || "",
      formattedValues
    ),
    description: replaceVariables(
      parentSection.ticketInfo || "",
      formattedValues
    ),
    categoryId:
      parentSection.ticketCategoryId !== undefined
        ? parentSection.ticketCategoryId
        : 4,
    attributes: ticketAttributes,
    assets: files ? files.map(file => file.id) : [],
    summary: parentSection.ticketSubHeader
      ? replaceVariables(parentSection.ticketSubHeader, formattedValues)
      : undefined
  };
}

export function pullOutCoreData(fields: any): any {
  const result: any = {};

  for (let index = 0; index < PREFILL_FIELDS.length; index++) {
    const field = PREFILL_FIELDS[index];

    if (fields[field.name]) {
      result[field.name] = fields[field.name];
    } else if (field.customSource && fields[field.customSource]) {
      result[field.name] = fields[field.customSource];
    }
  }

  return result;
}

export function getCoreData(
  wizardConfig: WizardStepConfig[],
  userFields: any
): { values: WizardValues; formattedValues: WizardValues } {
  const wizardFieldsKeys = getFieldsKeys(wizardConfig);
  const coreData = pullOutCoreData(userFields);

  const values: WizardValues = {};
  const formattedValues: WizardValues = {};

  wizardFieldsKeys.forEach(key => {
    if (coreData[key]) {
      values[key] = coreData[key];

      if (coreData[key] instanceof Date) {
        formattedValues[key] = moment(coreData[key]).format("MM/DD/YY");
      } else {
        formattedValues[key] = coreData[key];
      }
    }
  });

  return { values, formattedValues };
}

import React from "react";
import styles from "./styles.module.scss";
import classnames from "classnames";

interface Props {
  backgroundColor?: string;
  image?: string; // rendered in container
  backgroundImage?: string; // rendered as background image
  header?: string;
  subHeader?: string;
  noPaper?: boolean;
}

const PageHeader: React.FC<Props> = ({
  backgroundColor,
  image,
  backgroundImage,
  header,
  subHeader,
  noPaper,
  children
}) => {
  var content;
  if (image) {
    content = <img src={image} alt="" />;
  } else if (header && subHeader) {
    content = (
      <div>
        <h1 className={classnames("hero", styles.hero)}>{header}</h1>
        <h3>{subHeader}</h3>
      </div>
    );
  } else if (header) {
    content = <h1>{header}</h1>;
  } else {
    content = children;
  }

  return (
    <div
      className={classnames(styles.pageHeader, {
        [styles.pageHeaderImage]: !!image,
        [styles.noPaper]: noPaper
      })}
      style={{
        backgroundColor: backgroundColor ? backgroundColor : "white",
        backgroundImage: `url(${backgroundImage})`
      }}
    >
      <div className={styles.container}>{content}</div>
    </div>
  );
};

export default PageHeader;

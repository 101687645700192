function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from "react";
export default (({
  styles = {},
  ...props
}) => React.createElement("svg", _extends({
  "data-name": "Layer 1",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 30 30"
}, props), React.createElement("path", {
  fill: "#e6d7eb",
  d: "M5.7 5.7h18.6v18.6H5.7z"
}), React.createElement("path", {
  d: "M23.6 6.4v17.2H6.4V6.4h17.2M25 5H5v20h20V5z",
  fill: "#fff"
}), React.createElement("rect", {
  x: "11",
  y: "8",
  width: "8",
  height: "8",
  rx: "4",
  fill: "#e6d7eb"
}), React.createElement("path", {
  d: "M15 16.5a4.5 4.5 0 114.5-4.5 4.51 4.51 0 01-4.5 4.5zm0-8a3.5 3.5 0 103.5 3.5A3.5 3.5 0 0015 8.5z",
  fill: "#282d37"
}), React.createElement("path", {
  fill: "#e6d7eb",
  d: "M13.5 12l1 1 2-2"
}), React.createElement("path", {
  d: "M14.5 13.5a.47.47 0 01-.35-.15l-1-1a.49.49 0 01.7-.7l.65.64 1.65-1.64a.49.49 0 11.7.7l-2 2a.47.47 0 01-.35.15zM18 21.5h-6a.5.5 0 010-1h6a.5.5 0 010 1zM21 19.5H9a.5.5 0 010-1h12a.5.5 0 010 1z",
  fill: "#282d37"
}), React.createElement("path", {
  d: "M25 25.5H5a.5.5 0 01-.5-.5V5a.5.5 0 01.5-.5h20a.5.5 0 01.5.5v20a.5.5 0 01-.5.5zm-19.5-1h19v-19h-19z",
  fill: "#282d37"
}), React.createElement("path", {
  fill: "none",
  d: "M0 0h30v30H0z"
})));
import React from "react";
import styles from "./styles.module.scss";

interface Props {
  header: string;
  subHeader: string;
  text?: string;
}

const EmptyInstructions: React.FC<Props> = ({
  header,
  subHeader,
  text,
  children
}) => (
  <div className={styles.emptyInstructions}>
    <h1>{header}</h1>
    <h2>{subHeader}</h2>
    {text && <p>{text}</p>}
    {children}
  </div>
);

export default EmptyInstructions;

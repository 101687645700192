import React from "react";
import classes from "./styles.module.scss";
import { formatMoney } from "accounting";

interface Props {
  label: string;
  onClick(): void;
  total?: number;
}

export const FormExpandField: React.FC<Props> = ({ label, total, onClick }) => (
  <div className={classes.container} onClick={onClick}>
    <div className={classes.label}>{label}</div>
    {total && total > 0 ? (
      <div className={classes.total}>
        Total <span>{formatMoney(total)}</span>
      </div>
    ) : null}
  </div>
);

export default FormExpandField;

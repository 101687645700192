import React from "react";
import classes from "./styles.module.scss";
import { connect } from "react-redux";
import { GlobalStore } from "../../types/store";
import { getMetaError } from "../../store/meta/selectors";

type OwnProps = {
  actionTypePrefix?: string;
};

type MapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & MapStateToProps;

const ErrorMessage: React.FC<Props> = ({ children, error }) =>
  error || children ? (
    <div className={classes.container}>{error || children}</div>
  ) : null;

const mapStateToProps = (state: GlobalStore, props: OwnProps) => ({
  error: props.actionTypePrefix && getMetaError(state, props.actionTypePrefix)
});

export default connect(mapStateToProps)(ErrorMessage);

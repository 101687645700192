import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState, useCallback } from "react";

interface Props<T> {
  request: () => Promise<T>;
  children(props: { data: T; refetch: () => Promise<void> }): JSX.Element;
}

function Fetch<T = any>({
  request,
  children
}: Props<T>): React.FunctionComponentElement<Props<T>> {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<T>();

  const fetch = useCallback(
    async function() {
      setLoading(true);
      try {
        const data = await request();
        setData(data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [request]
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  return loading ? (
    <div style={{ textAlign: "center", paddingTop: 30, color: "red" }}>
      <CircularProgress />
    </div>
  ) : data ? (
    children({ data, refetch: fetch })
  ) : (
    <div style={{ textAlign: "center", paddingTop: 30, color: "red" }}>
      "Sorry, something weng wrong"
    </div>
  );
}

export default Fetch;

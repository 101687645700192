import { CircularProgress } from "@material-ui/core";
import { formatMoney } from "accounting";
import moment from "moment";
import React from "react";
import { connect, useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { cancelInvoice } from "../../store/actions/cancelInvoice";
import { copyInvoiceLink } from "../../store/actions/copyInvoiceLink";
import { deleteInvoice } from "../../store/actions/deleteInvoice";
import { downloadInvoicePDF } from "../../store/actions/downloadInvoicePDF";
import { fetchInvoiceList } from "../../store/actions/fetchInvoiceList";
import { sendInvoiceReminder } from "../../store/actions/sendInvoiceReminder";
import Button from "../../components/payments/Button";
import { InvoiceActionsDropdownContainer } from "../../components/payments/InvoiceActionsDropdown";
import InvoiceStatus from "../../components/payments/InvoiceStatus";
import { Mobile, TabletDesktop } from "../../components/Responsive";
import { Sidebar } from "../../components/Sidebar";
import {
  CANCEL_INVOICE,
  COPY_INVOICE_LINK,
  DELETE_INVOICE,
  FETCH_INVOICE_LIST,
  SEND_INVOICE_REMINDER
} from "../../store/constants";
import { getMemberClientDataByInvoiceId } from "../../store/selectors/getMemberClientDataByInvoiceId";
import { getInvoiceDataById } from "../../store/selectors/invoices";
import { getMetaIsLoading } from "../../store/meta/selectors";
import { getUserCompanyName } from "../../store/selectors/user";
import { IInvoiceStatus } from "../../types/paymentsService";
import { GlobalStore } from "../../types/store";
import { getInvoiceTotalAmount } from "../../utils/invoiceHelper";
import styles from "./Details.module.scss";
import { InvoiceDetailsInfoSection } from "../../components/payments/InvoiceDetailsInfoSection";

type MapStateToProps = ReturnType<typeof mapStateToProps>;
type RouterProps = RouteComponentProps<{ invoiceId: string }>;

type InvoiceDetailsProps = RouterProps & MapStateToProps;

const Details: React.FC<InvoiceDetailsProps> = props => {
  const dispatch = useDispatch();

  let content = null;

  if (props.loading) {
    content = (
      <div className={styles.loading}>
        <CircularProgress />
      </div>
    );
  }

  if (props.data && props.member) {
    const invoice = props.data;
    const member = props.member;
    const invoiceName = `Invoice #${invoice.invoiceId.substring(0, 4)}`;

    content = (
      <div className={styles.content}>
        <div className={styles.header}>
          <div>
            <h1 className={styles.headerTitle}>
              {member.company}
              <Mobile>
                <br />
              </Mobile>
              <TabletDesktop> – </TabletDesktop>
              {invoiceName}
            </h1>
            <p>Invoice 1 of 1</p>
          </div>
          <InvoiceStatus status={invoice.status} />
        </div>
        <div className={styles.subheader}>
          <div className={styles.info}>
            <div>
              <span className={styles.label}>Amount</span>
              <span className={styles.value}>
                {formatMoney(getInvoiceTotalAmount(invoice))}
              </span>
            </div>
            <div>
              <span className={styles.label}>Due</span>
              <span className={styles.value}>
                {moment.utc(invoice.dueDate).format("MMM D, YYYY")}
              </span>
            </div>
          </div>
          <div className={styles.actions}>
            {invoice.status === IInvoiceStatus.Open ? (
              <div className={styles.actionsButton}>
                <Mobile>
                  <InvoiceActionsDropdownContainer
                    invoiceId={invoice.invoiceId}
                    buttons
                    noView
                    noCancel
                    noEdit
                  />
                </Mobile>
                <TabletDesktop>
                  <InvoiceActionsDropdownContainer
                    invoiceId={invoice.invoiceId}
                    noView
                    noCancel
                    noEdit
                  />
                </TabletDesktop>
              </div>
            ) : null}

            {invoice.status === IInvoiceStatus.Overdue ? (
              <div className={styles.actionsButton}>
                <Mobile>
                  <InvoiceActionsDropdownContainer
                    invoiceId={invoice.invoiceId}
                    buttons
                    noView
                    noSendReminder
                  />
                </Mobile>
                <TabletDesktop>
                  <InvoiceActionsDropdownContainer
                    invoiceId={invoice.invoiceId}
                    noView
                    noSendReminder
                  />
                </TabletDesktop>
              </div>
            ) : null}

            {invoice.status === IInvoiceStatus.Draft ? (
              <>
                <Button
                  secondary
                  small
                  loading={props.deleteInvoiceLoading}
                  onClick={async () => {
                    await dispatch(deleteInvoice(invoice.invoiceId));
                    await dispatch(fetchInvoiceList());
                    props.history.push(`/invoices`);
                  }}
                >
                  Delete invoice
                </Button>

                <Button
                  small
                  loading={props.sendInvoiceReminderLoading}
                  onClick={() => {
                    props.history.push(`/invoices/${invoice.invoiceId}/edit`);
                  }}
                >
                  Edit invoice
                </Button>
              </>
            ) : null}

            {invoice.status === IInvoiceStatus.Open ? (
              <>
                <Button
                  secondary
                  small
                  loading={props.cancelInvoiceLoading}
                  onClick={async () => {
                    await dispatch(cancelInvoice(invoice.invoiceId));
                    await dispatch(fetchInvoiceList());
                    props.history.push(`/invoices`);
                  }}
                >
                  Cancel invoice
                </Button>
                <Button
                  small
                  onClick={() => {
                    props.history.push(`/invoices/${invoice.invoiceId}/edit`);
                  }}
                >
                  Edit invoice
                </Button>
              </>
            ) : null}

            {invoice.status === IInvoiceStatus.Overdue ? (
              <>
                <Button
                  secondary
                  small
                  loading={props.copyInvoiceLinkLoading}
                  onClick={() => {
                    dispatch(copyInvoiceLink(invoice.invoiceId));
                  }}
                >
                  Copy invoice link
                </Button>
                <Button
                  small
                  loading={props.sendInvoiceReminderLoading}
                  onClick={() => {
                    dispatch(sendInvoiceReminder(invoice.invoiceId));
                  }}
                >
                  Send reminder
                </Button>
              </>
            ) : null}

            {invoice.status === IInvoiceStatus.Paid ? (
              <>
                <Button
                  secondary
                  small
                  loading={props.copyInvoiceLinkLoading}
                  onClick={() => {
                    dispatch(copyInvoiceLink(invoice.invoiceId));
                  }}
                >
                  Copy invoice link
                </Button>
                <Button
                  small
                  onClick={() => {
                    dispatch(downloadInvoicePDF(invoice.invoiceId));
                  }}
                >
                  Download PDF
                </Button>
              </>
            ) : null}
          </div>
        </div>

        <div className={styles.section}>
          <InvoiceDetailsInfoSection
            userCompany={props.userCompany}
            clientCompany={member.company}
            invoiceName={invoiceName}
            createdDate={new Date()}
            dueDateTypeOnReceipt={invoice.updatedAt === invoice.dueDate}
            dueDate={invoice.updatedAt}
            clientName={member.name}
            clientEmail={member.emailTo}
            additionalEmails={member.emailCC}
            lineItems={invoice.lineItems.map(lineItem => ({
              description: lineItem.description || "",
              totalCost: lineItem.totalCost
            }))}
            lateFees={invoice.feeLineItems?.map(
              feeLineItem => feeLineItem.description || ""
            )}
            notes={invoice.invoiceNotes}
          />
        </div>
      </div>
    );
  }

  return (
    <Sidebar onClose={() => props.history.push("/invoices")}>{content}</Sidebar>
  );
};

const mapStateToProps = (state: GlobalStore, props: RouterProps) => ({
  loading: getMetaIsLoading(state, FETCH_INVOICE_LIST),
  data: getInvoiceDataById(state, props.match.params.invoiceId),
  member: getMemberClientDataByInvoiceId(state, props.match.params.invoiceId),
  userCompany: getUserCompanyName(state),
  sendInvoiceReminderLoading: getMetaIsLoading(
    state,
    SEND_INVOICE_REMINDER,
    props.match.params.invoiceId
  ),
  copyInvoiceLinkLoading: getMetaIsLoading(
    state,
    COPY_INVOICE_LINK,
    props.match.params.invoiceId
  ),
  cancelInvoiceLoading: getMetaIsLoading(
    state,
    CANCEL_INVOICE,
    props.match.params.invoiceId
  ),
  deleteInvoiceLoading: getMetaIsLoading(
    state,
    DELETE_INVOICE,
    props.match.params.invoiceId
  )
});

export const InvoiceDetailsContainer = connect(mapStateToProps)(Details);

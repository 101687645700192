import Drawer from "@material-ui/core/Drawer";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import AgentHeader from "../components/Chat/AgentHeader";
import ChatDetail from "../components/Chat/ChatDetail";
import ChatReply from "../components/Chat/ChatReply";
import ChatTextField from "../components/Chat/ChatTextField";
import GoalsList from "../components/Chat/GoalsList";
import SummaryReply from "../components/Chat/SummaryReply";
import MainLayout from "../components/MainLayout";
import { TabletDesktop } from "../components/Responsive";
import {
  attachAssetToChat,
  createAsset,
  createTicketReply,
  getTicket,
  getTickets
} from "../services/api";
import { track } from "../utils/analytics";
import classes from "./Chat.module.scss";

const ChatScreen: React.FC<
  RouteComponentProps<{ freshdeskTicketId: string }>
> = ({
  match: {
    params: { freshdeskTicketId }
  },
  history
}) => {
  const [chatData, setChatData] = useState<any>(null);
  const [loaded, setLoaded] = useState(false);
  const [value, setValue] = useState("");
  const [showDrawer, setShowDrawer] = useState(false);
  const [goals, setGoals] = useState<{ active: any[]; completed: any[] }>({
    active: [],
    completed: []
  });
  const [goalsLoaded, setGoalsLoaded] = useState(false);

  useEffect(() => {
    async function updateChat() {
      try {
        setChatData(await getTicket(freshdeskTicketId));
      } catch (e) {
        if (!loaded) {
          console.error("Unable to load chat");
          console.error(e);
        }
      }
      if (!loaded) {
        setLoaded(true);
      }
    }

    async function getGoals() {
      try {
        const goals = await getTickets();
        setGoals({
          active: goals.active,
          completed: goals.completed
        });
      } catch (error) {
        console.error("Unable to load goals");
        console.error(error);
      } finally {
        setGoalsLoaded(true);
      }
    }

    const interval = window.setInterval(updateChat, 5000);

    updateChat();
    getGoals();

    return () => {
      if (interval) {
        window.clearInterval(interval);
      }
    };
  }, [freshdeskTicketId, loaded]);

  if (!loaded || !goalsLoaded) {
    return <div></div>;
  }

  const items = [];
  const conversation = chatData ? chatData.conversation : [];

  items.push(
    <SummaryReply
      category={chatData.category}
      subject={chatData.subject}
      summary={chatData.summary}
      files={chatData.initialAssets}
    />
  );

  for (let i = 0; i < conversation.length; i++) {
    const current = conversation[i];
    let showAvatar = false;

    // TODO: implement logic for time stamp
    if (!current.isUser) {
      if (i === conversation.length - 1) {
        showAvatar = true;
      } else if (conversation[i + 1].isUser) {
        showAvatar = true;
      } else {
        const nextReply = conversation[i + 1];
        const currentAgentName =
          current.agent && current.agent.agentName
            ? current.agent.agentName
            : null;
        const nextAgentName =
          nextReply.agent && nextReply.agent.agentName
            ? nextReply.agent.agentName
            : null;
        if (currentAgentName && currentAgentName !== nextAgentName) {
          showAvatar = true;
        }
      }
    }

    items.push(
      <ChatReply
        key={`message-item-${i}`}
        text={current.text}
        showAvatar={showAvatar}
        isCurrentUser={current.isUser}
        agent={current.agent}
        fileName={current.fileName}
        fileUrl={current.url}
      />
    );
  }

  return (
    <MainLayout title="Chat" disableHeaderAnimation>
      <div className={classes.container}>
        <TabletDesktop>
          <GoalsList
            value={freshdeskTicketId}
            className={classes.goalsList}
            active={goals.active}
            completed={goals.completed}
            onChange={(id: string) => {
              history.push(`/goals/f/${id}`);
            }}
          />
        </TabletDesktop>

        <div className={classes.innerChatContainer}>
          <AgentHeader
            onClick={() => setShowDrawer(!showDrawer)}
            assignedAgent={chatData.assignedAgent}
          />
          <div className={classes.messageContainer}>
            {items.map(item => item)}
          </div>
          <div className={classes.inputContainer}>
            <ChatTextField
              setValue={setValue}
              sendReply={async () => {
                let replyValue = value;
                try {
                  if (replyValue && replyValue.length > 0) {
                    setValue(""); //clear the input
                    const data = await createTicketReply(
                      freshdeskTicketId,
                      replyValue
                    );

                    setChatData(data);

                    track("Send Message", {
                      freshdeskTicketId: data.id,
                      category: data.category,
                      subject: data.subject,
                      status: data.status,
                      assignedAgentName: data.assignedAgent.agentName
                    });
                  }
                } catch (e) {
                  console.error(e);
                  // TODO: not sure what to show if failure
                }
              }}
              sendFile={async (file: File) => {
                try {
                  const { id } = await createAsset(file);
                  await attachAssetToChat(freshdeskTicketId, id);
                } catch (e) {
                  console.error("Failed to upload asset");
                  console.error(e);
                }
              }}
              value={value}
            />
          </div>
        </div>

        <Drawer
          anchor="right"
          onClose={() => setShowDrawer(false)}
          open={showDrawer}
        >
          <ChatDetail
            className={classes.chatDetail}
            attributes={chatData.attributes}
            subject={chatData.subject}
            description={chatData.description}
            assignedAgent={chatData.assignedAgent}
          />
        </Drawer>
      </div>
    </MainLayout>
  );
};

export default ChatScreen;

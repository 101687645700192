import classNames from "classnames";
import React from "react";
import classes from "./styles.module.scss";

interface Props {
  description?: string;
  className?: string;
}

const MainBox: React.FC<Props> = ({ description, className, children }) => (
  <div className={classNames(classes.mainBox, className)}>
    {description && <div className={classes.description}>{description}</div>}
    {children}
  </div>
);

export default MainBox;

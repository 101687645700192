import React from "react";
import classes from "./styles.module.scss";

interface Props {
  align?: "left" | "center";
}

const Modest: React.FC<Props> = ({ align, children }) => (
  <div className={classes.container} style={{ textAlign: align }}>
    {children}
  </div>
);

export default Modest;

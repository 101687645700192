import { Field, FieldProps, getIn } from "formik";
import React from "react";
import TextField, { TextFieldProps } from ".";

interface Props extends Omit<TextFieldProps, "value" | "error" | "onChange"> {
  name: string;
}

const FormikTextField: React.FC<Props> = ({ name, ...props }) => (
  <Field name={name}>
    {({ field, form }: FieldProps) => (
      <TextField
        name={field.name}
        value={field.value}
        onChange={(event: any) => {
          form.setFieldValue(
            field.name,
            event.target.rawValue || event.target.value
          );
        }}
        onBlur={field.onBlur}
        error={
          getIn(form.touched, name) && getIn(form.errors, name)
            ? getIn(form.errors, name)
            : undefined
        }
        {...props}
      />
    )}
  </Field>
);

export default FormikTextField;

import { Form, Formik } from "formik";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router-dom";
import * as Yup from "yup";
import { fetchMembership } from "../../store/actions/fetchMembership";
import Button from "../../components/onboarding/Button";
import Layout from "../../components/onboarding/Layout";
import Modest from "../../components/onboarding/Modest";
import SelectSubscriptionPlanField from "../../components/onboarding/SelectSubscriptionPlanField";
import TextField from "../../components/onboarding/TextField";
import { Mobile } from "../../components/Responsive";
import Row from "../../components/Row";
import * as apiService from "../../services/api";
import * as stripeService from "../../services/stripe";
import { getMembershipPlans } from "../../store/selectors/membership";
import { GlobalStore } from "../../types/store";
import { makeEnrichIdentifyFromState, track } from "../../utils/analytics";
import { generateFormStatus } from "../../utils/form";

type StateProps = ReturnType<typeof mapStateToProps>;

type RouterPops = RouteComponentProps;

type Props = StateProps & RouterPops;

const Subscribe: React.FC<Props> = ({ history, plans, enrichIdentify }) => {
  const dispatch = useDispatch();
  const initialPlan = plans.find(plan => plan.interval === "year");

  const [loading, setLoading] = useState(false);

  const [plan, setPlan] = useState(initialPlan ? initialPlan.planId : "");
  const [currentStep, setCurrentStep] = useState("plan");

  switch (currentStep) {
    case "plan":
      return (
        <Layout title="Choose Membership">
          <h1>Choose Membership</h1>
          <Layout.MainBox description="Which payment plan would you like? Both plans have a 30-day free trial!">
            <SelectSubscriptionPlanField
              value={plan}
              onChange={newPlan => {
                track("Membership Plan Selection Updated", {
                  location: "Onboarding"
                });
                setPlan(newPlan);
              }}
            />
          </Layout.MainBox>
          <Layout.Actions>
            <Button
              loading={loading}
              onClick={async () => {
                setLoading(true);

                track("Membership Plan Selected", {
                  location: "Onboarding"
                });
                setCurrentStep("paymentInfo");

                setLoading(false);
              }}
            >
              Sign up for Free!
            </Button>
            <Button
              secondary
              onClick={() => {
                track("Membership Ignored", { location: "Onboarding" });
                history.push("/dashboard");
              }}
            >
              No thanks, I’ll subscribe later
            </Button>
          </Layout.Actions>
        </Layout>
      );

    case "paymentInfo":
      return (
        <Layout title="Payment Details">
          <h1>Payment Details</h1>

          <Formik
            initialValues={{
              name: "",
              card: "",
              expirationDate: "",
              cvv: "",
              zip: ""
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required("Name on Card is required"),
              card: Yup.string().required("Card is required"),
              expirationDate: Yup.string().required(
                "Expiration Date is required"
              ),
              cvv: Yup.string().required("CVV Code is required"),
              zip: Yup.string().required("Billing Zip is required")
            })}
            onSubmit={async (values, { setSubmitting, setStatus }) => {
              try {
                setStatus(null);

                track("Membership Purchased", {
                  location: "Onboarding"
                });

                const [
                  expirationMonth,
                  expirationYear
                ] = values.expirationDate.split("/");

                const token = await stripeService.createPaymentMethod({
                  name: values.name,
                  number: Number(values.card.split(" ").join("")),
                  cvc: Number(values.cvv),
                  expMonth: Number(expirationMonth),
                  expYear: Number(expirationYear),
                  zip: Number(values.zip)
                });

                await apiService.updateSubscriptionPaymentMethod(token);

                const subscription = await apiService.updateSubscription(plan);
                await dispatch(fetchMembership());

                enrichIdentify(
                  subscription.planNickname,
                  new Date(),
                  subscription.trialEnd
                );

                setCurrentStep("success");
              } catch (error) {
                setStatus(generateFormStatus(error));
              }
              setSubmitting(false);
            }}
          >
            {({
              handleSubmit,
              status,
              values,
              handleChange,
              touched,
              errors,
              isSubmitting
            }) => (
              <Form>
                <Layout.MainBox description="Please enter your payment details below">
                  {status && status.type === "error" && (
                    <p style={{ color: "red", marginBottom: 20 }}>
                      {status.message}
                    </p>
                  )}

                  <Row>
                    <Row.Col>
                      <TextField
                        label="Name on Card"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        error={
                          touched.name && errors.name ? errors.name : undefined
                        }
                        required
                        placeholder="John Doe"
                      />
                    </Row.Col>
                    <Row.Col>
                      <TextField
                        label="Card Number"
                        name="card"
                        value={values.card}
                        onChange={handleChange}
                        formatOptions={{
                          creditCard: true
                        }}
                        error={
                          touched.card && errors.card ? errors.card : undefined
                        }
                        required
                        placeholder="1234 1234 1234 1234"
                      />
                    </Row.Col>
                  </Row>

                  {/* TODO: Come up with something better */}
                  <Mobile>
                    <br />
                  </Mobile>

                  <Row>
                    <Row.Col>
                      <TextField
                        label="Expiration Date"
                        name="expirationDate"
                        value={values.expirationDate}
                        onChange={handleChange}
                        formatOptions={{
                          date: true,
                          datePattern: ["m", "y"]
                        }}
                        error={
                          touched.expirationDate && errors.expirationDate
                            ? errors.expirationDate
                            : undefined
                        }
                        required
                        placeholder="MM / YY"
                      />
                    </Row.Col>
                    <Row.Col>
                      <TextField
                        label="CVV Code"
                        maxLength={3}
                        formatOptions={{
                          numericOnly: true
                        }}
                        name="cvv"
                        value={values.cvv}
                        onChange={handleChange}
                        error={
                          touched.cvv && errors.cvv ? errors.cvv : undefined
                        }
                        required
                        placeholder="CVV"
                      />
                    </Row.Col>
                    <Row.Col>
                      <TextField
                        label="Billing Zip"
                        maxLength={5}
                        formatOptions={{
                          numericOnly: true
                        }}
                        name="zip"
                        value={values.zip}
                        onChange={handleChange}
                        error={
                          touched.zip && errors.zip ? errors.zip : undefined
                        }
                        required
                        placeholder="12345"
                      />
                    </Row.Col>
                  </Row>

                  <Modest>
                    All transactions are secure and SSL encrypted.
                  </Modest>
                </Layout.MainBox>

                <Layout.Actions>
                  <Button
                    loading={isSubmitting}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    secondary
                    onClick={() => {
                      setCurrentStep("plan");
                    }}
                  >
                    Back
                  </Button>
                </Layout.Actions>
              </Form>
            )}
          </Formik>
        </Layout>
      );

    default:
      return <Redirect to="/dashboard" />;
  }
};

const mapStateToProps = (state: GlobalStore) => ({
  plans: getMembershipPlans(state),
  enrichIdentify: makeEnrichIdentifyFromState(state)
});

export default connect(mapStateToProps)(Subscribe);

import React from "react";
import { connect } from "react-redux";
import { getMembershipPlans } from "../../store/selectors/membership";
import { GlobalStore } from "../../types/store";
import RadioField from "../RadioField";
import Label from "./Label";
import classes from "./styles.module.scss";

type OwnProps = {
  name: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  error?: string;
  label?: string;
  disabled?: boolean;
  showTitle?: boolean;
};

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StateProps;

const SelectSubscriptionPlanField: React.FC<Props> = ({
  plans,
  name,
  value,
  error,
  label,
  onChange,
  disabled,
  showTitle
}) => (
  <div>
    {showTitle && <h4 className={classes.title}>Subscriptions</h4>}
    <div className={classes.freeTrial}>
      All plans include a 30 day free trial!
    </div>
    <div className={classes.container}>
      <RadioField
        name={name}
        value={value}
        options={plans.map(plan => ({
          label: (
            <Label
              monthlyPrice={
                plan.interval === "year" ? plan.amount / 12 : plan.amount
              }
              interval={plan.interval}
              annualSavings={plan.interval === "year" ? "120" : ""}
            />
          ),
          value: plan.planId
        }))}
        error={error}
        label={label}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  </div>
);

const mapStateToProps = (state: GlobalStore) => ({
  plans: getMembershipPlans(state)
});

export default connect(mapStateToProps)(SelectSubscriptionPlanField);
